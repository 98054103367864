import styles from './dailyDurationReportTable.module.scss'
import {TalkReportsLanguageT} from '../../../utils/models'
import { AccountReportT, DailyHoursReportT, DailyReportT, GetDailyTalkListFilterT, GetHourReportFilterT, GetHoursDailyTalkList, GetHoursTalkListResT } from '../../../utils/requests'
import { useEffect, useState } from 'react'
import { useDispatch, useTypedSelector } from '../../../redux'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
const { dailyDurationReportTable, headRow, bodyRow, noRecord, pair, single} = styles



type DailyDurationReportTablePropsT = {
    data: DailyReportT[]
  dailyRowClickHandler: (report: DailyReportT) => void
    date:GetDailyTalkListFilterT
}



const DailyDurationReportTable: React.FC<DailyDurationReportTablePropsT> = props => {
  // console.log('data in duration table',props.data);
  // const [dailyTalkReport, setDailyTalkReport] = useState<DailyHoursReportT[]>([])
  const { user: userRedux, configs: configRedux } = useTypedSelector(state => ({ user: state.user, configs: state.configs }))
    const dispatch = useDispatch()
  const navigate = useNavigate()
//      useEffect(() => {
//     const fetchData = async (access_token: string) => {
//         try {
//             // setLoading(true)
//             const filters: GetHourReportFilterT = {
//               from: props.date.from,
//               to: props.date.to
//             }
//             const res = await GetHoursDailyTalkList({token: access_token, filters},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
//             // setLoading(false)
//             if(res.status === 200){
//               const resData = res.data as GetHoursTalkListResT
             
              
                
//               if (resData.report) {
//                   //  console.log('data from hours',resData.report);
//                 setDailyTalkReport(resData.report)
//                 // setHoursData(resData.report[0].hour_report)
//                 }
//                 else {
//                   setDailyTalkReport([])
//                 }
//             }
//         }
//         catch(err){
//             // setLoading(false)
//         }
//     }
//     if(userRedux.access_token){
//         fetchData(userRedux.access_token)
//         // setFirstRender(false)
//     }
// },[userRedux.access_token])
  
    let rows = []
    if(props.data){
        rows = props.data.map((record, index) => {
            const { fifteen, five, hundred, sixty, thirty } = record
            
            return (
                <tr className={[bodyRow].join(' ')} key={`${index}-sdas`} >
                    <td>{index+1}</td>
                    <td>{new Date(record.date).toLocaleDateString('en')}</td>
                    <td>{five}</td>
                    <td>{fifteen}</td>
                    <td>{thirty}</td>
                    <td>{sixty}</td>
                    <td>{hundred}</td>
                </tr>
            )

        })
    }

    return(
        <>
            <table className={dailyDurationReportTable}>
                <thead>
                    <tr className={headRow}>
                        <td style={{minWidth: '2em'} as React.CSSProperties}></td>
                        <td>Date</td>
                        <td>{` 5 - 15 `}</td>
                        <td>{` 15 - 30 `}</td>
                        <td>{` 30 - 60 `}</td>
                        <td>{` 60 - 120 `}</td>
                        <td>{` > 120 `}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={6}>there is no record</td></tr>
                    }
                </tbody>
            </table>
        </>
    )
}


export default DailyDurationReportTable