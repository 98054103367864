import styles from './countryTalkListTable.module.scss'
import {CountryTalkWithPalCountriesT} from '../../../utils/models'
import { useNavigate } from 'react-router-dom'
import {useDispatch} from '../../../redux'
import { PATHES } from '../../../utils/contants'
import { rearrange_date_fields } from '../../../utils/others'



const {countryTalkListTable, filterBox, tableBox, userTable,
     headRow, bodyRow, noRecord, paginationBox, exportBtn, pair, single,
    redCss, greenCss, blueCss, yellowCss, graphSpot, body
    } = styles





type CountryTalkListTablePropsT = {
    access_token: string
    refresh_token: string
    filteredDates: {from: Date | null, to: Date | null} | null
    createdAt: any
    countryTalkList: CountryTalkWithPalCountriesT[]
}

const CountryTalkListTable:React.FC<CountryTalkListTablePropsT> = (props) => {
 


    const navigate = useNavigate()
    const dispatch = useDispatch()






    
    //data we need to export as excel 
    // let exportable_data: {
    //     index: number,
    //     talk_id: number,
    //     language: string,
    //     duration: number,
    //     status: string
    //     created_at: string
    //     reconnection_count: number
    //     end_reason: string,
    //     finisher_id: number
    //     account_id_A: number,  
    //     account_id_B: number,  
    //     character_A: string,  
    //     character_B: string, 
    //     platform_A: string,  
    //     platform_B: string, 
    //     app_version_A: string
    //     app_version_B: string
    //     ip_A: string
    //     ip_B: string
    //     country_A: string 
    //     country_B: string
    //     internet_quality_A: string
    //     internet_quality_B: string
    // }[] = []
    // let exportable_headers = [
    //     {key: 'index', label: 'index'},
    //     {key: 'talk_id', label: 'Talk ID'},
    //     {key: 'language', label: 'Language'},
    //     {key: 'duration', label: 'Duration'},
    //     {key: 'status', label: 'Status'},
    //     {key: 'created_at', label: 'Created At'},
    //     {key: 'reconnection_count', label: 'Reconnection Count'},
    //     {key: 'end_reason', label: 'End Reason'},
    //     {key: 'finisher_id', label: 'Finisher Id'},
    //     {key: 'account_id_A', label: 'AccountId A'},
    //     {key: 'account_id_B', label: 'AccountId B'},
    //     {key: 'character_A', label: 'Character A'},
    //     {key: 'character_B', label: 'Character B'},
    //     {key: 'platform_A', label: 'Platform A'},
    //     {key: 'platform_B', label: 'Platform B'},
    //     {key: 'app_version_A', label: 'App Version A'},
    //     {key: 'app_version_B', label: 'App Version B'},
    //     {key: 'ip_A', label: 'Ip A'},
    //     {key: 'ip_B', label: 'Ip B'},
    //     {key: 'country_A', label: 'Country A'},
    //     {key: 'country_B', label: 'Country B'},
    //     {key: 'internet_quality_A', label: 'Internet Quality A'},
    //     {key: 'internet_quality_B', label: 'Internet Quality B'},
    // ]
    





  
//    talks.forEach((talkRec,index) => {
//     const {talk_id,language_id,created_at,duration, status, pals} = talkRec
    
//     const charA = pals[0]
//     const charB = pals[1] 
//     exportable_data.push({
//         index: index+1,
//         talk_id,
//         language: props.langFC.options.find(lang => lang.value === language_id).label.toString(),
//         duration,
//         created_at,
//         status: props.statusFC.options.find(stat => stat.value === status).label.toString(),
//         account_id_A: charA.account_id,
//         account_id_B: charB.account_id,
//         character_A: props.allChars.find(ch => ch.value === charA.character_id)!.name.toString(),
//         character_B: props.allChars.find(ch => ch.value === charB.character_id)!.name.toString(),
//         app_version_A: charA.app_version,
//         app_version_B: charB.app_version,
//         country_A: charA.country,
//         country_B: charB.country,
//         ip_A: charA.ip,
//         ip_B: charB.ip,
//         internet_quality_A: props.internetQualityFC.options.find(iq => iq.value === charA.internet_quality).label.toString(),
//         internet_quality_B: props.internetQualityFC.options.find(iq => iq.value === charB.internet_quality).label.toString(),
//         platform_A: props.platformFC.options.find(pl => pl.value === charA.platform).label.toString(),
//         platform_B: props.platformFC.options.find(pl => pl.value === charB.platform).label.toString(),
//     })
//     })






const countryRowClickHandler = (countryRec: CountryTalkWithPalCountriesT) => {
    navigate({
        pathname: PATHES.countryTalkDetail,
    }, {
        state: {
            countryTalkDetail: countryRec,
            from: PATHES.countryTalkDetail,
            filteredDates: rearrange_date_fields(props.createdAt)
        }
    })
}




let rows = []
if(props.countryTalkList.length > 0){
    const sortedCountryTalkList = [...props.countryTalkList].sort((a, b) => b.total_talks - a.total_talks);
    rows = sortedCountryTalkList.map((countryRec,index) => {

        const {PalCountries, above_five_minutes_talks, characters, country, exited_talks, failed_talks,
            successful_talks, total_count_non_zero_pings, total_duration, total_pings_in_millisecond, total_talks
        } = countryRec
        
        const manChars = characters.filter((char, index) => [1,2,12,13,14,15,16,17,18,24,28,30,32,34,36,38,40,42,44,46,48,50,52,54,56,58].includes(char.id)).reduce((acc, rec) => acc+rec.count, 0)
        const womanChars = characters.filter((char, index) => [3,4,5,6,7,8,9,10,11,23,25,26,27,29,31,32,33,35,37,39,41,43,45,47,49,51,53,55,57,59].includes(char.id)).reduce((acc, rec) => acc+rec.count, 0)
        const totalChars = manChars + womanChars
        const manPercentage = totalChars > 0 ? Math.round((manChars / totalChars)*100) : 0
        const womanPercentage = totalChars > 0 ? Math.round((womanChars / totalChars)*100) : 0
        
        const avgDuration = successful_talks > 0 ? Math.round((total_duration / successful_talks)) : 0
        // exportable_data.push({
        //     index: index+1,
        //     talk_id,
        //     language: languageName,
        //     duration,
        //     created_at,
        //     reconnection_count,
        //     end_reason: endReason,
        //     finisher_id,
        //     status: statusName,
        //     account_id_A: charA?.account_id,
        //     account_id_B: charB?.account_id,
        //     character_A: charA_name,
        //     character_B: charB_name,
        //     app_version_A: charA?.app_version,
        //     app_version_B: charB?.app_version,
        //     country_A: charA?.country,
        //     country_B: charB?.country,
        //     ip_A: charA?.ip,
        //     ip_B: charB?.ip,
        //     internet_quality_A: internetQualityA?.toString(),
        //     internet_quality_B: internetQualityB?.toString(),
        //     platform_A: platformA,
        //     platform_B: platformB,
        // })

    
     
        return (
            <tr className={[bodyRow].join(' ')} key={`${index}-sdas`} onClick={() => countryRowClickHandler(countryRec)}>
                <td>{index+1}</td>
                <td>{country}</td>
                <td>{total_talks}</td>
                <td>{successful_talks}</td>
                <td>{failed_talks}</td>
                <td>{exited_talks}</td>
                <td className={pair}>
                    <div className={single}>{manChars}</div>
                    <div className={single}>{manPercentage}%</div>
                </td>
                <td className={pair}>
                    <div className={single}>{womanChars}</div>
                    <div className={single}>{womanPercentage}%</div>
                </td>
                <td>{above_five_minutes_talks}</td>
                <td>{total_duration}</td>
                <td>{avgDuration}</td>
            </tr>
        )
    })

}


   



    return(
    <div className={countryTalkListTable}>
    
       
        <div className={tableBox}>
        <div className={exportBtn}>
            {/* <CSVLink data={exportable_data} headers={exportable_headers} filename={"test.csv"} >
                <ButtonA children={`export entire talk list`} onClick={() => {}} type="secondary"/>
            </CSVLink> */}
        </div>
        <table className={userTable}>
            <thead>
                <tr className={headRow}>
                    <td style={{minWidth: '2em'} as React.CSSProperties}></td>
                    <td>Country</td>
                    <td>Total Call</td>
                    <td>Success</td>
                    <td>Failed</td>
                    <td>Exit</td>
                    <td>Man</td>
                    <td>Woman</td>
                    <td>Last 5 Minutes</td>
                    <td>Total Duration</td>
                    <td>Avg Duration</td>
                </tr>
            </thead>
            <tbody>
                {
                rows.length > 0 ?
                rows : 
                <tr className={noRecord}><td colSpan={13}>there is no record</td></tr>
                }
            </tbody>
        </table>
    </div>
        </div>
        
      


    )
} 


export default CountryTalkListTable