import styles from './sendTopic.module.scss'
import React,{useState, useEffect} from 'react'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import {ButtonAProps} from '../../components/buttons/buttonA'
import Template from '../../components/template/template'
import {useTypedSelector, useDispatch} from '../../redux/index'
import {adminCreateNewUser, AdminCreateNewUserReqBodyT, sendAdminPublishMessage, SendAdminPublishMessageReqT} from '../../utils/requests'
import { useNavigate } from 'react-router-dom'
import {NAME_MIN_LENGTH, PATHES, PLATFORMS_ARR} from '../../utils/contants'
import {PASSWORD_MIN_LENGTH, USERNAME_MIN_LENGTH} from '../../utils/contants'
import {Langs, validator,ValidatorTypes} from '../../utils/validator'
import FormControl, {InputTypes, SelectContent} from '../../components/formControl/formControl'
import {AppVersionFC, LanguageTopicMessageFC, TopicFC, TypeFC, passFC, passwordX2FC, qualityOfServiceFC_creator} from '../../utils/formControl'
import Filter from '../../components/filters/filterE'
import { type } from 'os'
import {toast} from 'react-toastify'
import { languagesSelection } from '../../utils/utility'



const {sendTopic, container, filterSpot, body, languageSection, langLabel, langInput, userName, phoneNumber, email, password, confirmPassword, role } = styles




const SendTopic:React.FC = props => {
    const {user: userRedux, configs: configsRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const qualityOfServiceFC = qualityOfServiceFC_creator()
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [passV, setPassV] = useState('')
    const [typeV, setTypeV] = useState('')
    const [topicV, setTopicV] = useState('')
    const [isRetained, setIsRetained] = useState(false)
    const [versions, setVersions] = useState<string[]>([])
    const [versionV, setVersionV] = useState<string>('')
    const [platforms, setPlatforms] = useState<{name: string, value: number|string}[]>([])
    const [languages, setLanguages] = useState<{name: string, value: number|string}[]>([])
    const [messages, setMessages] = useState<{langId: string, value: string}[]>([])
    const [qualityOfServiceV, setQualityOfServiceV] = useState(qualityOfServiceFC.options[0].value as number)
   

    const [loading, setLoading] = useState(false)
    const [errorsObj, setErrorsObj] = useState({pass: null, topic: null, type: null, email: null, platforms: null, versions: null,languages: null, messages: null})


    //when we add a lang we have to add the corresponding message obj to messages arr as well if there wasnt any
    useEffect(() => {
      languages.forEach((lang,index) => {
        if(!messages.findIndex(msg => msg.langId.toString() === lang.name.toString())){
          setMessages(prev => [...prev, {langId: lang.name, value: ''}])
        }
      })
    },[languages])

    //a function for updating the message of each language
    const messageChanger = (langId:  string|number, value: string) => {
      setMessages(prev => {
        const obj = messages.find(msg => {
          return msg.langId.toString() === langId.toString()
        })
        //if there is an message obj for this langId, we have to update the value of it
        if(obj){
          return prev.map((msg) => {
            if(msg.langId.toString() === langId.toString()){
              return {
                langId: msg.langId,
                value: value
              }
            }
            return msg
          })

        }
        else {
          return [...prev, {langId: langId.toString(), value: value}]
        }
      })

    }

    //we have to loop through selected messages and show their textAreas and html for their corresponding messages
    const languagesSections = languages.map((lang, index) => {
      const msg = messages.find(langMsg => langMsg.langId.toString() === lang.value.toString()) 
      // console.log('msg ---> ',msg)
      return (
        <div className={languageSection} key={`${index}-js`}>
          <h3 className={langLabel}>{lang.name}</h3>
          <div className={langInput}>
            <FormControl content={LanguageTopicMessageFC} value={msg?.value ? msg.value : '' } setParentValue={(value) => messageChanger(lang.value, value)} isValid={true} setIsValid={() => {}} errorMsg={null}/>
          </div>
        </div>
      )
    })

  const submitHandler = async () => {
      
      if(loading){
        return
      }
      //then we have to do validation on inputs
      const errObj = {pass: null, topic: null, type: null, email: null, platforms: null, versions: null,languages: null, messages: null}
      let hasError = false
      const passValidationResult = validator({type: ValidatorTypes.minimumLengthString, nChar: 3, value: passV, fieldName: 'pass', showFieldName: true})
      const topicValidationResult = validator({type: ValidatorTypes.minimumLengthString, nChar: 3, value: topicV, fieldName: 'topic', showFieldName: true})
      const typeValidationResult = validator({type: ValidatorTypes.minimumLengthString, nChar: 3, value: typeV, fieldName: 'type', showFieldName: true})
      
      if(!passValidationResult.isValid){
        hasError = true
        errObj.pass = passValidationResult.errs[0]
      }
      if(!topicValidationResult.isValid){
        hasError = true
        errObj.topic = topicValidationResult.errs[0]
      }
      if(!typeValidationResult.isValid){
        hasError = true
        errObj.type = typeValidationResult.errs[0]
      }
      //if no langugage was selected by user
      if(languages.length === 0){
        hasError = true
        errObj.languages = 'you have to choose at least one language'

      }else {

        //if any of message textArea is empty
        if(messages.find(msg => msg.value.trim().length === 0)){
          hasError = true
          errObj.messages = 'messages fields must not be empty'
        }
      }
      //if there is no platform selcted
      if(platforms.length === 0){
        hasError = true
        errObj.platforms = 'you have to enter at least one platform'
      }
      if(versions.length === 0){
        hasError = true
        errObj.versions = 'you have to enter at least one version'
      }
      //if we do have an error, we dont want to continue
      if(hasError){
        toast.error(Object.values(errObj).find(err => err !== null))
        return setErrorsObj(errObj)
      }

      //then we have to extract input values 
      const reqBody: SendAdminPublishMessageReqT = {
        pass: passV,
        topic: topicV,
        qos: qualityOfServiceV,
        retained: isRetained,
        payload: {
          type: typeV,
          content: [{
            timestamp: new Date().getTime(),
            platforms: platforms.map(pl => pl.name),
            accounts: [],
            versions: versions,
            languages: languages.map(lang => {
              return {
                content: messages.find(msg => msg.langId.toString() === lang.value.toString()).value,
                language_id: +lang.value
              }
            })
          }]
        }
      }


      try {
        setLoading(true)
        console.log(reqBody);
        
        const res = await sendAdminPublishMessage(userRedux.access_token, reqBody, {dispatch, navigate, refresh_token: userRedux.refresh_token, toast })
        setLoading(false)
        if(res.status === 200){
          toast('your topic was sent successfully')
          //then we have to clear all fields
          setPassV('')
          setTopicV('')
          setTypeV('')
          setVersions([])
          setVersionV('')
          setPlatforms([])
          setMessages([])
          setIsRetained(false)
          setLanguages([])
        }
      }
      catch(err){
        setLoading(false)
      }
    }


    const confirmBtnProps: ButtonAProps = {
        type: 'primary',
        children: 'Confirm',
        onClick: submitHandler,
        loading: loading
    }

    //textInput for entering new version value
    const versionAdd_children = (
      <FormControl content={AppVersionFC} value={versionV} setParentValue={setVersionV} isValid={true} setIsValid={() => {}} errorMsg={null} />
    )

    const versionAdd_addHandler = () => {
      // console.log('asdasdas')
      if(versionV.trim().length === 0){
        return
      }
      //we have to add the versionV to the versionsArr which were confirmed
      setVersions(prev => ([...prev, versionV]))
      //then we have to empty the version input
      setVersionV('')
    }

    const versionAdd_removeHandler = (vers: string) => {
        setVersions(prev => [...prev.filter(c => c !== vers)])
    }

    const platform_removeHandler = (value: string|number) => {
      setPlatforms(prev => [...prev.filter(c => c.value !== value)])
    }


    const languages_removeHandler = (value: string|number) => {
      setLanguages(prev => [...prev.filter(c => c.value !== value)])
      setMessages(prev => [...prev.filter(c => c.langId.toString() !== value.toString())])
    }


    const qualityOfServiceChanger = (e: any, value: number, label: string, object: object) => {
      setQualityOfServiceV(value)
  }

    return (
      <Template>
        <div className={sendTopic}>
           <MainPanel title='Send Topic' headButtons={[confirmBtnProps]}>
              <div className={filterSpot}>
                <Filter 
                  versionAdd_confirmedArr={versions}
                  versionAdd_addHandler={versionAdd_addHandler}
                  versionAdd_children={versionAdd_children}
                  versionsAdd_FC={true}
                  versionAdd_removeHandler={versionAdd_removeHandler}
                  platformAdd_FC={true}
                  platformAdd_isMultiValue={true}
                  platformAdd_values={platforms}
                  platformAdd_setValues={setPlatforms}
                  platformAdd_options={PLATFORMS_ARR}
                  platformAdd_removeHandler={platform_removeHandler}
                  languagesAdd_FC={true}
                  languagesAdd_isMultiValue={true}
                  languagesAdd_values={languages}
                  languagesAdd_options={languagesSelection || []}
                  languagesAdd_setValues={setLanguages}
                  languages_removeHandler={languages_removeHandler}
                  passContent={passFC} passValue={passV} setPassValue={setPassV}
                  topicContent={TopicFC} topicValue={topicV} setTopicValue={setTopicV}
                  hasRetained={true} isRetained={isRetained} setIsRetained={setIsRetained}
                  qualityOfServiceFC={qualityOfServiceFC_creator()} qualityOfServiceValue={qualityOfServiceV} setQualityOfServiceValue={qualityOfServiceChanger}
                  typeContent={TypeFC} typeValue={typeV} setTypeValue={setTypeV}
                  hideConfirmBtn={true}
                />
              </div>
              <div className={body}>
                {
                  languagesSections
                }
              </div>
           </MainPanel>
        </div>
      </Template>
    )
}

export default SendTopic

