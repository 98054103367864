import styles from './filterM.module.scss'

import DatePicker from '../datePicker/reactDatePicker'
import ButtonA from '../buttons/buttonA'
import RightImg from '../../assets/images/right.png'
import CalenderImg from '../../assets/images/calender.png'
import FormControl,{ OtherInputsContent, SelectContent} from '../formControl/formControl'
const {filter, filterItem, filterLabel, filterValue, arrIcon, calIcon, createdRange, confirmBtn, dateBox,fromHour,toHour
        
    ,platformA, platformB ,sort,talkType,mediaServerA, mediaServerB,media} = styles

type FilterProps = {
    createdAt?: {from: Date, to: Date}
    setCreatedAt?: (a: any) => void   

    confirmHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void
    loading?: boolean
    hideConfirmBtn?: boolean

    setMediaDomainValue?:(a:string)=>void
   fromHourValue?: string 
   setFromHourValue?: (a: string) => void
  fromHourContent?: OtherInputsContent 
   
    toHourValue?: string 
    setToHourValue?: (a: string) => void
  toHourContent?: OtherInputsContent 

     platformFC?: SelectContent
    platformAValue?: number
    setPlatformAValue?: (e: any, value: number, label: string, object: object) => void
    
    platformBValue?: number
  setPlatformBValue?: (e: any, value: number, label: string, object: object) => void
    
     sortFC?: SelectContent
    sortValue?: number|string
  setSortValue?: (e: any, value: number, label: string, object: object) => void
    
      talkTypeFC?: SelectContent
    talkTypeValue?: number
  setTalkTypeValue?: (e: any, value: number, label: string, object: object) => void
    
         mediaServerAValue?: string 
    setMediaServerA?: (a: string) => void
  mediaServerAContent?: OtherInputsContent 

        mediaServerBValue?: string 
    setMediaServerB?: (a: string) => void
  mediaServerBContent?: OtherInputsContent 
   
    mediaValue?: string 
    setMedia?: (a: string) => void
  mediaContent?: OtherInputsContent 
}

const Filter:React.FC<FilterProps> = ({
    createdAt, setCreatedAt,confirmHandler, loading,hideConfirmBtn,fromHourValue,fromHourContent,setFromHourValue,toHourValue,setToHourValue,toHourContent,platformFC,platformAValue,setPlatformAValue,platformBValue,setPlatformBValue,sortFC,sortValue,setSortValue,talkTypeFC,talkTypeValue,setTalkTypeValue,mediaServerAContent,mediaServerAValue,setMediaServerA,mediaServerBContent,mediaServerBValue,setMediaServerB,mediaContent,mediaValue,setMedia
    
}) => {




    return (
    <div className={filter}>
     
        {
        createdAt && setCreatedAt ?
        <div className={[filterItem, createdRange].join(' ')}>
            <div className={filterLabel}>created at:</div>
            <div className={filterValue}>
                <div className={dateBox}><DatePicker date={createdAt.from} setDate={(date) => {setCreatedAt(prev => ({...prev,from: date}))}} placeholder="Start date"/></div>
                <img src={RightImg} className={arrIcon}/>
                <div className={dateBox}><DatePicker date={createdAt.to} setDate={(date) => {setCreatedAt(prev => ({...prev, to: date}))}} placeholder="End date"/></div>
                <img src={CalenderImg} className={calIcon}/>
            </div>
        </div>
        :""
        }
          {
        fromHourContent ?
        <div className={[filterItem, fromHour].join(' ')}>
            <div className={filterLabel}>from hour:</div>
            <div className={filterValue}>
                <FormControl content={fromHourContent} value={fromHourValue} setParentValue={setFromHourValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
            {
        toHourContent ?
        <div className={[filterItem, toHour].join(' ')}>
            <div className={filterLabel}>To hour:</div>
            <div className={filterValue}>
                <FormControl content={toHourContent} value={toHourValue} setParentValue={setToHourValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
               {    
        platformFC ? 
        <div className={[filterItem, platformA].join(' ')}>
            <div className={filterLabel}>platformA:</div>
            <div className={filterValue}>
                <FormControl content={platformFC} value={platformAValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setPlatformAValue}/>
            </div>
        </div>
        : ""
        }
           {    
        platformFC ? 
        <div className={[filterItem, platformB].join(' ')}>
            <div className={filterLabel}>platformB:</div>
            <div className={filterValue}>
                <FormControl content={platformFC} value={platformBValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setPlatformBValue}/>
            </div>
        </div>
        : ""
        }
           {    
        sortFC ? 
        <div className={[filterItem, sort].join(' ')}>
            <div className={filterLabel}>sort by:</div>
            <div className={filterValue}>
                <FormControl content={sortFC} value={sortValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setSortValue}/>
            </div>
        </div>
        : ""
        }
           {
        talkTypeFC ?
        <div className={[filterItem, talkType].join(' ')}>
            <div className={filterLabel}>Type:</div>
            <div className={filterValue}>
                <FormControl content={talkTypeFC} value={talkTypeValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setTalkTypeValue}/>
            </div>
        </div>
        :""
        }
              {
        mediaServerAContent ?
        <div className={[filterItem, mediaServerA].join(' ')}>
            <div className={filterLabel}>Media Server Per A:</div>
            <div className={filterValue}>
                <FormControl content={mediaServerAContent} value={mediaServerAValue} setParentValue={setMediaServerA} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
            {
        mediaServerBContent ?
        <div className={[filterItem, mediaServerB].join(' ')}>
            <div className={filterLabel}>Media Server Per B:</div>
            <div className={filterValue}>
                <FormControl content={mediaServerBContent} value={mediaServerBValue} setParentValue={setMediaServerB} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
                {
        mediaContent ?
        <div className={[filterItem, media].join(' ')}>
            <div className={filterLabel}>Media:</div>
            <div className={filterValue}>
                <FormControl content={mediaContent} value={mediaValue} setParentValue={setMedia} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
            !hideConfirmBtn &&  
            <div className={confirmBtn}>
                <ButtonA children={'filter result'} type='primary' loading={loading} onClick={confirmHandler}/>
            </div>
        }
    
    </div>
    )
}


export default Filter