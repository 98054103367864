import Template from '../../components/template/template'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import { useState, useEffect } from 'react'
import SpinnerB from '../../components/spinner/spinnerB'
import CountryTalkListTable from '../../components/tables/countryTalkListTable/countryTalkListTable'

import styles from './countryTalkDetail.module.scss'
import { useTypedSelector, useDispatch } from '../../redux'
import {SelectContent} from '../../components/formControl/formControl'
import {LangT, CharacterT, CountryTalkWithPalCountriesT, CountryTalkT} from '../../utils/models'
import {countryTalkSortFC_creator, endReasonFC_creator, internetQualityFC_creator, langFC_creator, platformFC_creator, sortFC_creator, statusFC_creator} from '../../utils/formControl'
import {GetCharacters,GetCharactersResT, GetLangs, GetLangsResT} from '../../utils/requests'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { PATHES, static_token } from '../../utils/contants'
import STATIC_DATA from '../../utils/staticData.json'
import { useLocation } from 'react-router-dom'
import { historyPropertiesExistanceCheck } from '../../utils/utility'
import CountryTalkDetailTable from '../../components/tables/countryTalkDetailTable/countryTalkDetailTable'
import { ButtonAProps } from '../../components/buttons/buttonA'
import { DesiredFieldX123T, reconstruct_country_talk_data_for_graph_v_1, reconstruct_country_talk_data_for_graph_v_2, reconstruct_country_talk_data_for_graph_v_3, reconstruct_country_talk_data_for_graph_v_4, reconstruct_country_talk_data_for_graph_v_5, reorder_country_talks } from '../../utils/others'
import Filter from '../../components/filters/filterE'
import Tabs from '../../components/tab/tabs'
import StackedAreaChart from '../../components/charts/stackedAreaChart/stackedAreaChart'
const {countryTalkDetail, container, backBtn, filterSpot, body} = styles

const CountryTalkDetail = () => {
  const location = useLocation()
  const countryTalkDetailData: CountryTalkWithPalCountriesT = historyPropertiesExistanceCheck(location, 'countryTalkDetail', null)
  const from = historyPropertiesExistanceCheck(location,'from',null)
  const filteredDates = historyPropertiesExistanceCheck(location,'filteredDates',null)
  const [sortV,setSortV] = useState<number|string>(0)
  const [sortFC, setSortFC] = useState<SelectContent|null>(countryTalkSortFC_creator(countryTalkDetailData))
  const [countryTalkList,setCountryTalkList] = useState<CountryTalkT[]>(Object.values(countryTalkDetailData.PalCountries) || [])
  const [selectedTabId, setSelectedTabId] = useState<number>(0)
  console.log('location --->', location)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const [loading, setLoading] = useState(false)

 



  //configs based on roles and status comes from redux for dropdown
  let reconstructed_data_for_chart_v_1 = null
  let reconstructed_data_for_chart_v_2 = null
  let reconstructed_data_for_chart_v_3 = null
  let reconstructed_data_for_chart_v_4 = null
  let reconstructed_data_for_chart_v_5 = null
  if(countryTalkList.length > 0){
    reconstructed_data_for_chart_v_1 = reconstruct_country_talk_data_for_graph_v_1(countryTalkList)
    reconstructed_data_for_chart_v_2 = reconstruct_country_talk_data_for_graph_v_2(countryTalkList)
    reconstructed_data_for_chart_v_3 = reconstruct_country_talk_data_for_graph_v_3(countryTalkList)
    reconstructed_data_for_chart_v_4 = reconstruct_country_talk_data_for_graph_v_4(countryTalkList)
    reconstructed_data_for_chart_v_5 = reconstruct_country_talk_data_for_graph_v_5(countryTalkList)
  }


  const backToCountryTalkListHandler = () => {
    navigate({
      pathname: PATHES.countryTalkList,
    }, {
        state: {
            filteredDates: filteredDates
        }
    })
  }

  const buttonsArr: ButtonAProps[] = [{
    children: 'Back',
    type: 'primary',
    onClick: backToCountryTalkListHandler,
    externalClass: backBtn
  }]


  const sortChanger = (e: any, value: string | number, label: string, object: object) => {
    setSortV(value)
    //then we want to change the order of countryTalks based on the sortValue
    const newCountryTalkList = reorder_country_talks(Object.values(countryTalkDetailData.PalCountries), value as DesiredFieldX123T)
    setCountryTalkList(newCountryTalkList as CountryTalkT[])
}

  return(
    <Template>
      <div className={countryTalkDetail}>
        {
        // !loading && langFC && allChars ?
        !loading && userRedux.access_token ?
        <MainPanel title={`Country Talk Detail = ${countryTalkDetailData.country}`} headButtons={buttonsArr}>
          <div className={container} style={{width: '100%'} as React.CSSProperties}>
            <div className={filterSpot}>
              <Filter sortFC={sortFC} sortValue={sortV} setSortValue={sortChanger} hideConfirmBtn={true}/>
            </div>
            <div className={body}>
              <Tabs 
                  components={[
                    <CountryTalkDetailTable 
                    countryTalkList={countryTalkList}
                    
                    access_token={userRedux.access_token} refresh_token={userRedux.refresh_token} />,
                      <StackedAreaChart data={reconstructed_data_for_chart_v_1} title='' key="A" xInterval={1}/>,
                      <StackedAreaChart data={reconstructed_data_for_chart_v_2} title='' key="B" xInterval={1}/>,
                      <StackedAreaChart data={reconstructed_data_for_chart_v_3} title='' key="C" xInterval={1}/>,
                      <StackedAreaChart data={reconstructed_data_for_chart_v_4} title='' key="D" xInterval={1}/>,
                      <StackedAreaChart data={reconstructed_data_for_chart_v_5} title='' key="E" xInterval={1}/>
                  ]}
                  selectedTabId={selectedTabId}
                  data={[
                      {id: 0, title: 'table'},
                      {id: 1, title: 'talk'},
                      {id: 2, title: 'country'},
                      {id: 3, title: 'gender'},
                      {id: 4, title: 'total dur'},
                      {id: 5, title: 'avg dur'}
                  ]}
                  tabChanger={(tabId) => setSelectedTabId(tabId)}
              />
            </div>
            </div>
        </MainPanel>
        :
        <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
          <SpinnerB/>
        </div>
        }
      </div>
  </Template>  
  )
}



export default CountryTalkDetail