import styles from './talkStep.module.scss'
import { useNavigate } from "react-router-dom"
import Template from "../../components/template/template"
import { useDispatch, useTypedSelector } from "../../redux"
import { changeHourOfISODate, historyPropertiesExistanceCheck } from "../../utils/utility"
import { useState } from "react"
import { GetTalkStep, TalkStepFilterT, TalkStepResT, talkStepReportT } from "../../utils/requests"
import MainPanel from "../../components/panels/mainPanel/mainPanel"
import Tabs from '../../components/tab/tabs'
import Filter from '../../components/filters/filterF'
import {toast} from 'react-toastify'
import {prepare_data_for_talk_MultilineChartDataset_TalkStep, reorder_date_fields } from '../../utils/others'
import MultipleLineChartDataSet from '../../components/charts/LineChart/MultipleLineChartDataSet'
import { justNumberInputChanger } from '../../utils/validator'
import { accountIdFC, stringFC } from '../../utils/formControl'

const { container,filterSpot,body} = styles
const TalkSteps: React.FC = () => {
     const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
    const dispatch = useDispatch()
  const navigate = useNavigate()
   const today = new Date()
  const yesterday = new Date(today.getTime() - 1 *24*60*60*1000)
  const filteredDates = historyPropertiesExistanceCheck(location, 'filteredDates', null) as { from: Date | null, to: Date | null } | null
   const [loading,setLoading] = useState(false)
    const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({
      from: filteredDates && filteredDates.from ? filteredDates.from : today,
    to: filteredDates && filteredDates.to ? filteredDates.to : today
    })
    const [fromHourV, setFromHourV] = useState('0')
  const [toHourV, setToHourV] = useState('23')
  const [selectedTabId, setSelectedTabId] = useState(0)
  const [mediaV, setMediaV] = useState('')
  const [confirmMediaV, setConfirmMediaV] = useState('')

  
  const [confirmedFilterPhrase, setConfirmedFilterPhrase] = useState<TalkStepFilterT | null>({
     from: changeHourOfISODate(createdAt.from, Number(fromHourV), 0, 0),
      to: changeHourOfISODate(createdAt.to, Number(toHourV), 0, 0)
  })
 const [talkStepReport, setTalkStepReport] = useState<talkStepReportT[]>([])
    const fromHourChanger = justNumberInputChanger(setFromHourV)
   const toHourChanger = justNumberInputChanger(setToHourV)
  const fetchData = async (access_token: string, filterPhrase: TalkStepFilterT) => {
  try {
      setLoading(true)
      const res = await GetTalkStep({token: access_token, filters: filterPhrase},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
      setLoading(false)
      if(res.status === 200){
        const resData = res.data as TalkStepResT
        if (resData) {
        setTalkStepReport(resData)                     
        }
          else {
            setTalkStepReport([])
          }
      }
  }
  catch(err){
      setLoading(false)
  }
}
   const confirmHandler = async() => {
  if(loading){
    return
  }
 
  try {
   //first we have to populate input values if exists any
    const filteredPhrase: TalkStepFilterT = { 
        from: confirmedFilterPhrase.from,
      to: confirmedFilterPhrase.to,
    }
    let from: Date | null = null
    let to: Date | null = null
    //if createdAt is selected
    if(createdAt && createdAt.from){
      from = createdAt.from as Date
      filteredPhrase.from = changeHourOfISODate(from,Number(fromHourV),0, 0)
    }
    if(createdAt && createdAt.to){
      to = createdAt.to as Date
      filteredPhrase.to = changeHourOfISODate(to, Number(toHourV), 59, 59)
    }
     
           setConfirmMediaV(mediaV)
    
    reorder_date_fields(createdAt, filteredPhrase)
    setConfirmedFilterPhrase(filteredPhrase)
    await fetchData(userRedux.access_token, filteredPhrase)
  }
  catch(err){
  }
  }

  // let data_for_chart = prepare_data_for_talk_MultilineChartDataset_TalkStep(talkStepReport)
  let data_for_chart2 = prepare_data_for_talk_MultilineChartDataset_TalkStep(talkStepReport,confirmMediaV)
  return (
    <>
      <Template>
        <>
        <MainPanel title='Talks Steps' headButtons={[]}>
          <div className={container} style={{ width: '100%' } as React.CSSProperties}>
             <div className={filterSpot}>
            <Filter  confirmHandler={confirmHandler} loading={loading} 
                createdAt={createdAt} setCreatedAt={setCreatedAt} fromHourValue={fromHourV} setFromHourValue={fromHourChanger} fromHourContent={accountIdFC} toHourValue={toHourV} setToHourValue={toHourChanger} toHourContent={accountIdFC}mediaContent={stringFC} mediaValue={ mediaV} setMedia={setMediaV} 
              />
            </div>
             <div className={body}>
              <Tabs
                components={[
                   <MultipleLineChartDataSet title='chart' dataPoints={data_for_chart2} xLabel='' yLabel=''/>
                   // <TalkStepsTable talkStepData={talkStepReport} test={test}/>,
                ]}
                data={[
                  {id: 0, title: " Chart"},   
                 
                ]}
                selectedTabId={selectedTabId}
                tabChanger={(tabId) => setSelectedTabId(tabId)}
              />
            </div>
          </div>
        </MainPanel>
      </>
      </Template>
    </>
  )
}
export default TalkSteps