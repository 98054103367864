import styles from './dashboard.module.scss'
import { useEffect, useState } from 'react'
import Filter from '../../filters/filterD'
import {changeHourOfISODate} from '../../../utils/utility'
import { useTypedSelector, useDispatch } from '../../../redux/index'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {RefreshTimeFC, FromHourFC, FromMinFC, ToHourFC, ToMinFC} from '../../../utils/formControl'
import { GetDashboard1, GetDashboard2, GetDashboard1ResT, GetDashboard2ResT, GetTalkStatsFiltersT} from '../../../utils/requests'
import { justHourInputChanger, justMinInputChanger } from '../../../utils/validator'
import { reorder_date_fields } from '../../../utils/others'
import Tabs from '../../tab/tabs'
import DashboardInfo from './DashboardInfo'
import DashboardGraph from '../../dashboardgraphs/DashboardGraph'



const { dashboard, container, filterSpot,body} = styles


type DashboardPropsT = {
    
}

const Dashboard: React.FC<DashboardPropsT> = props => {
     const navigate = useNavigate()
    const dispatch = useDispatch()
    const today = new Date()
    const yesterday = new Date(today.getTime() - 1 *24*60*60*1000)
   
    let intervalIds = []
    let defaultRefreshTime = 30
    const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
 
    const [onlineUsers, setOnlineUsers] = useState<number|null>(null) 
    const [inSearchUsers, setInSearchUsers] = useState<number|null>(null) 
    const [currentActiveCalls, setCurrentActiveCalls] = useState<number|null>(null) 
    const [inHistoryActiveCalls, setInHistoryActiveCalls] = useState<number|null>(null) 
    const [avgDurationPerCall, setAvgDurationPerCall] = useState<number|null>(null) 
    const [avgDurationPerUser, setAvgDurationPerUser] = useState<number|null>(null) 
    const [failedPercentage, setFailedPercentage] = useState<string |null>(null)
  const [exitedPercentage, setExitedPercentage] = useState<string | null>(null)
  const [progressCall, setProgressCall] = useState<number | null>(0)
    // const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({from: null, to: null})
    const [refreshTimeV, setRefreshTimeV] = useState(defaultRefreshTime.toString())
    const [refreshTimeIsValid, setRefreshTimeIsValid] = useState(true)
    const [fromHourV, setFromHourV] = useState('')
    const [toHourV, setToHourV] = useState('')
    const [fromMinV, setFromMinV] = useState('')
    const [toMinV, setToMinV] = useState('')
    const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({from: yesterday, to: today})
    const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [men, setMen] = useState(0)
  const [women, setWomen] = useState(0)
  const [womenRate, setWomenRate] = useState(0)
  const [selectedTabId, setSelectedTabId] = useState(0)
  
  




  // const fetchCharacters = (data) => {
  //   let manArray = []
  //   let womenArray = []
  //   let totalCharactersArray=[]
  //   const characters = data?.map(item => item.characters)
  //   characters?.map(char => {
  //     const manChars = char.filter((char, index) => [1, 2].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)
  //     const womanChars = char.filter((char, index) => [3, 4].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)
  //     const total = manChars + womanChars;
  //     manArray = [...manArray, manChars]     
  //     womenArray = [...womenArray, womanChars]
  //     totalCharactersArray = [...totalCharactersArray, total]
  //     // console.log('total cha',totalCharactersArray);
      
  //   })
  //   const totalMen = manArray.reduce((total, num) => {
  //       return total + num;      
         
  //      }, 0);
  //   setMen(totalMen)

  
  //      const totalWomen = womenArray.reduce((total, num) => {
  //     return total + num;
  //      }, 0);
  //   setWomen(totalWomen)

  //         const totalCharacters = totalCharactersArray.reduce((total, num) => {
  //     return total + num;
  //   }, 0);
  //  console.log('men',totalMen);
  //   console.log('women',totalWomen);
  //   console.log('total',totalCharacters);
    
    
      
  //   const womenRate = (totalWomen / totalCharacters) * 100
  //   setWomenRate(Number(womenRate.toFixed(1)))
    
  //   }
      
    useEffect(() => {

        //componentDidUnmount
        return () => {
            //we have to clear all intevals before unmounting the component
            console.log('cleared --->',intervalIds)
            console.log('<componentDidUnmount> of dashboard')
            setLoading(false)
            for(const intervalId of intervalIds){
                console.log('intervalId is cleared --->',intervalId)
                clearInterval(intervalId)
                // console.log(`interval => ${intervalId} cleared`)
            } 
            intervalIds = []
            // console.log('intervalIds must be [] --->',intervalIds)
            
        }
    }, [])

    useEffect(() => {
        const fetchData = async (token, refresh_token, filterPhrase) => {
          
            if(loading){
                //console.log(loading)
                // return
            }
            //fetching the first part of the data
                   
            const firstFetch = new Promise((resolve, reject) => {
                GetDashboard1({token, filters: filterPhrase}, {dispatch,navigate,toast,refresh_token})
                .then(res => {
                    if(res.status === 200){
                      const data = res.data as GetDashboard1ResT
                      // console.log('data',data);                      
                        setCurrentActiveCalls(data.active_room_count)
                        setAvgDurationPerUser(Math.round(data.total_talk_average))
                        setAvgDurationPerCall(Math.round(data.started_talk_average))
                        setInHistoryActiveCalls(data.total_room)
                        setFailedPercentage(Number((data.total_failed_talks/(data.total_room+data.total_exited_talks+data.total_failed_talks+data.active_room_count))*100).toFixed(1))
                      setExitedPercentage(Number((data.total_exited_talks / (data.total_room + data.total_exited_talks + data.total_failed_talks + data.active_room_count)) * 100).toFixed(1))
                      setMen(data.men_pals)
                      setWomen(data.women_pals)
                      setWomenRate(Number(((data.women_pals / data.total_pals) * 100).toFixed(1)))
                      setProgressCall(data.progress_call)
                    }
                    resolve(true)
                })
                .catch(err => {
                    reject(err)
                })
            });
          // fetchCountryData(token)
                  // fetchCharacters(characters)
            const secondFetch = new Promise((resolve, reject) => {
                GetDashboard2(token, {dispatch,navigate,toast,refresh_token})
                .then(res => {
                    if(res.status === 200){
                        const data = res.data as GetDashboard2ResT
                        setOnlineUsers(data.online_users)
                        setInSearchUsers(data.search_users)
                    }
                    resolve(true)
                })
                .catch(err => {
                    reject(err)
                }) 
            });
            try {
                //console.log('before getting')
                setLoading(true)
                await Promise.all([
                    firstFetch,
                    secondFetch
                ]).finally(() => {
                    setLoading(false)
                })
                // console.log('after getting')
            }
            catch(err){

            }
           
       
    
        }
      

        //if we do have tokens, we want to perform the task
        if(!userRedux || !userRedux.access_token || !userRedux.refresh_token ){
            return
        }
        let refreshT = +refreshTimeV
        if(refreshTimeV === "" || Number.isNaN(+refreshTimeV)){
            // console.log('input is not number')
            return
        }
        // console.log('is number')
        
        //then register another intervals with registered values 
        // fetchData(userRedux.access_token, userRedux.refresh_token, {}).finally(() => {
            const intId = setInterval(async () => {
                try{
                    await fetchData(userRedux.access_token, userRedux.refresh_token, {})
                }
                catch(err){
                        
                }
            }, refreshT*1000)
            // console.log('intervalId is registered --->', intId)
            intervalIds.push(intId)
        // })
        
        return () => {
            //first we have to remove all the registered interval tasks
            // console.log('cleared --->',intervalIds)
            setLoading(false)
            for(const intervalId of intervalIds){
                // console.log('intervalId is cleared --->',intervalId)
                clearInterval(intervalId)
            } 
            intervalIds = []
            // console.log('intervalIds must be [] --->',intervalIds)
            
        }
    }, [userRedux, refreshTimeV])


    const fromHourChanger = justHourInputChanger(setFromHourV)
    const toHourChanger = justHourInputChanger(setToHourV)

    const fromMinChanger = justMinInputChanger(setFromMinV)
    const toMinChanger = justMinInputChanger(setToMinV)



    const fetchData = async (token, refresh_token, filterPhrase) => {
        
        if(loading){
            //console.log(loading)
            // return
        }
        //fetching the first part of the data
        const firstFetch = new Promise((resolve, reject) => {
          GetDashboard1({ token, filters: filterPhrase }, { dispatch, navigate, toast, refresh_token })
             
              
            .then(res => {
                if(res.status === 200){
                  const data = res.data as GetDashboard1ResT
                  console.log('firstFetch',data);
                  
                    setCurrentActiveCalls(data.active_room_count)
                    setAvgDurationPerUser(Math.round(data.total_talk_average))
                    setAvgDurationPerCall(Math.round(data.started_talk_average))
                    setInHistoryActiveCalls(data.total_room)
                    setFailedPercentage(Number((data.total_failed_talks/(data.total_room+data.total_exited_talks+data.total_failed_talks+data.active_room_count))*100).toFixed(1))
                  setExitedPercentage(Number((data.total_exited_talks / (data.total_room + data.total_exited_talks + data.total_failed_talks + data.active_room_count)) * 100).toFixed(1))
                   setMen(data.men_pals)
                      setWomen(data.women_pals)
                      setWomenRate(Number(((data.women_pals / data.total_pals) * 100).toFixed(1)))
                      setProgressCall(data.progress_call)
                }
                resolve(true)
            })
            .catch(err => {
                reject(err)
            })
        });
        const secondFetch = new Promise((resolve, reject) => {
            GetDashboard2(token, {dispatch,navigate,toast,refresh_token})
            .then(res => {
                if(res.status === 200){
                    const data = res.data as GetDashboard2ResT
                    setOnlineUsers(data.online_users)
                    setInSearchUsers(data.search_users)
                }
                resolve(true)
            })
            .catch(err => {
                reject(err)
            }) 
        });
        try {
            //console.log('before getting')
            setLoading(true)
            await Promise.all([
                firstFetch,
              secondFetch,
              
                           ]).finally(() => {
                setLoading(false)
            })
            // console.log('after getting')
        }
        catch(err){

        }
       
   

    }
// //fetch contrylist api 
//   const fetchCountryData = async (access_token) => {
//     const date = new Date ();
//     const hours = date.getHours();
//     const minutes = date.getMinutes()    
//     const seconds = date.getSeconds()  
//         try {
//             setLoading(true)
//             const filters: GetCountryTalkListFilterT = {
//                 from: changeHourOfISODate(createdAt.from, hours, minutes, seconds),
//                 to: changeHourOfISODate(createdAt.to, hours, minutes, seconds),   
//           }
//           console.log('filters',filters);
          
//             const res = await GetCountryTalkList({token: access_token, filters},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
//             setLoading(false)
//           if (res.status === 200) {            
//             const resData = res.data as GetCountryTalkListResT
//             console.log('schema',resData);
//               const array = Object.values(res.data.data).map(item => item)
//               fetchCharacters(array)              
//             }
//         }
//         catch(err){
//             setLoading(false)
//         }
//     }

  

    const confirmHandler = async () => {
        if(loading){
            // return
        }
        let fromHour = 0
        let fromMin = 0
        let toHour = 23
        let toMin = 59
        let fromDate = ''
        let toDate = ''
        //first we have to grab the values from the hour and min inputs
        if(fromHourV.length > 0){
            fromHour = +fromHourV
        }
        if(toHourV.length > 0){
            toHour = +toHourV
        }
        if(fromMinV.length > 0){
            fromMin = +fromMinV
        }
        if(toMinV.length > 0){
            toMin = +toMinV
        }
        //then we have to create timestring from inputs and selected 
        if(createdAt.from){
          fromDate = changeHourOfISODate(createdAt.from, fromHour, fromMin, 0)
          
          
        }
        if(createdAt.to){
          toDate = changeHourOfISODate(createdAt.to, toHour, toMin, 59)
        
        }
        const filter: GetTalkStatsFiltersT = {
            from: fromDate,
            to: toDate
        }
        //then we have to correct the order of from and to 
        reorder_date_fields(createdAt, filter)

      try {
          
          
            await fetchData(userRedux.access_token, userRedux.refresh_token, filter)
        }
        catch(err){

        }
    }

    // console.log('exitedPercentage --->',exitedPercentage)
  return (
      
    <div className={dashboard}>
      <div className={body}>
            <div className={filterSpot}>
                <Filter confirmHandler={confirmHandler} loading={loading}
                    refreshTimeFC={RefreshTimeFC}
                    refreshTimeValue={refreshTimeV}
                    setRefreshTimeValue={setRefreshTimeV} refreshTimeIsValid={refreshTimeIsValid}
                    setRefreshTimeIsValid={setRefreshTimeIsValid}
                    fromHourFC={FromHourFC} fromHourV={fromHourV} setFromHourV={fromHourChanger}
                    fromMinFC={FromMinFC} fromMinV={fromMinV} setFromMinV={fromMinChanger}
                    toHourFC={ToHourFC} toHourV={toHourV} setToHourV={toHourChanger}
                    toMinFC={ToMinFC} toMinV={toMinV} setToMinV={toMinChanger}
                   
                />
        </div>
        <div >
        <Tabs components={[
            <DashboardInfo data={{ onlineUsers, inSearchUsers, currentActiveCalls, inHistoryActiveCalls, avgDurationPerCall, failedPercentage, exitedPercentage, women, womenRate,progressCall }} />,
          <DashboardGraph/>
          ]} data={[
                  {id: 0, title: "info"},
                  {id: 1, title: "graph"},
               
                ]}   selectedTabId={selectedTabId}
                tabChanger={(tabId) => setSelectedTabId(tabId)} />
        <div className={container}>
       
        {/* <Billboard title='online users' data={onlineUsers} />
        <Billboard title='in-search users' data={inSearchUsers} />
        <Billboard title='active calls' data={currentActiveCalls} />
        <Billboard title='total calls (last 24hrs)' data={inHistoryActiveCalls} />
        <Billboard title='avg duration / call (last 24hrs)' data={avgDurationPerCall} />
        <Billboard title='failed calls(% Last 24hrs)' data={failedPercentage} />
        <Billboard title='exited calls(% Last 24hrs)' data={exitedPercentage} />
        <Billboard title='Total Women' data={women} />
        <Billboard title='Women rate ( % Last 24hrs)' data={womenRate}/> */}
              
      </div>
     </div>
      </div>
      </div>
    )
}


export default Dashboard