import React, { useEffect, useState } from 'react'
import styles from './usersuspensionlisttable.module.scss'
import {useDispatch} from '../../../redux/index'
import {SelectContent } from '../../formControl/formControl'
import DELETE from '../../../assets/images/delete.svg'
import {PATHES} from '../../../utils/contants'
import {BackendUserT,ResourceWithActions,RoleT, StatusT} from '../../../utils/models'
import { useNavigate } from 'react-router-dom'
import {accountIdFC, searchFC} from '../../../utils/formControl'
import Filter from '../../filters/filterA'
import MobilePagination from '../../pagination/mobilePagination'
import {getUserSuspensionList, GetUserSuspendListResT, getFilteredUserSuspensionList, adminDeleteSuspensionMember, GetFilteredUserSuspensionListReqT} from '../../../utils/requests'
import {USER_PER_PAGE} from '../../../utils/contants'
import {toast} from 'react-toastify'
import { changeHourOfISODate,phone_number_modifier_based_on_backend_instruction } from '../../../utils/utility'
import { CSVLink} from 'react-csv'
import ButtonA from '../../buttons/buttonA'
import { dateSplit, reorder_date_fields } from '../../../utils/others'


const {tableBox, filterBox, userListTable, headRow, bodyRow, userTable, paginationBox, noRecord, exportBtn,actionImage} = styles

type UserListTableProps = {
    roleFC: SelectContent
    statusFC: SelectContent
    allRoles: RoleT[]
    allStatuses: StatusT[]
    access_token: string
    refresh_token: string
    user_is_permitted_to_edit_users: boolean
    logged_in_user_email: string
}


const UserSuspensionListTable: React.FC<UserListTableProps> = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [users,setUsers] = useState<any[]>([])
    const [searchV, setSearchV] = useState('')
    const [roleV,setRoleV] = useState(0)
    const [statusV, setStatusV] = useState(0)
    const [page,setPage] = useState<number | null>(0)
    const [totalPage, setTotalPage] = useState<number | null>(0)
    const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({from: null, to: null})
    const [loading, setLoading] = useState(false)
    const [confirmedFilterPhrase, setConfirmedFilterPhrase] = useState<GetFilteredUserSuspensionListReqT>()

    

    const editHandler = (userData: {user: BackendUserT, permissions: ResourceWithActions[]}) => {
        navigate({pathname: PATHES.userEdit},{state: {from: PATHES.user, userData: userData}})
    }
    const roleChanger = (e: any, value: number, label: string, object: object) => {
        const selectedRole = props.roleFC.options.find((role) => role.value === value)
        setRoleV(+selectedRole.value)
    }
    const statusChanger = (e: any, value: number, label: string, object: object) => {
        const selectedStatus = props.statusFC.options.find(status => status.value === value)
        setStatusV(+selectedStatus.value)
    }
    const roleLogger = (roleValue: number,userData) => {
        return props.allRoles.find(role => role.value === roleValue)?.name ? props.allRoles.find(role => role.value === roleValue).name : 'NOT_SET_YET'
    }
    const statusLogger = (statusValue: number, userData) => {
        return props.allStatuses.find(status => status.value === statusValue)?.name ? props.allStatuses.find(status => status.value === statusValue).name : 'NOT_SET_YET'
  }
     const removeHandler=async (token,id:number)=> {
       try {
                const res = await adminDeleteSuspensionMember(token, id,{dispatch,navigate,refresh_token: props.refresh_token,toast})
                
                
                if(res.status === 200){
                    const res = await getUserSuspensionList(token, {dispatch,navigate,refresh_token: props.refresh_token,toast})
                
                
                if(res.status === 200){
                   
                    const data: GetUserSuspendListResT = res.data 
                    setUsers(data.suspensions)
                    setPage(data.meta.current_page)
                    setTotalPage(data.meta.last_page)
                }
               
                }
            }
            catch(err){
              console.log(err.message);
              
            }
      
    }
    //for the first fetch 
    useEffect(() => {
        const fetchData = async (token) => {
            try {
                const res = await getUserSuspensionList(token, {dispatch,navigate,refresh_token: props.refresh_token,toast})
                if(res.status === 200){
                  const data: GetUserSuspendListResT = res.data                    
                    setUsers(data.suspensions)
                    setPage(data.meta.current_page)
                    setTotalPage(data.meta.last_page)
                }
            }
            catch(err){}
        }
        if(props.access_token){
          fetchData(props.access_token)
        }
    },[props.access_token])

    const fetchData = async (token,reqBody) => {
        try {
            setLoading(true)
            const res = await getFilteredUserSuspensionList(token,{dispatch,navigate,refresh_token: props.refresh_token,toast},reqBody)
            setLoading(false)
            if(res.status === 200){
                const data: GetUserSuspendListResT = res.data 
                if(data.suspensions){
                    setUsers(data.suspensions)
                    setPage(data.meta.current_page)
                    setTotalPage(data.meta.last_page)
                }
                else {
                    setUsers([])
                    setPage(0)
                    setTotalPage(0)
                }
            }
        }
        catch(err){
            setLoading(false)
        }
    }

    useEffect(() => {
        const fetchData = async (token,page) => {
            try {
                setLoading(true)
                const res = await getFilteredUserSuspensionList(token,{dispatch,navigate,refresh_token: props.refresh_token,toast},confirmedFilterPhrase,page)
                setLoading(false)
                if(res.status === 200){
                  const data: GetUserSuspendListResT = res.data
                  
                    if(data.suspensions){
                        setUsers(data.suspensions)
                        setPage(data.meta.current_page)
                        setTotalPage(data.meta.last_page)
                    }
                    else {
                        setUsers([])
                        setPage(0)
                        setTotalPage(0)
                    }
                }
            }
            catch(err){
                setLoading(false)
            }
        }
        if(props.access_token){
            fetchData(props.access_token,page)
        }
    },[page])

    //data we need to export as excel 
    let exportable_data: {index: number,account_id:number,expiration:string,duration:number,created_at: string}[] = []
    let exportable_headers = [{key: 'index', label: 'index'},{key: 'account_id', label: 'account_id'},{key: 'expiration', label: 'expiration'},{key: 'duration', label: 'duration'},{key: 'created_at', label: 'Created at'}]
    
  const rows = users?.map((userData, index) => {
      
    const {id, account_id, expiration, duration, created_at } = userData
    const expirationDate = dateSplit(expiration)
    const creationDate=dateSplit(created_at)
// Output: ["Hello,", "how", "are", "you?"]

        exportable_data.push({
            index: index+1,
          account_id,
          expiration,
            duration,            
            created_at })
        return (
            <tr className={bodyRow} key={`${index}-sdas`}>
                <td>{index+1}</td>
                <td>{account_id}</td>
                <td>{`${expirationDate[0]} AT ${expirationDate[1]}`}</td>
                <td>{duration} </td>
            <td>{`${ creationDate[0] } AT ${ creationDate[1] }`}</td>
              <td>
                    { props.user_is_permitted_to_edit_users&&<img src={DELETE} className={actionImage}  style={{cursor:'pointer'}} onClick={() => removeHandler(props.refresh_token,id)}/> }
                </td>
                {/* <td>
                    {props.user_is_permitted_to_edit_users && props.logged_in_user_email !== email ? <img src={PenImg} onClick={() => editHandler(userData)}/> : ''}
                </td> */}
            </tr>
        )
    })


    const confirmHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if(loading) return
        //first we have to populate input values if exists any
        const filteredPhrase: GetFilteredUserSuspensionListReqT = {size: USER_PER_PAGE}
        if(searchV.length > 0){
            filteredPhrase.account_id = phone_number_modifier_based_on_backend_instruction(searchV)
        }
        
        if(createdAt && createdAt.from){
            const from = createdAt.from
            filteredPhrase.from = changeHourOfISODate(from, 0, 0, 0)
        }
        if(createdAt && createdAt.to){
            const to = createdAt.to
            filteredPhrase.to = changeHourOfISODate(to, 23, 59, 59)
        }
        //then we have to correct the order of from and to 
        reorder_date_fields(createdAt, filteredPhrase)
        setConfirmedFilterPhrase(filteredPhrase)
        await fetchData(props.access_token, filteredPhrase)
    }


    return(
        <div className={userListTable}>
            <div className={filterBox}>
                <Filter confirmHandler={confirmHandler} searchContent={accountIdFC} searchValue={searchV} setSearchValue={setSearchV}  createdAt={createdAt} setCreatedAt={setCreatedAt} loading={loading}/>
            </div>
            <div className={tableBox}>
                <div className={exportBtn}>
                    <CSVLink data={exportable_data} headers={exportable_headers} filename={"test.csv"} >
                        <ButtonA children={`export excel List`} onClick={() => {}} type="secondary"/>
                    </CSVLink>
                </div>
                {/* <Report button={<button>dddd</button>} columns={exportable_headers} dataSet={exportable_data} title={"users"}/> */}
                <table className={userTable}>
                    <thead>
                        <tr className={headRow}>
                            <td style={{minWidth: '2em'} as React.CSSProperties}></td>
                            <td>Account Id</td>
                            <td>Expiration Date</td>
                            <td>Duration (Hours)</td>
                            <td>created at</td>
                             <td>Delete</td>
                
                            {/* <td style={{minWidth: '2em'} as React.CSSProperties}></td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                        rows.length > 0 ?
                        rows : 
                        <tr className={noRecord}><td colSpan={10}>there is no record</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className={paginationBox}>
                <MobilePagination parentCurrentPage={page} setParentCurrentPage={(page) => {setPage(page)}} totalPageNum={totalPage}/>
            </div>
        </div>

    )
}

export default UserSuspensionListTable







