import CanvasJSReact,{CanvasJSOptionsT} from '../../../0-0/canvasJS/canvasjs.react'
import './lineChart.module.scss'



type multipleLineChartDataSetPropsT = {
  title: string,
  xLabel: string,
  yLabel: string,
  dataPoints:any
}


const MultipleLineChartDataSet: React.FC<multipleLineChartDataSetPropsT> = (props) => {
  // console.log('chart',props.dataPoints);  
    const CanvasJSChart = CanvasJSReact.CanvasJSChart  
  const dataCreation = (data) => {
    let totaldata = []
     const data_for_each_dataset = props.dataPoints.map(item => {
   
    const created_obj = {
      type: 'line',
      name:item[0]?.name,
      showInLegend: true,
      // indexLabel: " {y}",
      markerType: "circle",
      startAngle: -90,
      // color:`#${Math.floor(Math.random() * 16777215).toString(16)}`,
      dataPoints: item.map(dp => ({ label: dp.label, y: dp.value }))
       }
       totaldata=[...totaldata,created_obj]
     })
      
    return totaldata
  }

  const options: CanvasJSOptionsT = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"
    title: {
      text: props.title
    },
    axisY: {
      title: props.yLabel
    },
      toolTip: {
        shared: true
    },
    
    legend:{
        fontSize: 18,
        fontFamily: "tamoha",
        fontColor: "Sienna"      
      },
     
    data: dataCreation(props.dataPoints)
        


  }

    return(
      <div>
        <CanvasJSChart options={options} containerProps={undefined} onRef={undefined} />
       
      </div>
    )
}



export default MultipleLineChartDataSet