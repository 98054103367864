

import React, { useState, useEffect, useCallback, useRef, lazy, Suspense } from "react";
import Fallback from "./components/fallback/fallback";
import Login from './pages/login/login'
import Home from "./pages/home/home";
import Profile from './pages/profile/profile'
import EditProfile from './pages/editProfile/editProfile'
import ChangePassword from './pages/changePassword/changePassword'
import UserList from './pages/userList/userList'
import UserEdit from './pages/userEdit/userEdit'
import UserCreate from './pages/createUser/userCreate'
import PolicyList from './pages/policyList/policyList'
import PolicyEdit from './pages/policyEdit/policyEdit'
import RefreshPassword from './pages/login/refreshPassword'
import TalkList from "./pages/talkList/talkList";
import NewTalkList from './pages/newTalkList/newTalkList'
import { Route, Routes } from 'react-router-dom';
import TalkReportsLanguageCount from './pages/talkReports/languageCount/languageCount'
import TalkReportsLanguageCountV2 from './pages/talkReports/languageCountV2/languageCount'
import TalkReportsLanguageDuration from './pages/talkReports/languageDuration/languageDuration'
import TalkReportsCharacterCount from './pages/talkReports/characterCount/characterCount'
import TalkReportsCharacterDuration from "./pages/talkReports/characterDuration/characterDuration";
import TalkAnother from './pages/talkReports/anotherV2/another'
import CountryTalkDetail from "./pages/countryTalkDetail/countryTalkDetail";
import CountryTalkList from "./pages/countryTalkList/countryTalkList";
import DailyTalkList from "./pages/dailyTalkList/dailyTalkList";
import DailyHourList from "./pages/dailyHourList/dailyHourList";
import LanguageTotalList from "./pages/LanguageTotalList/languageTotalList";
import SendTopic from "./pages/sendTopic/sendTopic";


import { PATHES,SESSION_STORAGE_KEYS} from './utils/contants'
import { makeSureOfExistanceOfEntireStateInSessionStorageANDRedux } from './utils/others'

import { useDispatch, useTypedSelector, UserReduxActionTypesT, ConfigsReduxActionTypesT } from './redux/index'

import styles from './app.module.scss'
import AuthRoute from './components/others/authRoute'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CharacterT, LanguageT } from "./utils/models";
import UserSuspension from "./pages/usersuspension/UserSuspension";
import CreateSuspension from "./pages/createSuspension/CreateSuspension";
import TalkStatus from "./pages/talkstatus/TalkStatus";
import TalkSteps from "./pages/talkSteps/TalkSteps";
import FailTalk from "./pages/failTalk/FailTalk";
import NewTalkList2 from "./pages/newTalkList2/newTalkList2";



const { app } = styles


function App(props) {
  const dispatch = useDispatch()
  const {user: userReduxState,configs: configsReduxState} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
 
  //it gets the state from session storage and adds it into redux
  useEffect(() => {
    makeSureOfExistanceOfEntireStateInSessionStorageANDRedux(userReduxState, UserReduxActionTypesT.saveWholeStateFromSessionStorage, SESSION_STORAGE_KEYS.user, dispatch)
    makeSureOfExistanceOfEntireStateInSessionStorageANDRedux(configsReduxState, ConfigsReduxActionTypesT.saveWholeStateFromSessionStorage, SESSION_STORAGE_KEYS.configs, dispatch)
  }, [])

  //we have to show to homePage if user has the token in his redux store
  let userHasTokenToSeeTheHomePage = false
  if (userReduxState.access_token && userReduxState.resources && configsReduxState.allResources?.length > 0) {
    userHasTokenToSeeTheHomePage = true
  }

  return (
    <div className={app}>
      <Suspense fallback={<Fallback />}>
        <Routes>
          {
            //we only want to render the login page if user doesnt have the token
            !userHasTokenToSeeTheHomePage &&
            <>
            <Route path={PATHES.login} element={<Login />} />
            <Route path={PATHES.refreshPassword} element={<RefreshPassword />} />
            <Route path='*' element={<Login />} />
            </>
          }
          {
            userHasTokenToSeeTheHomePage &&
            <>
            <Route path={PATHES.home} element={<AuthRoute key={PATHES.home}><Home /></AuthRoute>} />
            <Route path={PATHES.profile} element={<AuthRoute key={PATHES.profile}><Profile /></AuthRoute>}/>
            <Route path={PATHES.profileEdit} element={<AuthRoute key={PATHES.profileEdit}><EditProfile/></AuthRoute>}/>
            <Route path={PATHES.profileChangePassword} element={<AuthRoute key={PATHES.profileChangePassword}><ChangePassword/></AuthRoute>}/>
            <Route path={PATHES.user} element={<AuthRoute key={PATHES.user}><UserList/></AuthRoute>}/>
            <Route path={PATHES.userEdit} element={<AuthRoute key={PATHES.userEdit}><UserEdit /></AuthRoute>}/> 
            <Route path={PATHES.userCreate} element={<AuthRoute key={PATHES.userCreate}><UserCreate /></AuthRoute>} />
            <Route path={PATHES.access_policy} element={<AuthRoute key={PATHES.access_policy}><PolicyList/></AuthRoute>}/>
            <Route path={PATHES.policyEdit} element={<AuthRoute key={PATHES.policyEdit}><PolicyEdit/></AuthRoute>} />
            {/* <Route path={PATHES.talkList} element={<AuthRoute key={PATHES.talkList}><TalkList/></AuthRoute>} /> */}
            <Route path={PATHES.talkList} element={<AuthRoute key={PATHES.talkList}><NewTalkList/></AuthRoute>} />
            {/* <Route path={PATHES.talkReportsLanguageCount} element={<AuthRoute key={PATHES.talkReportsLanguageCount}><TalkReportsLanguageCount/></AuthRoute>} /> */}
            <Route path={PATHES.talkReportsLanguageCount} element={<AuthRoute key={PATHES.talkReportsLanguageCount}><TalkReportsLanguageCountV2/></AuthRoute>} />
            <Route path={PATHES.talkReportsLanguageDuration} element={<AuthRoute key={PATHES.talkReportsLanguageDuration}><TalkReportsLanguageDuration/></AuthRoute>} />
            
            <Route path={PATHES.talkReportsCharacterCount} element={<AuthRoute key={PATHES.talkReportsCharacterCount}><TalkReportsCharacterCount/></AuthRoute>} />
            <Route path={PATHES.talkReportsCharacterDuration} element={<AuthRoute key={PATHES.talkReportsCharacterDuration}><TalkReportsCharacterDuration/></AuthRoute>} />
            <Route path={PATHES.talkAnother} element={<AuthRoute key={PATHES.talkAnother}><TalkAnother/></AuthRoute>} />
            <Route path={PATHES.languageTotalList} element={<AuthRoute key={PATHES.languageTotalList}><LanguageTotalList/></AuthRoute>} />
            <Route path={PATHES.countryTalkList} element={<AuthRoute key={PATHES.countryTalkList}><CountryTalkList/></AuthRoute>} />
            <Route path={PATHES.countryTalkDetail} element={<AuthRoute key={PATHES.countryTalkDetail}><CountryTalkDetail/></AuthRoute>} />
            <Route path={PATHES.dailyTalkList} element={<AuthRoute key={PATHES.dailyTalkList}><DailyTalkList/></AuthRoute>} />
              <Route path={PATHES.dailyHourList} element={<AuthRoute key={PATHES.dailyHourList}><DailyHourList /></AuthRoute>} />
              <Route path={PATHES.talkListStatus} element={<AuthRoute key={PATHES.talkListStatus}><TalkStatus></TalkStatus></AuthRoute>} />
              <Route path={PATHES.talkSteps} element={<AuthRoute key={PATHES.talkSteps}><TalkSteps /></AuthRoute>} />
              <Route path={PATHES.failTalk} element={<AuthRoute key={PATHES.failTalk}><FailTalk /></AuthRoute>} />
               <Route path={PATHES.newTalkList} element={<AuthRoute key={PATHES.newTalkList}><NewTalkList2/></AuthRoute>} />
              <Route path={PATHES.send_topic} element={<SendTopic key={PATHES.send_topic} />} />
              <Route path={PATHES.user_suspension} element={<UserSuspension key={PATHES.user_suspension} />} />
              <Route path={PATHES.createSuspension} element={<CreateSuspension/>} />
            </>
          }
        </Routes>
        <ToastContainer />
      </Suspense>
    </div>
  );
}

export default App;
