import { useState } from 'react'
import Tabs from "../../tab/tabs"
import styles from './languageCount.module.scss'
import PieChart from '../../charts/pieChart/pieChart'
import BarChart from '../../charts/barChart/barChart'
import LanguageCountTableV2 from '../../tables/languageCountTableV2/languageCountTable'
import LangData from './languageCount.json'
import {TalkReportsLanguageT, CharacterT} from '../../../utils/models'
import { modifierForLangChart, modifier_for_language_duration_charts} from '../../../utils/others'

const { languageDuration, head, body} = styles





type LanguageCountPropsT = {
    data: TalkReportsLanguageT[],
}




const LanguageCount: React.FC<LanguageCountPropsT> = (props) => {
    const [selectedTabId, setSelectedTabId] = useState<number>(0)

    const dataForBarChartCount = modifier_for_language_duration_charts(props.data, 'count',false)
    const dataForPieChartCount = modifier_for_language_duration_charts(props.data, 'count',true)

    const dataForBarChartDuration = modifier_for_language_duration_charts(props.data, 'duration',false)
    const dataForPieChartDuration = modifier_for_language_duration_charts(props.data, 'duration',true)


    return(
        <div className={languageDuration}>
            <div className={head}>
                Languages's Count
            </div>
            <div className={body}>
                <Tabs components={[
                    <LanguageCountTableV2 data={props.data}/>,
                    <>
                        <BarChart title='Call Count' dataPoints={dataForBarChartCount} xLabel='Language' yLabel='Count'/>,
                        <div style={{marginTop: "3em"}}></div>
                        <BarChart title='Call Duration' dataPoints={dataForBarChartDuration} xLabel='Language' yLabel='Duration'/>,
                    </>,
                    <>
                    <PieChart title='Call Count' dataPoints={dataForPieChartCount} xLabel='' yLabel=''/>
                    <div style={{marginTop: "3em"}}></div>
                    <PieChart title='Call Duration' dataPoints={dataForPieChartDuration} xLabel='' yLabel=''/>
                    </>
                    // <PieChart title='Call Count' dataPoints={dataForPieChartCount} xLabel='' yLabel=''/>
                ]} 
                selectedTabId={selectedTabId} 
                data={[
                    {id: 0, title: 'table'},
                    {id: 1, title: 'bar chart'},
                    {id: 2, title: 'pie chart'}
                ]} 
                tabChanger={(tabId) => setSelectedTabId(tabId)}/>
            </div>
        </div>
    )
}


export default LanguageCount