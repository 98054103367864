import React,{useEffect,useState} from 'react'
import Template from '../../components/template/template'
import styles from './home.module.scss'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import DashboardPanel from '../../components/panels/dashboard/dashboard'
import SpinnerB from '../../components/spinner/spinnerB'
const { home, container } = styles



const Home: React.FC = () => {
  const [loading, setLoading] = useState(false)

  return (
    <Template>
      <div className={home}>
        {
          !loading ?
          <MainPanel title="Dashboard" headButtons={[]}>
            <div className={container}>
              <DashboardPanel />
            </div>
          </MainPanel>
          :
          <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
            <SpinnerB/>
          </div>
        }
      </div>
    </Template>
  )
}

export default Home