import Check from '../check'
import styles from './addObjectOptions.module.scss'
import CONFIG from '../../../config.json'
import useDetectClickedInside from '../../customHooks/useDetectClickedInside'
import { useRef, useEffect } from 'react'
import {CharacterT} from '../../../utils/models'

const {optionItem,optionsPanel} = styles

type AddObjectOptionsPropsT = {
    setShowOptions: (a: boolean) => void
    confirmedValues: {name: string, value: string|number}[]
    isMultiValue: boolean
    setValues: (a) => any
    options: {name: string, value: string|number}[]
}


const AddObjectOptions: React.FC<AddObjectOptionsPropsT> = (props) => {
    const panelEl = useRef<HTMLDivElement | null>(null)

    let isInside = useDetectClickedInside(panelEl);
    useEffect(() => {
        if(!isInside){
            props.setShowOptions(false)
        }
    })

    //it detects whether the character is already exists in the confirmedArr, if it does we have to remove it from confirmedArr
    //if it doesnt exist we have to add it
    const add_or_remove_object_from_confirmed_arr = (id:number|string) => {
        props.setValues((prev: {name: string, value: string|number}[]) => {
            let returned_characters = []
            const exists_in_confirmed_arr = props.confirmedValues.find(obj => obj.value.toString() === id.toString())
            if(exists_in_confirmed_arr){
                return [...prev.filter(obj => obj.value !== id)]
            }
            if(props.isMultiValue){
                return [...prev,props.options.find(obj => obj.value.toString() === id.toString())]
            }
            return [props.options.find(obj => obj.value.toString() === id.toString())]
        })
    }

    const items = props.options.map((op,index) => {
        const isChecked = props.confirmedValues.find(c => c.value.toString() === op.value.toString()) ? true : false
        let imageElement = null
        //if the option is not a default option(ex: all characters), we have to get the image from server
        if(op.value !== 0){
            imageElement = <img src={`${CONFIG.API_URL_2}/static/characters/${op.value}/avatar.png`} style={{maxWidth: '1.5em'}}/>
        }
        return (
        <div className={optionItem} key={`${index}-dsf`}>
            <Check id={op.value.toString()} value={isChecked} onChangeHandler={() => {add_or_remove_object_from_confirmed_arr(op.value)}} children={op.name}/>
        </div>
        )
    })
    return(
        <div className={optionsPanel} ref={panelEl}>
            {
            items
            }
        </div>
    )
}


export default AddObjectOptions