import CanvasJSReact,{CanvasJSOptionsT} from '../../../0-0/canvasJS/canvasjs.react'
import './lineChart.module.scss'



type PieChartPropsT = {
  title: string,
  xLabel: string,
  yLabel: string,
  dataPoints: { label: string,  value: number  }[]
}


const PieChart: React.FC<PieChartPropsT> = (props) => {
  const CanvasJSChart = CanvasJSReact.CanvasJSChart  
   
  const options: CanvasJSOptionsT = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"
    title: {
      text: props?.title
    },
    axisY: {
      title: props?.yLabel
    },
    
    data: [{      
      markerType: "circle",
          type: 'line',
      // indexLabel: " {y}",
       color:`green`,
      startAngle: -90,
      dataPoints: props.dataPoints?.map(dp => ({ label: dp.label, y: dp.value })),
      
      }             
      ]
        


  }

    return(
      <div>
        <CanvasJSChart options={options} containerProps={undefined} onRef={undefined} />
       
      </div>
    )
}



export default PieChart