import styles from './usersuspension.module.scss'
import React,{useEffect, useState} from 'react'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import {ButtonAProps} from '../../components/buttons/buttonA'
import Template from '../../components/template/template'
import {useTypedSelector} from '../../redux/index'
import { useNavigate } from 'react-router-dom'
import {PATHES} from '../../utils/contants'

import SpinnerB from '../../components/spinner/spinnerB'
import {RoleFC_creator,UserStatusFC_creator} from '../../utils/formControl'
import {SelectContent} from '../../components/formControl/formControl'
import UserSuspensionListTable from '../../components/tables/userSuspensionListTable/UserSuspensionListTable'


const {userList, container,  } = styles

const UserSuspension:React.FC = props => {
  const [statusFC, setStatusFC] = useState<SelectContent | null>(null)
  const [roleFC, setRoleFC] = useState<SelectContent | null>(null)
  const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const navigate = useNavigate()

  //we dont want to allow user to change user's policies if he is not allowed
  const user_is_permitted_to_create_user = userRedux.resources.find(reso => reso.value === 2)?.actions.find(act => act.value === 2) ? true : false


  let btnPropsArr: ButtonAProps[] = []
  if(user_is_permitted_to_create_user){
    console.log('user_is_permitted_to_create_user  ====>',user_is_permitted_to_create_user)
    const editBtnProps: ButtonAProps = {
      type: 'primary',
      children: 'Suspend New User',
      onClick: () => {navigate({pathname: PATHES.createSuspension})}
    }
    btnPropsArr.push(editBtnProps)
  }



  //configs based on roles and status comes from redux for dropdown
  useEffect(() => {
    if(configRedux.allRoles.length > 0){
      const roleDropdownConfigs = RoleFC_creator(configRedux.allRoles)
      setRoleFC(roleDropdownConfigs)
    }
    if(configRedux.allStatuses.length > 0){
      const statusDropdownConfigs = UserStatusFC_creator(configRedux.allStatuses)
      setStatusFC(statusDropdownConfigs)
    }
  },[configRedux])

  //to find out whether we can show the edit button to the user based on his permissions
  const user_is_permitted_to_edit_users = userRedux.resources.find(reso => reso.value === 2)?.actions.find(act => act.value === 4) ? true : false
  return (
    <Template>
      <div className={userList}>
        <MainPanel title='Users Suspension' headButtons={btnPropsArr}>
          <div className={container} style={{width: '100%'} as React.CSSProperties}>
            {
            statusFC && roleFC && configRedux && configRedux.allRoles.length > 0  ?
            <UserSuspensionListTable roleFC={roleFC} statusFC={statusFC} allRoles={configRedux.allRoles} allStatuses={configRedux.allStatuses} access_token={userRedux.access_token} refresh_token={userRedux.refresh_token} user_is_permitted_to_edit_users={user_is_permitted_to_edit_users} logged_in_user_email={userRedux.email}/>
            :
            <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
              <SpinnerB/>
            </div>
            }
          </div>
        </MainPanel>
      </div>
    </Template>
    
    )
}

export default UserSuspension

