import { FailTalkResT } from '../../../utils/requests'
import ButtonA from '../../buttons/buttonA'
import styles from './failtalktable.module.scss'
import { CSVLink} from 'react-csv'
const { dailyAccountReportTable, headRow, bodyRow, noRecord, pair, single,exportBtn} = styles
type props = {
  failTalkData:any
}
const FailTalkTable: React.FC<props> = ({ failTalkData }) => {
  // console.log('table',failTalkData);
  
     let rows = []
   let exportable_data: {
        index: number,
  id: number,
  p1_media: string,
  p2_media: string,
  notStartedCount: number,
  notStartedPercent: number,
  inProgressPercent: number
  inProgressCount: number,
  finishedCount: number,
  finishedPercent: number,
  exitedCount: number,
  exitedPercent: number,
  total:number
  
    //  divide_to_failed_count:number
    //  divide_to_total_count:number

  }[] = []
     let exportable_headers = [
        {key: 'index', label: 'index'},
        {key: 'status', label: 'status'},
        {key: 'p1_media', label: 'media A'},
        {key: 'p2_media', label: 'media B'},
       { key: 'exitedCount', label: 'exitedCount' },
        {key: 'exitedPercent', label: 'exited Percent'},
       { key: 'finishedCount', label: 'finishedCount' },   
        {key: 'finishedPercent', label: 'finished percent' }, 
       { key: 'inProgressCount', label: 'inProgressCount' },
       {key: 'inProgressPercent', label: 'inProgress percent'},
       { key: 'notStartedCount', label: 'notStartedCount' }, 
       { key: 'notStartedPercent', label: 'notStarted Percent' }, 
          {key: 'total', label: 'total' }, 
  ]
                failTalkData?.forEach((record,index) => {
      const { p1_media,p2_media,id,exitedCount,exitedPercent,finishedCount,finishedPercent,inProgressCount,inProgressPercent,notStartedCount,notStartedPercent,total} = record
    exportable_data.push({
        index: index+1,
      id: id,
      p1_media: p1_media,
      p2_media: p2_media,
      exitedCount: exitedCount,
      exitedPercent:exitedPercent,
      finishedCount: finishedCount,
      finishedPercent:finishedPercent,
      inProgressCount: inProgressCount,
      inProgressPercent:inProgressPercent,
      notStartedCount: notStartedCount,
      notStartedPercent: notStartedPercent,
      total:total
    })
    })
   if(failTalkData){
        rows = failTalkData?.map((record, index) => {
            
          const {  p1_media, p2_media, exitedCount, exitedPercent, finishedCount, finishedPercent, inProgressCount, inProgressPercent, notStartedCount, notStartedPercent,total } = record      
          return (              
                <tr className={[bodyRow].join(' ')} key={`${index}-sdas`} >
                    <td>{index+1}</td>
                    <td>{p1_media}</td>
                    <td>{p2_media}</td>
                    <td>{total}</td>
                    <td>{exitedCount}</td>
                    <td>{`${Number(exitedPercent).toFixed(2)} %`}</td>
                    <td>{finishedCount}</td> 
                    <td>{ `${Number(finishedPercent).toFixed(2)} %`}</td>
                    <td>{inProgressCount}</td>
                    <td>{` ${Number(inProgressPercent).toFixed(2)} %`}</td>
                    <td>{notStartedCount}</td>
                    <td>{`${Number(notStartedPercent).toFixed(2)} %`}</td>
                
              </tr>              
            ) })
    }
  return (
    <>
            <div className={exportBtn}>
                <CSVLink data={exportable_data} headers={exportable_headers} filename={"test.csv"} >
                    <ButtonA children={`Export Excel`} onClick={() => {}} type="secondary"/>
                </CSVLink>
            </div>
            <table className={dailyAccountReportTable}>
                <thead>
                    <tr className={headRow}>
                     <td style={{ minWidth: '2em' } as React.CSSProperties}>index</td>                              
                    <td>Media A</td>
                    <td>Media B</td> 
                    <td>Total</td>  
                    <td>exited Count</td>
                    <td>exited Percent </td>            
                    <td>finished Count</td>    
                    <td>finished Percent</td>   
                    <td>in Progress Count</td>
                    <td>in Progress percent</td>
                    <td>not Started Count</td> 
                   <td>not Started percent</td> 
             </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={8}>there is no record</td></tr>
                    }
                </tbody>
            </table>
        </>
  )
}
export default FailTalkTable