import Template from '../../../components/template/template'
import MainPanel from '../../../components/panels/mainPanel/mainPanel'
import React, { useState, useEffect } from 'react'
import styles from './another.module.scss'
import { useTypedSelector, useDispatch } from '../../../redux'
import {SelectContent} from '../../../components/formControl/formControl'
import {langFC_creator} from '../../../utils/formControl'
import { useNavigate } from 'react-router-dom'
import FilterD from '../../../components/filters/filterD'
import {reconstruct_talk_another_data_v2} from '../../../utils/others'
import {GetTalkAnotherResT, GetTalkAnother} from '../../../utils/requests'
import {toast} from 'react-toastify'
import { characterFC_creator, RefreshTimeFC } from '../../../utils/formControl'
import TalkAnotherTable from '../../../components/tables/talkAnother/talkAnother'

const {talkAnother, container, body, filterSpot, tableSpot, tableTitle, tableSpace, noRecord} = styles

const TalkAnother = () => {
    
  let intervalIds = []
  let defaultRefreshTime = 15
  let hasRecord = false
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [talks, setTalks] = useState<GetTalkAnotherResT | null>(null)

  const [langFC, setLangFC] = useState<SelectContent | null>(null)
  const [charFC, setCharFC] = useState<SelectContent | null>(null)
  const [refreshTimeV, setRefreshTimeV] = useState(defaultRefreshTime.toString())
  const [refreshTimeIsValid, setRefreshTimeIsValid] = useState(true)

 

  const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const [loading, setLoading] = useState(false)


  console.log('langFC',langFC);
  


  useEffect(() => {
    const fetchData = async (access_token: string) => {
      if(loading){
        // console.log(loading)
        return
      }
      
      try {
        // console.log('before getting')
        setLoading(true)
        const res = await GetTalkAnother({token: access_token},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
        // console.log('after getting')
    
        if(res.status === 200){
            const resData = res.data
            if(resData){
                setTalks(resData)
            }
            else {
                setTalks(null)
            }
        }
      }
      catch(err){
        // console.log('error --->',err)
      }
      setLoading(false)
      return
    }
    let refreshTime = +refreshTimeV
    //if updated refresh time is not number
    if(refreshTimeV === "" || Number.isNaN(+refreshTimeV)){
      // refreshTime = defaultRefreshTime
      
      // console.log('input is not number')
      return
    }
    // console.log('is number')
    // fetchData('').finally(() => {
        const intId = setInterval(async () => {
            await fetchData('')
            // console.log('intervalExecution')
        }, refreshTime*1000)
        // console.log('intervalId is registered --->', intId)
        intervalIds.push(intId)
      // console.log(`new setInterval was set with value:${refreshTime} seconds`)
  // })
    return () => {
      // console.log('cleared --->',intervalIds)
      setLoading(false)
      for(const intervalId of intervalIds){
        // console.log('intervalId is cleared --->',intervalId)
         clearInterval(intervalId)
      } 
      intervalIds = []
      // console.log('intervalIds must be [] --->',intervalIds)
    
    }
  }
  ,[refreshTimeV])



  


  //we have to 

  //configs based on roles and status comes from redux for dropdown
  useEffect(() => {
    if(configRedux.allLanguages.length > 0){
      setLangFC(langFC_creator(configRedux.allLanguages))
    }
    if(configRedux.allCharacters.length > 0){
      const charFC = characterFC_creator(configRedux.allCharacters)
      setCharFC(charFC)
    }
 
  },[configRedux])



  let tables = []
  if(talks){
    tables = talks.languages.map((lang,index) => {
     let title='' || undefined
      
      const req_arr_of_language = reconstruct_talk_another_data_v2(lang)
      if(req_arr_of_language?.length > 0){
        hasRecord = true
      }
     
      if (Array.isArray(langFC.options) && langFC) {
    const option = langFC.options.find(op => op.value === lang.language_id);
    if (option) {
         title = option.label;
        // Now you can use the title
    } else {
      if (lang.language_id===504) {
         title ='PAKISTAN';
      }
      
    }
} else {
    console.error("langFC.options is not an array or langFC is not defined.");
}
      return (
        <React.Fragment  key={`${lang.language_id}-42sad-${index}`}>
          {
            req_arr_of_language && langFC &&
            <div className={tableSpot}>
                <div className={tableTitle}>{ title }</div>
              <div className={tableSpace}>
                <TalkAnotherTable data={req_arr_of_language} />
              </div>
            </div>
          }
        </React.Fragment>
      )
    })

  }

  




  





  
 








  return(
    <Template>
      <div className={talkAnother}>
      
        <MainPanel title="Talk another" headButtons={[]}>
          <div className={container} style={{width: '100%'} as React.CSSProperties}>
            <div className={filterSpot}>
                <FilterD 
                  hasNotButton={true}
                  confirmHandler={() => {}} loading={loading}
                  refreshTimeFC={RefreshTimeFC} refreshTimeValue={refreshTimeV}
                  setRefreshTimeValue={setRefreshTimeV} refreshTimeIsValid={refreshTimeIsValid}
                  setRefreshTimeIsValid={setRefreshTimeIsValid}
                  />
            </div>
            <div className={body}>
              {tables} 
              {
              !hasRecord &&
                <div className={noRecord}>
                  there is no record
                </div>
              }            
            </div>
          </div>
        </MainPanel>
        
      </div>
  </Template>  
  )
}




export default TalkAnother