import styles from './talkstatus.module.scss'
import MainPanel from "../../components/panels/mainPanel/mainPanel"
import Template from "../../components/template/template"
import Filter from '../../components/filters/filterh'
import { changeHourOfISODate, historyPropertiesExistanceCheck } from '../../utils/utility'
import { useEffect, useState } from 'react'
import { accountIdFC, platformFC_creator, talkTypeFC_creator } from '../../utils/formControl'
import Tabs from '../../components/tab/tabs'
import TalkStatusTable from '../../components/tables/talkStatusTable/TalkStatusTable'
import { GetTalkStatus, TalkListStatusFilterT, TalkStatusResT, talkStatusReportT } from '../../utils/requests'
import { prepare_data_for_talk_MultilineChartDataset_TalkStatus_chart1, prepare_data_for_talk_MultilineChartDataset_TalkStatus_chart2, reorder_date_fields } from '../../utils/others'
import { useDispatch, useTypedSelector } from '../../redux'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { justNumberInputChanger } from '../../utils/validator'
import MultipleLineChartDataSet from '../../components/charts/LineChart/MultipleLineChartDataSet'
import { SelectContent } from '../../components/formControl/formControl'
import { PLATFORMS_ARR } from '../../utils/contants'
const { container,filterSpot,body} = styles
const TalkStatus: React.FC = () => {
 
  
   const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
    const dispatch = useDispatch()
  const navigate = useNavigate()
   const today = new Date()
  const yesterday = new Date(today.getTime() - 1 *24*60*60*1000)
  const filteredDates = historyPropertiesExistanceCheck(location, 'filteredDates', null) as { from: Date | null, to: Date | null } | null
   const [loading,setLoading] = useState(false)
    const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({
    //if user is comming back from the countryTalkDetail page we want to show those filtered dates
    from: filteredDates && filteredDates.from ? filteredDates.from : today,
    to: filteredDates && filteredDates.to ? filteredDates.to : today
    })
  const [perTimeV, setPerTimeV] = useState('5')
  const [fromHourV, setFromHourV] = useState('0')
  const [toHourV, setToHourV] = useState('23')
  const [mediaServerAV, setMediaServerAV] = useState('')
  const [mediaServerBV, setMediaServerBV] = useState('')
  const [selectedTabId, setSelectedTabId] = useState(0)
  const [confirmedFilterPhrase, setConfirmedFilterPhrase] = useState<TalkListStatusFilterT | null>({
      from: changeHourOfISODate(createdAt.from, Number(fromHourV), 0, 0),
      to: changeHourOfISODate(createdAt.to, Number(toHourV), 0, 0)
  })
  const [talkStatusReport, setTalkStatusReport] = useState<talkStatusReportT[]>([])
  const [platformFC, setPlatformFC] = useState<SelectContent | null>(null)
  const [platformVA, setPlatformVA] = useState(0)
  const [platformVB, setPlatformVB] = useState(0)
  const [talkTypeFC, setTalkTypeFC] = useState<SelectContent | null>(null)
  const [talkTypeV, setTypeTalkV] = useState(-1)
  // console.log('schema in talk status',talkStatusReport);
  const perTimeChanger = justNumberInputChanger(setPerTimeV)
  const fromHourChanger = justNumberInputChanger(setFromHourV)
   const toHourChanger = justNumberInputChanger(setToHourV)
  const platformChangerA = (e: any, value: number, label: string, object: object) => {
    setPlatformVA(value)
  }
  const platformChangerB = (e: any, value: number, label: string, object: object) => {
    setPlatformVB(value)
  }
  const talkTypeChanger = (e: any, value: number, label: string, object: object) => {
        setTypeTalkV(value)
  }
  //configs based on roles and status comes from redux for dropdown
  useEffect(() => {    
    setPlatformFC(platformFC_creator(PLATFORMS_ARR))
      setTalkTypeFC(talkTypeFC_creator(configRedux.talkType) )

 
  }, [configRedux])

  const fetchData = async (access_token: string, filterPhrase: TalkListStatusFilterT) => {
  try {
      setLoading(true)
      const res = await GetTalkStatus({token: access_token, filters: filterPhrase},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
      setLoading(false)
      if(res.status === 200){
        const resData = res.data as TalkStatusResT
             
          if(resData){
            setTalkStatusReport(resData)
          }
          else {
            setTalkStatusReport([])
          }
      }
  }
  catch(err){
      setLoading(false)
  }
}
  const confirmHandler = async() => {
  if(loading){
    return
    }
       try {
   //first we have to populate input values if exists any
    const filteredPhrase: TalkListStatusFilterT = { 
    
    }
    let from: Date | null = null
    let to: Date | null = null
    //if createdAt is selected
    if(createdAt && createdAt.from){
      from = createdAt.from as Date
      filteredPhrase.from = changeHourOfISODate(from, Number(fromHourV), 0, 0)
    }
    if(createdAt && createdAt.to){
      to = createdAt.to as Date
      filteredPhrase.to = changeHourOfISODate(to, Number(toHourV), 59, 59)
    }
    if(perTimeV.trim().length !== 0){
            filteredPhrase.per = perTimeV.trim()
    }
    if(mediaServerAV.trim().length !== 0){
            filteredPhrase.p1_media = mediaServerAV.trim()
    }
    if(mediaServerBV.trim().length !== 0){
            filteredPhrase.p2_media = mediaServerBV.trim()
    }
     if(platformVA !== 0){
            filteredPhrase.p1_platform= platformVA
    }
    if(platformVB !== 0){
            filteredPhrase.p2_platform= platformVB
    }
  
    if(talkTypeV !== -1){
            filteredPhrase.talk_type = talkTypeV
    }
    //then we have to correct the order of from and to 
    reorder_date_fields(createdAt, filteredPhrase)
    setConfirmedFilterPhrase(filteredPhrase)
    await fetchData(userRedux.access_token, filteredPhrase)

  }
  catch(err){

  }
  }
  let reconstructed_data_for_percents_chart = []
  let reconstructed_data_for_counts_chart = []
  reconstructed_data_for_percents_chart = prepare_data_for_talk_MultilineChartDataset_TalkStatus_chart1(talkStatusReport)
  reconstructed_data_for_counts_chart=prepare_data_for_talk_MultilineChartDataset_TalkStatus_chart2(talkStatusReport)
  return (
    <Template>
      <>
        <MainPanel title='Talks Status' headButtons={[]}>
          <div className={container} style={{ width: '100%' } as React.CSSProperties}>
             <div className={filterSpot}>
              <Filter confirmHandler={confirmHandler} perContent={accountIdFC} perTimeValue={perTimeV} setPerValue={perTimeChanger} createdAt={createdAt} setCreatedAt={setCreatedAt} fromHourValue={fromHourV} setFromHourValue={fromHourChanger} fromHourContent={accountIdFC} toHourValue={toHourV} setToHourValue={toHourChanger} toHourContent={accountIdFC} mediaServerAContent={accountIdFC} mediaServerAValue={ mediaServerAV} setMediaServerA={setMediaServerAV}   mediaServerBContent={accountIdFC} mediaServerBValue={ mediaServerBV} setMediaServerB={setMediaServerBV} platformFC={platformFC} platformAValue={platformVA} setPlatformAValue={platformChangerA}  platformBValue={platformVB} setPlatformBValue={platformChangerB} talkTypeFC={talkTypeFC} talkTypeValue={talkTypeV} setTalkTypeValue={talkTypeChanger} loading={loading}/>
            </div>
             <div className={body}>
              <Tabs
                components={[
                  <TalkStatusTable talkStatusData={talkStatusReport} />   ,
                  <MultipleLineChartDataSet title='Percents Chart ' dataPoints={reconstructed_data_for_percents_chart} xLabel='' yLabel='' />,
                    <MultipleLineChartDataSet title='counts Chart ' dataPoints={reconstructed_data_for_counts_chart} xLabel='' yLabel='' />
                ]}
                data={[
                  {id: 0, title: "Table"},
                  { id: 1, title: "Percents Chart" },
                  {id: 2, title: "count Chart"}
                 
                ]}
                selectedTabId={selectedTabId}
                tabChanger={(tabId) => setSelectedTabId(tabId)}
              />
            </div>
          </div>
        </MainPanel>
      </>
      
   </Template>
  )
}
export default TalkStatus