import styles from './dailyHourReportTable.module.scss'
import {TalkReportsLanguageT} from '../../../utils/models'
import { AccountReportT, DailyHoursReportT, DailyReportT } from '../../../utils/requests'
import { useEffect, useState } from 'react'
const { dailyHourReportTable, headRow, bodyRow, noRecord, pair, single} = styles



type DailyHourReportTablePropsT = {
    data: any
  // hourClickHandler: (record: HourReportT) => void
  hourClickHandler: (record: any) => void
}



const DailyHourReportTable: React.FC<DailyHourReportTablePropsT> = props => {
 const [hoursData, setHoursData] = useState<any>([])
  // console.log('data', props.data);
 
  
    let rows = []
    if(props.data ){
        rows = props.data.map((record, index) => {
            const { exited_talk, failed_talk, hour, successful_talk, total_talk,calling_talk,cancel_ring_talk,cancel_talk,reject_talk,missed_talk,wait_talk} = record
            const none_exited_talks = failed_talk + successful_talk
            const failedOverNoneExitedTalks = none_exited_talks > 0 ? Math.round((failed_talk / none_exited_talks)*100) : 0
            const failedOverTotalTalks = total_talk > 0 ? Math.round((failed_talk / total_talk)*100) : 0
            
            return (
                <tr className={[bodyRow].join(' ')} key={`${index}-sdas`} onClick={() => props.hourClickHandler(record)}>
                    {/* <td>{new Date(props.data.date).toLocaleDateString('en')}</td> */}
                    <td>{hour}</td>
                    <td>{total_talk}</td>
                    <td>{successful_talk}</td>
                    <td>{failed_talk}</td>
                    <td>{exited_talk}</td>
                    <td>{calling_talk}</td>
                    <td>{cancel_ring_talk}</td>
                    <td>{missed_talk}</td>
                    <td>{reject_talk}</td>
                    <td>{cancel_talk}</td>
                    <td>{ wait_talk}</td>
                    <td>{failedOverNoneExitedTalks}%</td>
                    <td>{failedOverTotalTalks}%</td>
                </tr>
            )

        })
    }

    return(
        <>
             <table className={dailyHourReportTable}>
                <thead>
                    <tr className={headRow}>
                      
              {/* <td>Date</td> */}
                        
                        <td>Hour</td>
                        <td>Total</td>
                        <td>Success</td>
                        <td>Failed</td>
                        <td>Exited</td>
                        <td>calling talk</td> 
                        <td>cancel ring talk</td>
                        <td>Missed Call</td>
                        <td>reject talk</td> 
                        <td>cancel talk</td>
                        <td>Wait Call</td>
                        <td>Failed/(Success+Failed)</td>
                        <td>Failed/Total</td>
                    </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={8}>there is no record</td></tr>
                    }
                </tbody>
            </table> 
        </>
    )
}


export default DailyHourReportTable