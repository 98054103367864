import { useEffect, useState } from 'react';
import styles from './dashboardgraph.module.scss'
import { DailyReportT, GetDailyTalkList, GetDailyTalkListFilterT, GetDailyTalkListResT } from '../../utils/requests';
import { changeHourOfISODate, historyPropertiesExistanceCheck } from '../../utils/utility';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useTypedSelector } from '../../redux';
import {toast} from 'react-toastify'
import { prepare_data_for_talk_newAccount_dailyTalks } from '../../utils/others';
import MultipleLineChartDataSet from '../charts/LineChart/MultipleLineChartDataSet';

const { container}=styles

const DashboardGraph: React.FC = () => {
   const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
    const today = new Date()
  const yesterday = new Date(today.getTime() - 1 *24*60*60*1000)
  //in case of traversing from the countryTalkDetail page
  const location = useLocation()
  const [loading, setLoading] = useState(false)
   const filteredDates = historyPropertiesExistanceCheck(location,'filteredDates',null) as {from: Date | null, to: Date | null} | null
   const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({
    //if user is comming back from the countryTalkDetail page we want to show those filtered dates
    from: filteredDates && filteredDates.from ? filteredDates.from : yesterday,
    to: filteredDates && filteredDates.to ? filteredDates.to : today
   })
    const [dailyTalkReport, setDailyTalkReport] = useState<DailyReportT[]>([])
  const dispatch = useDispatch()
    const navigate = useNavigate()
   useEffect(() => {
     const fetchData = async (access_token: string) => {
       const date = new Date ();
    const hours = date.getHours();
    const minutes = date.getMinutes() 
        try {
            setLoading(true)
            const filters: GetDailyTalkListFilterT = {
              from: changeHourOfISODate(createdAt.from, 0, 0, 0),
              to: changeHourOfISODate(createdAt.to, 0, 0, 0) 
            }
            const res = await GetDailyTalkList({token: access_token, filters},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
            setLoading(false)
            if(res.status === 200){
              const resData = res.data as GetDailyTalkListResT
              // console.log('schema',resData.report);               
              if (resData) {
                  
                  setDailyTalkReport(resData)
                }
                else {
                  setDailyTalkReport([])
                }
            }
        }
        catch(err){
            setLoading(false)
        }
    }
    if(userRedux.access_token){
        fetchData(userRedux.access_token)
        // setFirstRender(false)
    }
   }, [userRedux.access_token])
  let reconstructed_data_for_newAccount_dailyTalk_lineChart = []
  if (dailyTalkReport.length > 0) { 
     reconstructed_data_for_newAccount_dailyTalk_lineChart= prepare_data_for_talk_newAccount_dailyTalks(dailyTalkReport)
  }
  return (
    <div className={ container}>
       <MultipleLineChartDataSet title='New Account Vs Daily Talks' dataPoints={reconstructed_data_for_newAccount_dailyTalk_lineChart} xLabel='' yLabel=''/>,
    </div>
  );
};

export default DashboardGraph;
