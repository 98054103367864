import React, {useState} from 'react'
import styles from './addCharsFormControl.module.scss'
import AddCharsOptions from './addCharsOptions'
import {CharacterT} from '../../../utils/models'

const {addCharsFormControl, focused,main,main_text,main_icon,options_spot} = styles 

type AddCharsFormControlPropsT = {
    values: CharacterT[]
    setValues: (a) => any
    name: string
    isMultiValue: boolean
    options: CharacterT[]
}


const AddCharsFormControl:React.FC<AddCharsFormControlPropsT> = props => {
    const [showOptions, setShowOptions] = useState(false)
 


    return (
        <div className={[addCharsFormControl, showOptions ? focused : ''].join(' ')}>
           <div className={main} onClick={() => setShowOptions(true)}>
            <div className={main_text}>
                {
                //we have to show the length of the selected items 
                // <><span>{props.values.length} {props.name}</span> is selected</>
                <><span>{props.values.length} char</span></>
                

                }
            </div>
            <div className={main_icon}></div>
           </div>
           {
            showOptions &&


            <div className={options_spot}>
                <AddCharsOptions confirmedValues={props.values} setValues={props.setValues} options={props.options} setShowOptions={setShowOptions} isMultiValue={props.isMultiValue}/>
            </div>
           }
        </div>
    )
}

export default AddCharsFormControl






// how to use

// const [userV,setUserV] = useState('')
// const [userIsValid,setUserIsValid] = useState(true)
// const [usersArrV,setUsersArrV] = useState([])
// const [usersArrIsValid,setUsersArrIsValid] = useState(true)

// const userAddHandler = () => {
//     //some validation code
//     setUsersArrV(prev => [...prev,userV])
  
// }


// const usersArrRemoveHandler = (user) => {
//     setUsersArrV(prev => [...prev.filter(c => c !== user)])
// }




{/* <AddItemFormControl btnTitle="تایید" addHandler={userAddHandler} confirmedArr={usersArrV} removeHandler={usersArrRemoveHandler} isValid={usersArrIsValid}>
    <FormControl content={CouponRestrictedUsers} value={userV} setParentValue={setUserV} isValid={userIsValid} setIsValid={setUserIsValid}  errorMsg={null} />
</AddItemFormControl> */}




//when is readOnly
{/* 
<AddItemFormControl btnTitle="تایید" readOnly={true} addHandler={() => {}} confirmedArr={[]} removeHandler={() => {}} isValid={true}>
    <div className={usersFc} style={{border: '1px solid #c9a220',borderRadius: '4px',height: '3em',padding: '0 1em',display: 'flex',justifyContent: 'flex-start',alignItems: 'center'}}>
        <div className={label}>کاربرهای مجاز</div>
    </div>
</AddItemFormControl> */}