import styles from './createSuspension.module.scss'
import React,{useState} from 'react'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import {ButtonAProps} from '../../components/buttons/buttonA'
import Template from '../../components/template/template'
import {useTypedSelector, useDispatch} from '../../redux/index'
import {toast} from 'react-toastify'
import { adminCreateNewUserSuspension, AdminCreateNewUserSuspensionReqBodyT} from '../../utils/requests'
import { useNavigate } from 'react-router-dom'
import { PATHES} from '../../utils/contants'
import {Langs, validator,ValidatorTypes} from '../../utils/validator'
import FormControl from '../../components/formControl/formControl'
import {firstNameFC,userNameFC} from '../../utils/formControl'

const {userCreate, container, item, itemLabel, itemInput, firstName, lastName, userName, phoneNumber, email, password, confirmPassword, role } = styles

const CreateSuspension:React.FC = props => {
    const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [accountIdV, setAccountIdV] = useState(0)
    const [accountIdIsValid, setAccountIdIsValid] = useState(true)

    const [durationV, setDurationV] = useState(0)
    const [durationIsValid, setDurationIsValid] = useState(true)

    const [loading, setLoading] = useState(false)
    const [errorsObj, setErrorsObj] = useState({accountId: null, duration: null})



    const submitHandler = async () => {
      if(loading){
        //we dont want to send another req if we have sent a req already and we havent get its response yet
        return
      }
      //here we can validate user inputs  ===> email, password, role   are essetials
      //but we force user to enter userName, firstName, lastName, phoneNumber as well from the client-side
      const populatedErrors = {accountId: null, duration: null}
      let hasError = false
      
      const accountIDValidationResult = validator({type: ValidatorTypes.accountID, value: accountIdV})
       const durationValidationResult = validator({type: ValidatorTypes.duration, value: durationV})
    
      
      
            
      if(!accountIDValidationResult.isValid){
        setAccountIdIsValid(false)
        populatedErrors.accountId = accountIDValidationResult.errs[0]
        hasError = true
      }
      
      
      if(!durationValidationResult.isValid){
        setDurationIsValid(false)
        populatedErrors.duration = durationValidationResult.errs[0]
        hasError = true
      }
      //if any error occurs, we dont want to continue and send req
      if(hasError){
        return setErrorsObj(populatedErrors)
      }

      try {
        const reqBody: AdminCreateNewUserSuspensionReqBodyT = {
          account_id: Number(accountIdV) ,
          duration_in_hour: Number(durationV) ,
          type:1
         
        }
        setLoading(true)
        const res = await adminCreateNewUserSuspension(userRedux.access_token, reqBody, {dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
        setLoading(false)  //after recieving res, we have to set loading to false
        if(res.status === 200){
          navigate({pathname: PATHES.user_suspension})
          return toast.success('user was suspended successfully')
        }
      }
      catch(err){
        setLoading(false) 
      }

    }


    const confirmBtnProps: ButtonAProps = {
        type: 'primary',
        children: 'Confirm',
        onClick: submitHandler,
        loading: loading
    }
    const previousPageBtn: ButtonAProps = {
      type: 'terthiary',
      children: 'Cancel ',
      onClick: () => {navigate({pathname: PATHES.user_suspension})}
    }




    return (
      <Template>
        <div className={userCreate}>
           <MainPanel title='Suspension Form' headButtons={[previousPageBtn,confirmBtnProps]}>
              <form className={container} style={{width: '100%'} as React.CSSProperties}>
                <input type={'text'} style={{display: 'none'} as React.CSSProperties}/>
                    <div className={[item,firstName].join(' ')}>
                      <div className={itemLabel}>Account ID</div>
                      <div className={itemInput}>
                        <FormControl content={firstNameFC} value={accountIdV} setParentValue={setAccountIdV} isValid={accountIdIsValid} setIsValid={setAccountIdIsValid} errorMsg={errorsObj.accountId}/>
                      </div>
                    </div>
                      <div className={[item,userName].join(' ')}>
                      <div className={itemLabel}>Suspension Duration (Hours)</div>
                      <div className={itemInput}>
                        <FormControl content={userNameFC} value={durationV} setParentValue={setDurationV} isValid={durationIsValid} setIsValid={setAccountIdIsValid} errorMsg={errorsObj.duration}/>
                      </div>
                    </div>
                   
                </form>
           </MainPanel>
        </div>
      </Template>
    )
}

export default CreateSuspension

