import Template from '../../components/template/template'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import { useState, useEffect } from 'react'
import SpinnerB from '../../components/spinner/spinnerB'
import CountryTalkListTable from '../../components/tables/countryTalkListTable/countryTalkListTable'
import styles from './countryTalkList.module.scss'
import { useTypedSelector, useDispatch } from '../../redux'
import {SelectContent} from '../../components/formControl/formControl'
import { CountryTalkWithPalCountriesT} from '../../utils/models'
import {countryTalkSortFC_creator} from '../../utils/formControl'
import { GetCountryTalkList, GetCountryTalkListFilterT, GetCountryTalkListResT} from '../../utils/requests'
import { useNavigate, useLocation } from 'react-router-dom'
import {toast} from 'react-toastify'
import { changeHourOfISODate, historyPropertiesExistanceCheck } from '../../utils/utility'
import { DesiredFieldX123T, reconstruct_country_talk_data_for_graph_v_1, reconstruct_country_talk_data_for_graph_v_2, reconstruct_country_talk_data_for_graph_v_3, reconstruct_country_talk_data_for_graph_v_4, reconstruct_country_talk_data_for_graph_v_5, reorder_country_talks, reorder_date_fields } from '../../utils/others'
import Filter from '../../components/filters/filterE'
import Tabs from '../../components/tab/tabs'
import StackedAreaChart from '../../components/charts/stackedAreaChart/stackedAreaChart'
const {countryTalkListC, container, filterSpot, body} = styles

const CountryTalkList = () => {
  const today = new Date()
  const yesterday = new Date(today.getTime() - 1 *24*60*60*1000)
  //in case of traversing from the countryTalkDetail page
  const location = useLocation()
  const filteredDates = historyPropertiesExistanceCheck(location,'filteredDates',null) as {from: Date | null, to: Date | null} | null
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [firstRender, setFirstRender] = useState(true)
  const [countryTalks,setCountryTalks] = useState<GetCountryTalkListResT | null>(null)
  const [countryTalkList,setCountryTalkList] = useState<CountryTalkWithPalCountriesT[]>([])
  const [sortFC, setSortFC] = useState<SelectContent|null>(null)
  const [sortV,setSortV] = useState<number|string>(0)
  const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({
    //if user is comming back from the countryTalkDetail page we want to show those filtered dates
    from: filteredDates && filteredDates.from ? filteredDates.from : today,
    to: filteredDates && filteredDates.to ? filteredDates.to : today
})
const [loading,setLoading] = useState(false)
const [confirmedFilterPhrase, setConfirmedFilterPhrase] = useState<GetCountryTalkListFilterT | null>({
    from: changeHourOfISODate(today, 0, 0, 1),
    to: changeHourOfISODate(today, 23, 59, 59)
})

  const [selectedTabId, setSelectedTabId] = useState<number>(0)
  
   //first fetch as soon as component mount

//   useEffect(() => {
//     const fetchData = async (access_token) => {
//         try {
//             setLoading(true)
//             const filters: GetCountryTalkListFilterT = {
//                 from: changeHourOfISODate(createdAt.from, 0, 0, 1),
//                 to: changeHourOfISODate(createdAt.to, 23, 59, 59),   
//             }
//             const res = await GetCountryTalkList({token: access_token, filters},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
//             setLoading(false)
//             if(res.status === 200){
//               const resData = res.data as GetCountryTalkListResT
                          
//                 //then we have to create the config for sort dropdown
//                 setSortFC(countryTalkSortFC_creator(Object.values(resData.data)[0]))
//                 setCountryTalks(resData)
//                 if(resData.data){
//                     setCountryTalkList(Object.values(resData.data))
//                 }
//                 else {
//                     setCountryTalkList([])
//                 }
//             }
//         }
//         catch(err){
//             setLoading(false)
//         }
//     }
//     if(userRedux.access_token){
//         fetchData(userRedux.access_token)
//         setFirstRender(false)
//     }
// },[userRedux.access_token])



const fetchData = async (access_token: string,filterPhrase: GetCountryTalkListFilterT) => {
  try {
      setLoading(true)
      const res = await GetCountryTalkList({token: access_token, filters: filterPhrase},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
      setLoading(false)
      if(res.status === 200){
          const resData = res.data as GetCountryTalkListResT
          setCountryTalks(resData)
          if(resData.data){
              setCountryTalkList(Object.values(resData.data))
          }
          else {
              setCountryTalkList([])
          }
      }
  }
  catch(err){
      setLoading(false)
  }
}


const confirmHandler = async() => {
  if(loading){
      return
  }
  //we want to validate inputs 

  //first we have to populate input values if exists any
  const filteredPhrase: GetCountryTalkListFilterT = { 
      from: confirmedFilterPhrase.from,
      to: confirmedFilterPhrase.to
  }

  let from: Date | null = null
  let to: Date | null = null
  //if createdAt is selected
  if(createdAt && createdAt.from){
      from = createdAt.from as Date
      filteredPhrase.from = changeHourOfISODate(from, 0, 0, 0)
  }
  if(createdAt && createdAt.to){
      to = createdAt.to as Date
      filteredPhrase.to = changeHourOfISODate(to, 23, 59, 59)
  }
  //then we have to correct the order of from and to 
  reorder_date_fields(createdAt, filteredPhrase)
  setConfirmedFilterPhrase(filteredPhrase)
  await fetchData(userRedux.access_token, filteredPhrase)
}

const sortChanger = (e: any, value: string | number, label: string, object: object) => {
  setSortV(value)
  //then we want to change the order of countryTalks based on the sortValue
  const newCountryTalkList = reorder_country_talks(Object.values(countryTalks.data), value as DesiredFieldX123T)
  setCountryTalkList(newCountryTalkList as CountryTalkWithPalCountriesT[])
}

let reconstructed_data_for_chart_v_1 = null
let reconstructed_data_for_chart_v_2 = null
let reconstructed_data_for_chart_v_3 = null
let reconstructed_data_for_chart_v_4 = null
let reconstructed_data_for_chart_v_5 = null

if(countryTalkList.length > 0){
  reconstructed_data_for_chart_v_1 = reconstruct_country_talk_data_for_graph_v_1(countryTalkList)
    reconstructed_data_for_chart_v_2 = reconstruct_country_talk_data_for_graph_v_2(countryTalkList)
    reconstructed_data_for_chart_v_3 = reconstruct_country_talk_data_for_graph_v_3(countryTalkList)
    reconstructed_data_for_chart_v_4 = reconstruct_country_talk_data_for_graph_v_4(countryTalkList)
    reconstructed_data_for_chart_v_5 = reconstruct_country_talk_data_for_graph_v_5(countryTalkList)
}


  return(
    <Template>
      <div className={countryTalkListC}>
        {
        // !loading && langFC && allChars ?
        // !loading && userRedux.access_token ?
        <MainPanel title='Country Talks' headButtons={[]}>
          <div className={container} style={{width: '100%'} as React.CSSProperties}>
            <div className={filterSpot}>
              <Filter  confirmHandler={confirmHandler} loading={loading} 
                createdAt={createdAt} setCreatedAt={setCreatedAt} 
                sortFC={sortFC} sortValue={sortV} setSortValue={sortChanger}
              />
            </div>
            <div className={body}>
              <Tabs 
                  components={[
                    <CountryTalkListTable 
                      filteredDates={filteredDates} countryTalkList={countryTalkList} createdAt={createdAt}
                      access_token={userRedux.access_token} refresh_token={userRedux.refresh_token} />,
                      <StackedAreaChart data={reconstructed_data_for_chart_v_1} title='' key="A" xInterval={1}/>,
                      <StackedAreaChart data={reconstructed_data_for_chart_v_2} title='' key="B" xInterval={1}/>,
                      <StackedAreaChart data={reconstructed_data_for_chart_v_3} title='' key="C" xInterval={1}/>,
                      <StackedAreaChart data={reconstructed_data_for_chart_v_4} title='' key="D" xInterval={1}/>,
                      <StackedAreaChart data={reconstructed_data_for_chart_v_5} title='' key="E" xInterval={1}/>
                  ]}
                  selectedTabId={selectedTabId}
                  data={[
                      {id: 0, title: 'table'},
                      {id: 1, title: 'talk'},
                      {id: 2, title: 'country'},
                      {id: 3, title: 'gender'},
                      {id: 4, title: 'total dur'},
                      {id: 5, title: 'avg dur'}
                  ]}
                  tabChanger={(tabId) => setSelectedTabId(tabId)}
              />
            </div>
          </div>
        
        </MainPanel>
        // :
        // <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        //   <SpinnerB/>
        // </div>
        }
      </div>
  </Template>  
  )
}



export default CountryTalkList