import styles from './newTalkListTable2.module.scss'
import { useState ,useEffect} from 'react'
import Filter from '../../filters/filterE'
import { CSVLink} from 'react-csv'
import MobilePagination from '../../pagination/mobilePagination'
import { SelectContent } from '../../formControl/formControl'
import {CharacterT, TalkT, TalkV2T} from '../../../utils/models'
import {changeHourOfISODate} from '../../../utils/utility'
import { FaCheck } from "react-icons/fa";
import ButtonA from '../../buttons/buttonA'
import {GetTalksV2, GetTalksV2ResT, GetTalksV2FiltersT, GetGroupedTalksFiltersT, GetGroupedTalks, GetGroupedTalksResT, GetTalksV3FiltersT, GetTalksV3} from '../../../utils/requests'
import { useNavigate } from 'react-router-dom'
import {useDispatch} from '../../../redux'
import {toast} from 'react-toastify'
import {
    CountryFC, AppVersionFC, finisherIdFC,
    TalkIdFC,
    minDurationFC,
    maxDurationFC,
    AccountIdFC,
    mediaDomainFC} from '../../../utils/formControl'

import { justNumberInputChanger, justStringInputChanger } from '../../../utils/validator'
import { reorder_date_fields } from '../../../utils/others'
import Tabs from '../../tab/tabs'
import PieChart from '../../charts/pieChart/pieChart'

const {newTalkListTable, filterBox, tableBox, userTable, headRow, bodyRow, noRecord, paginationBox, exportBtn, pair, single,redCss, greenCss  } = styles





type TalkListTablePropsT = {
    langFC: SelectContent
    sortFC: SelectContent
    endReasonFC: SelectContent
  talkType:SelectContent
    statusFC: SelectContent
    platformFC: SelectContent
    internetQualityFC: SelectContent
    allChars: CharacterT[]
    access_token: string
    refresh_token: string
    createdAt: {from: Date | null, to: Date | null, fromHr: number, toHr: number}
}

const NewTalkListTable2: React.FC<TalkListTablePropsT> = (props) => {
  
    
    const today = new Date()
    const yesterday = new Date(today.getTime() - 1 *24*60*60*1000)
   
    const [firstRender, setFirstRender] = useState(true)
    const [talks,setTalks] = useState<TalkV2T[]>([])
  const [page, setPage] = useState<number | null>(1)
  const [piedata,setPieData] = useState<any>([])
    const [totalPage, setTotalPage] = useState<number | null>(null)
    const [langV,setLangV] = useState(props.langFC.options[0].value as number)
    const [sortV,setSortV] = useState(props.sortFC.options[0].value as number)
    const [statusV, setStatusV] = useState(props.statusFC.options[0].value as number)
    const [platformV, setPlatformV] = useState(props.platformFC.options[0].value as number)
    const [internetQualityV, setInternetQualityV] = useState(props.internetQualityFC.options[0].value as number)
  const [endReasonV, setEndReasonV] = useState(props.endReasonFC.options[0].value as number)
  const [talkTypeV, setTypeTalkV] = useState(props.talkType.options[0].value as number)
  const [finisherIdV, setFinisherIdV] = useState('')
   const [mediaDomainV, setMediaDomainV] = useState<string | undefined>('')

    const [isAscending, setIsAscending] = useState<boolean>(false)
    const [isReconnectedV, setIsReconnectedV] = useState<boolean>(false)
    // const [talkIdV, setTalkIdV] = useState('')
    const [countryV, setCountryV] = useState('')
    const [appVersionV, setAppVersionV] = useState('')
   
    const [minDurationV, setMinDurationV] = useState('')
    const [maxDurationV, setMaxDurationV] = useState('')
    const [firstCharsV,setFirstCharsV] = useState<CharacterT[]>([])
    const [secondCharsV, setSecondCharsV] = useState<CharacterT[]>([])
    const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({
        from: props.createdAt && props.createdAt.from ? props.createdAt.from : today, 
        to: props.createdAt && props.createdAt.to ? props.createdAt.to : today
    })
    const [loading,setLoading] = useState(false)
    const [confirmedFilterPhrase, setConfirmedFilterPhrase] = useState<GetTalksV3FiltersT | null>({
        from: changeHourOfISODate(createdAt.from, props.createdAt.fromHr ? props.createdAt.fromHr : 0, 0, 1),
        to: changeHourOfISODate(createdAt.to, props.createdAt.toHr ? props.createdAt.toHr : 23, 59, 59)
    })
    const [talkIdV, setTalkIdV] = useState('')
    const [accountIdV, setAccountIdV] = useState('')
   const [selectedTabId, setSelectedTabId] = useState(0)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const countryChanger = justStringInputChanger(setCountryV)
    const minDurationChanger = justNumberInputChanger(setMinDurationV)
    const maxDurationChanger = justNumberInputChanger(setMaxDurationV)
    const talkIdChanger = justNumberInputChanger(setTalkIdV)
    const accountIdChanger = justNumberInputChanger(setAccountIdV)
  const finisherIdChanger = justNumberInputChanger(setFinisherIdV)
  

//we create pie chart data based on server fetchd data
  function transformArray(inputArray) {
     let sum = 0;
  for (const item of inputArray) {
    sum += item.count;
  } 
  const transformedArray = inputArray.map(item => ({
    value: ((item.count/sum)*100).toFixed(1),
    label: props.statusFC.options.find(stat => stat.value === item.status)?.label.toString()
  }));
    // console.log('result pie chart',transformedArray);
  return transformedArray;
  }
  
// it uses for the first time we open the report 
    // useEffect(() => {
    //   const fetchData = async (access_token) => {
    //       console.log('first fetch');
          
    //         try {
    //             setLoading(true)
    //             const filters: GetTalksV2FiltersT = {
    //                 from: confirmedFilterPhrase.from,
    //                 to: confirmedFilterPhrase.to,   
    //             }
    //             const res = await GetTalksV2({token: access_token, filters},{dispatch,navigate,refresh_token: props.refresh_token,toast})
    //             setLoading(false)
    //             if(res.status === 200){
    //               const resData = res.data as GetTalksV2ResT
    //               console.log('talk api2', resData);
    //                 setTalks(resData.data)
    //               setTotalPage(resData.meta.last_page)
                            
    //           }
    //             const pieDataRes = await GetGroupedTalks({token: access_token, filters},{dispatch,navigate,refresh_token: props.refresh_token,toast})
    //             setLoading(false)
    //             if(pieDataRes.status === 200){
    //               const piedata = pieDataRes.data as GetGroupedTalksResT
    //               // console.log('pie api', piedata.data);
    //               setPieData(piedata.data)
    //             }
              
    //         }
    //         catch(err){
    //             setLoading(false)
    //         }
    //     }
    //     if(props.access_token && firstRender){
    //         fetchData(props.access_token)
    //         setFirstRender(false)
    //   }
      

    // },[props.access_token])


  useEffect(() => {
 
    const fetchData = async (access_token) => {
         try {
                setLoading(true)
                let filters: GetTalksV3FiltersT = {
                    page: page
                }
                if(confirmedFilterPhrase){
                    filters =  {
                        ...confirmedFilterPhrase,
                        page: page
                    }
                }
                const res = await GetTalksV3({token: access_token, filters},{dispatch,navigate,refresh_token: props.refresh_token,toast})
                setLoading(false)
                if(res.status === 200){
                  const resData = res.data as GetTalksV2ResT
                   console.log('page api ',res.data);
                    setTalks(resData.data)
                    setTotalPage(resData.meta.last_page)
              }
                const pieDataRes = await GetGroupedTalks({token: access_token, filters},{dispatch,navigate,refresh_token: props.refresh_token,toast})
                setLoading(false)
                if(pieDataRes.status === 200){
                  const piedata = pieDataRes.data as GetGroupedTalksResT
                  setPieData(piedata.data)                   
              }
            }
            catch(err){
                setLoading(false)
            }
        }
    if (props.access_token && page>1) {
         fetchData(props.access_token)
            
        }
    },[page])

    
    //data we need to export as excel 
    let exportable_data: {
        index: number,
        talk_id: number,
        language: string,
        duration: number,
        status: string
        created_at: string
        reconnection_count: number
        end_reason: string,
        finisher_id: number
        account_id_A: number,  
        account_id_B: number,  
        character_A: string,  
        character_B: string, 
        platform_A: string,  
        platform_B: string, 
        app_version_A: string
        app_version_B: string
        ip_A: string
        ip_B: string
        country_A: string 
        country_B: string
        internet_quality_A: string
        internet_quality_B: string
    }[] = []
    let exportable_headers = [
        {key: 'index', label: 'index'},
        {key: 'talk_id', label: 'Talk ID'},
        {key: 'language', label: 'Language'},
        {key: 'duration', label: 'Duration'},
        {key: 'status', label: 'Status'},
        {key: 'created_at', label: 'Created At'},
        {key: 'reconnection_count', label: 'Reconnection Count'},
        {key: 'end_reason', label: 'End Reason'},
        {key: 'finisher_id', label: 'Finisher Id'},
        {key: 'account_id_A', label: 'AccountId A'},
        {key: 'account_id_B', label: 'AccountId B'},
        {key: 'character_A', label: 'Character A'},
        {key: 'character_B', label: 'Character B'},
        {key: 'platform_A', label: 'Platform A'},
        {key: 'platform_B', label: 'Platform B'},
        {key: 'app_version_A', label: 'App Version A'},
        {key: 'app_version_B', label: 'App Version B'},
        {key: 'ip_A', label: 'Ip A'},
        {key: 'ip_B', label: 'Ip B'},
        {key: 'country_A', label: 'Country A'},
        {key: 'country_B', label: 'Country B'},
        {key: 'internet_quality_A', label: 'Internet Quality A'},
        {key: 'internet_quality_B', label: 'Internet Quality B'},
    ]
    
  




  const fetchData = async (access_token: string, filterPhrase: GetTalksV2FiltersT) => {
          
        try {
            setLoading(true)
            const res = await GetTalksV3({token: access_token, filters: filterPhrase},{dispatch,navigate,refresh_token: props.refresh_token,toast})
            setLoading(false)
          if (res.status === 200) {
                         
              const resData = res.data as GetTalksV2ResT
              setTalks(resData.data)
              setTotalPage(resData.meta.last_page)
              setPage(1)
          }
           const pieDataRes = await GetGroupedTalks({token: access_token, filters: filterPhrase},{dispatch,navigate,refresh_token: props.refresh_token,toast})
                setLoading(false)
                if(pieDataRes.status === 200){
                  const piedata = pieDataRes.data as GetGroupedTalksResT
                  // console.log('pie api', piedata.data);
                  setPieData(piedata.data)               
              }
        }
        catch(err){
            setLoading(false)
        }
    }


    const confirmHandler = async() => {
        if(loading){
            return
        }
        //we want to validate inputs 

    
        //first we have to populate input values if exists any
        const filteredPhrase: GetTalksV2FiltersT = {
            is_ascending: isAscending,
            is_reconnected: isReconnectedV 
        }

      
        //if language is selected
        if(langV !== 0){
            filteredPhrase.language_id = langV
        }
        //if createdAt is selected
        if(createdAt && createdAt.from){
            const from = createdAt.from
            filteredPhrase.from = changeHourOfISODate(from, 0, 0, 0)
        }
        if(createdAt && createdAt.to){
            const to = createdAt.to
            filteredPhrase.to = changeHourOfISODate(to, 23, 59, 59)
        }
        //then we have to correct the order of from and to 
        reorder_date_fields(createdAt, filteredPhrase)
        //if country is filled
        if(countryV.length > 0){
            filteredPhrase.country = countryV
        }
        //if app_version is filled
        if(appVersionV.trim().length > 0){
            filteredPhrase.app_version = appVersionV.trim()
        }
        //if accountId is selected
        if(accountIdV.trim().length !== 0){
            filteredPhrase.account_id = +accountIdV.trim()
      }
      
        //if talkId is selected
        if(talkIdV.trim().length !== 0){
            filteredPhrase.talk_id = +talkIdV.trim()
      }
      //if media Domain is selected
        if(mediaDomainV.trim().length !== 0){
            filteredPhrase.media_domain = mediaDomainV.trim()
        }
        //if min_duration is filled
        if(minDurationV.trim().length > 0){
            filteredPhrase.min_duration = +minDurationV.trim()
        }
        //if max_duration is filled
        if(maxDurationV.trim().length > 0){
            filteredPhrase.max_duration = +maxDurationV.trim()
        }
        //if platform is filled
        if(platformV !== 0){
            filteredPhrase.platform = platformV
        }
        //if endReason is selected
        if(endReasonV !== -1){
            filteredPhrase.reason = endReasonV
        }
        //if max_duration is filled
        if(finisherIdV.trim().length > 0){
            filteredPhrase.finisher_id = +finisherIdV.trim()
        }
        //if firstChars is selected
        if(firstCharsV.length > 0){
            filteredPhrase.character_id = firstCharsV[0].value
        }
        //if status is choosed
        if(statusV !== 0){
            filteredPhrase.status = statusV
        }
        //if sortby is choosed
        if(sortV !== 0){
            filteredPhrase.sort = props.sortFC.options.find(so => so.value === sortV)?.label.toString()
        }
        //if internet quality is choosed 
        if(internetQualityV !== 0){
            filteredPhrase.internet_quality = internetQualityV
        }
        //  if internet quality is choosed 
        if(talkTypeV !== -1){
            filteredPhrase.type = talkTypeV
        }
        // console.log('filteredPhrase',filteredPhrase);
        
        setConfirmedFilterPhrase(filteredPhrase)
        await fetchData(props.access_token, filteredPhrase)
    }

    const languageChanger = (e: any, value: number, label: string, object: object) => {
        setLangV(value)
    }

    const sortChanger = (e: any, value: number, label: string, object: object) => {
        setSortV(value)
    }

    const statusChanger = (e: any, value: number, label: string, object: object) => {
        setStatusV(value)
    }

    const platformChanger = (e: any, value: number, label: string, object: object) => {
        setPlatformV(value)
    }

    const endReasonChanger = (e: any, value: number, label: string, object: object) => {
        setEndReasonV(value)
    }
const talkTypeChanger = (e: any, value: number, label: string, object: object) => {
        setTypeTalkV(value)
    }
    const internetQualityChanger = (e: any, value: number, label: string, object: object) => {
        setInternetQualityV(value)
    }

//    talks.forEach((talkRec,index) => {
//     const {talk_id,language_id,created_at,duration, status, pals} = talkRec
    
//     const charA = pals[0]
//     const charB = pals[1] 
//     exportable_data.push({
//         index: index+1,
//         talk_id,
//         language: props.langFC.options.find(lang => lang.value === language_id).label.toString(),
//         duration,
//         created_at,
//         status: props.statusFC.options.find(stat => stat.value === status).label.toString(),
//         account_id_A: charA.account_id,
//         account_id_B: charB.account_id,
//         character_A: props.allChars.find(ch => ch.value === charA.character_id)!.name.toString(),
//         character_B: props.allChars.find(ch => ch.value === charB.character_id)!.name.toString(),
//         app_version_A: charA.app_version,
//         app_version_B: charB.app_version,
//         country_A: charA.country,
//         country_B: charB.country,
//         ip_A: charA.ip,
//         ip_B: charB.ip,
//         internet_quality_A: props.internetQualityFC.options.find(iq => iq.value === charA.internet_quality).label.toString(),
//         internet_quality_B: props.internetQualityFC.options.find(iq => iq.value === charB.internet_quality).label.toString(),
//         platform_A: props.platformFC.options.find(pl => pl.value === charA.platform).label.toString(),
//         platform_B: props.platformFC.options.find(pl => pl.value === charB.platform).label.toString(),
//     })
//     })

useEffect(() => {
    // console.log('isReconnected --->', isReconnectedV)
    // console.log('isAscending --->', isAscending)
},[isReconnectedV, isAscending])


    const rows = talks?.map((talkRec,index) => {

        const {talk_id,language_id,created_at,duration, status, pals, reconnection_count, reason, finisher_id,type,} = talkRec
        // not-started (value===1)--> red
        // in-progress (value===2)--> green
        // finished (value===3) --> blue
        let colorCssClass = status === 1 || status === 8 || status === 9 ? redCss : status === 2 && greenCss 
        
        const endReason = props.endReasonFC.options.find(endR => endR.value === reason)?.label.toString()
        const charA = pals[0]
        const charB = pals[1] 
      const languageName = props.langFC.options.find(lang => lang.value === language_id)?.label.toString()
        const talkType = props.talkType.options.find(item => item.value === type)?.label.toString()
        const statusName = props.statusFC.options.find(stat => stat.value === status)?.label.toString()
        const charA_name = props.allChars.find(char => char.value === charA?.character_id)?.name.toString()
        const charB_name = props.allChars.find(char => char.value === charB?.character_id)?.name.toString()
        const mediaDomainA = charA.media_domain
        const mediaDomainB=charB.media_domain
        let internetQualityA = charA?.internet_quality     
        let internetQualityB = charB?.internet_quality
        const failure_stepA = charA.failure_step
      const failure_stepB = charB.failure_step

        let platformA = ''
        if(charA?.platform !== 0){
            platformA = props.platformFC.options.find(pl => pl.value === charA?.platform)?.label.toString()  
        } 
        let platformB = '' 
        if(charB?.platform !== 0){
            platformB = props.platformFC.options.find(pl => pl.value === charB?.platform)?.label.toString()
        }

        exportable_data.push({
            index: index+1,
            talk_id,
            language: languageName,
            duration,
            created_at,
            reconnection_count,
            end_reason: endReason,
            finisher_id,
            status: statusName,
            account_id_A: charA?.account_id,
            account_id_B: charB?.account_id,
            character_A: charA_name,
            character_B: charB_name,
            app_version_A: charA?.app_version,
            app_version_B: charB?.app_version,
            country_A: charA?.country,
            country_B: charB?.country,
            ip_A: charA?.ip,
            ip_B: charB?.ip,
            internet_quality_A: internetQualityA?.toString(),
            internet_quality_B: internetQualityB?.toString(),
            platform_A: platformA,
            platform_B: platformB,
        })

    
     
        return (
            <tr className={[bodyRow, colorCssClass].join(' ')} key={`${index}-sdas`}>
            <td>{index + 1}</td>
            <td>{created_at}</td>
            <td>{reconnection_count}</td>
            <td>{endReason}</td>
             <td>{finisher_id}</td>            
            <td>{talk_id}</td>
            <td>{talkType==='talk type talk' ? "Let's talk":talkType==='talk type number'?'number': 'call'}</td>
            <td>{languageName}</td>
             <td>{statusName}</td>
            <td>{duration}</td>
            <td>{new Date(created_at).toLocaleDateString('en')} T {new Date(created_at).toLocaleTimeString()}</td>
            <td className={pair}>
                    <div className={single}>{charA?.account_id}</div>
                    <div className={single}>{charB?.account_id}</div>
            </td>
                <td className={pair}>
                    <div className={single}>{charA_name}</div>
                    <div className={single}>{charB_name}</div>
            </td>
              <td className={pair}>
                    <div className={single}>{charA?.country}</div>
                    <div className={single}>{charB?.country}</div>
            </td>
             <td className={pair}>
                    <div className={single}>{mediaDomainA ? mediaDomainA: '-'}</div>
                    <div className={single}>{mediaDomainB ? mediaDomainB : '-'}</div>
            </td>
             <td>{mediaDomainA !== mediaDomainB && <FaCheck size={20} color='green' />}</td>
                <td className={pair}>
                    <div className={single}>{platformA}</div>
                    <div className={single}>{platformB}</div>
                </td>
                <td className={pair}>
                    <div className={single}>{charA?.app_version}</div>
                    <div className={single}>{charB?.app_version}</div>
                </td>
                <td className={pair}>
                    <div className={single}>{charA?.ip}</div>
                    <div className={single}>{charB?.ip}</div>
                </td>
              
                <td className={pair}>
                    <div className={single}>{internetQualityA}</div>
                    <div className={single}>{internetQualityB}</div>
            </td>
                 <td className={pair}>
                    <div className={single}>{failure_stepA}</div>
                    <div className={single}>{failure_stepB}</div>
            </td>
                <td className={pair}>
                    <div className={single}>{charA.get_media_info_duration}</div>
                    <div className={single}>{charB.get_media_info_duration}</div>
            </td>
                <td className={pair}>
                    <div className={single}>{charA.connect_ws_duration}</div>
                    <div className={single}>{charB.connect_ws_duration}</div>
            </td>
                <td className={pair}>
                    <div className={single}>{charA.call_init_duration}</div>
                    <div className={single}>{charB.call_init_duration}</div>
            </td>
                 <td className={pair}>
                    <div className={single}>{charA.exit_room_duration}</div>
                    <div className={single}>{charB.exit_room_duration}</div>
            </td>
            
           
            </tr>
        )
    })
    return(
    <div className={newTalkListTable}>
        <div className={filterBox}>
          <Filter allChars={props.allChars} confirmHandler={confirmHandler} loading={loading} //
            langsFC={props.langFC} langValue={langV} setLangValue={languageChanger} //
            sortFC={props.sortFC} sortValue={sortV} setSortValue={sortChanger}
            statusFC={props.statusFC} statusValue={statusV} setStatusValue={statusChanger}
            platformFC={props.platformFC} platformValue={platformV} setPlatformValue={platformChanger}
            // internetQualityFC={props.internetQualityFC} internetQualityValue={internetQualityV} setInternetQualityValue={internetQualityChanger}
            firstCharValues={firstCharsV} setFirstCharValues={setFirstCharsV}
            // talkIDContent={TalkIdFC} talkIDValue={talkIdV} setTalkIDValue={setTalkIdV} //
            createdAt={createdAt} setCreatedAt={setCreatedAt} //
            hasAscending={true} isAscending={isAscending} setIsAscending={() => setIsAscending(prev => !prev)}
            countryContent={CountryFC} countryValue={countryV} setCountryValue={countryChanger}
            // searchContent={searchFC} searchValue={searchV} setSearchValue={setSearchV}
            minDurationContent={minDurationFC} minDurationValue={minDurationV} setMinDurationValue={minDurationChanger}
            maxDurationContent={maxDurationFC} maxDurationValue={maxDurationV} setMaxDurationValue={maxDurationChanger}
            appVersionContent={AppVersionFC} appVersionValue={appVersionV} setAppVersionValue={setAppVersionV}
            isReconnected={isReconnectedV} setIsReconnected={setIsReconnectedV} hasReconnected={true}
            talkIDContent={TalkIdFC} talkIDValue={talkIdV} setTalkIDValue={talkIdChanger}
            accountIDContent={AccountIdFC} accountIDValue={accountIdV} setAccountIDValue={accountIdChanger}
            endReasonFC={props.endReasonFC} endReasonValue={endReasonV} setEndReasonValue={endReasonChanger}
            finisherIdContent={finisherIdFC} finisherIdValue={finisherIdV} setFinisherIdValue={finisherIdChanger}
            talkType={props.talkType} talkTypeValue={talkTypeV} setTalkTypeValue={talkTypeChanger} mediaDomainContent={ mediaDomainFC} mediaDomainValue={mediaDomainV} setMediaDomainValue={setMediaDomainV}
                
             />
        </div>
        <Tabs components={[
        
          <>
              <div className={tableBox}>
            <div className={exportBtn}>
                <CSVLink data={exportable_data} headers={exportable_headers} filename={"test.csv"} >
                    <ButtonA children={`Export Excell`} onClick={() => {}} type="secondary"/>
                </CSVLink>
            </div>
            <table className={userTable}>
                <thead>
                    <tr className={headRow}>
                    <td style={{ minWidth: '2em' } as React.CSSProperties}></td>                 
                    <td>Created At</td>
                    <td>Reconnection Count</td>
                    <td>Reason</td>
                    <td>finisher_id</td>
                    <td>Talk ID</td>
                    <td>Type</td>
                    <td>Language ID</td>
                    <td>Status</td>
                    <td>Duration</td>                      
                    <td>Created At (local)</td>                          
                    <td>Account IDs</td>
                    <td>Character IDs</td>
                    <td>Countries</td>
                    <td>Media Dommains</td>
                    <td>pipe</td>
                    <td>Platforms</td>
                    <td>App Versions</td>
                    <td>Ips</td>                       
                    <td>Internet Qualities</td>       
                    <td>failure</td>
                    <td>Get InfoT</td>  
                    <td>Ws ConnectionT</td>  
                     <td>Call InitT</td> 
                     <td>Exit RoomT</td>  



                    
                    </tr>
                </thead>
                <tbody>
                    {
                    rows?.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={14}>there is no record</td></tr>
                    }
                </tbody>
            </table>
        </div>
        <div className={paginationBox}>
            <MobilePagination parentCurrentPage={page} setParentCurrentPage={(page) => {setPage(page)}} totalPageNum={totalPage}/>
        </div>
          </>,          
          <PieChart title='Talk List Pie Chart' dataPoints={transformArray(piedata)} xLabel='' yLabel='' sign='%' />         
           
          ]} data={[
                  {id: 0, title: "Data Table"},
                  {id: 1, title: "Pie Chart"},
               
                ]}   selectedTabId={selectedTabId}
                tabChanger={(tabId) => setSelectedTabId(tabId)} />
      
    </div>

    )
} 


export default NewTalkListTable2