import styles from './dailyAccountReportTable.module.scss'
import {  DailyReportT } from '../../../utils/requests'
import { CSVLink} from 'react-csv'
import ButtonA from '../../buttons/buttonA'
const { dailyAccountReportTable, headRow, bodyRow, noRecord, pair, single,exportBtn} = styles

type DailyAccountReportTablePropsT = {
    data: DailyReportT[]
    dailyRowClickHandler: (report: DailyReportT) => void
}



const DailyAccountReportTable: React.FC<DailyAccountReportTablePropsT> = props => {
   
  let rows = []
   //data we need to export as excel 
    let exportable_data: {
        index: number,
        date: string,
        new_accounts: number,
        new_Talks:number,
        successful_talks: number,
        failed_talks: number,
      exited_talks: number,
      day_missed_talks: number,
        day_rejected_talks:number,
        Total_unique_accounts_more_than_1_talk: number,
        New_unique_accounts_with_more_than_1_talk: number,
      old_unique_accounts_with_more_than_1_talk: number,
       call_count: number,
      talk_count: number,
      number_count: number,
      ios_accounts: number,
      android_accounts: number,
      web_accounts: number
    }[] = []
    let exportable_headers = [
        {key: 'index', label: 'index'},
        {key: 'date', label: 'date'},
        {key: 'new_accounts', label: 'new_accounts'},
        {key: 'new_Talks', label: 'new_Talks'},
        {key: 'successful_talks', label: 'successful_talks'},
        { key: 'failed_talks', label: 'failed_talks' },
        { key: 'exited_talks', label: 'exited_talks' },
      { key: 'day_missed_talks', label: 'day_missed_talks' },
          {key: 'day_rejected_talks', label: 'day_rejected_talks'},
        { key: 'Total_unique_accounts_more_than_1_talk', label: 'Total_unique_accounts_more_than_1_talk' },
        { key: 'New_unique_accounts_with_more_than_1_talk', label: 'New_unique_accounts_with_more_than_1_talk' },
      { key: 'old_unique_accounts_with_more_than_1_talk', label: 'old_unique_accounts_with_more_than_1_talk' },
          {key: 'talk_count', label: 'talk count'},
        {key: 'call_count', label: 'call count'},
        {key: 'number_count', label: 'number count'},
        {key: 'ios_accounts', label: 'ios accounts'},
        { key: 'android_accounts', label: 'android accounts' },
        { key: 'web_accounts', label: 'web accounts' },
  ]
  //    talks.forEach((talkRec,index) => {
  //   const {talk_id,language_id,first_character_id,second_character_id,created_at,duration} = talkRec
  //   exportable_data.push({
  //       index: index+1,
  //       talk_id,
  //       language: props.langFC.options.find(lang => lang.value === language_id).label.toString(),
  //       first_character_id,
  //       second_character_id,
  //       duration,
  //       created_at
  //   })
  //  })
              props.data.forEach((record,index) => {
      const { day_successful_talks, new_day_pals, total_day_accounts, total_day_pals, total_day_talks ,day_exited_talks,failed_day_talks,day_missed_talks,day_rejected_talks,talk_count,call_count,number_count,ios_accounts,android_accounts,web_accounts} = record
    exportable_data.push({
        index: index+1,
      date: new Date(record.date).toLocaleDateString('en'),
      new_accounts: total_day_accounts,
      new_Talks: total_day_talks,
      successful_talks: day_successful_talks,
      failed_talks: failed_day_talks,
      exited_talks: day_exited_talks,
      day_missed_talks: day_missed_talks,
      day_rejected_talks:day_rejected_talks,
      Total_unique_accounts_more_than_1_talk: total_day_pals,
      New_unique_accounts_with_more_than_1_talk:new_day_pals ,
      old_unique_accounts_with_more_than_1_talk: total_day_pals - new_day_pals,
      talk_count: talk_count,
      call_count: call_count,
      number_count: number_count,
      ios_accounts: ios_accounts,
      android_accounts: android_accounts,
      web_accounts:web_accounts
        
        
    })
   })
    if(props.data){
        rows = props.data.map((record, index) => {
            
            const { day_successful_talks, new_day_pals, total_day_accounts, total_day_pals, total_day_talks,day_exited_talks,failed_day_talks,day_missed_talks ,day_rejected_talks,talk_count,call_count,number_count,ios_accounts,android_accounts,web_accounts} = record
            // const sumOfExited = record.hour_report.reduce((acc, curr) => acc+curr.exited_talk, 0)
            // const sumOfFailed = record.hour_report.reduce((acc, curr) => acc+curr.failed_talk, 0)
            const percentOfExited = total_day_talks > 0 ? Math.round((day_exited_talks/total_day_talks)*100) : 0
            const percentOfFailed = total_day_talks > 0 ? Math.round((failed_day_talks/total_day_talks)*100) : 0
            const percentOfSuccess = total_day_talks > 0 ? Math.round((day_successful_talks/total_day_talks)*100) : 0
            const new_pal_percentage = total_day_pals !== 0 ? Math.round((new_day_pals/total_day_pals)*100) : 0
            const old_day_pals = total_day_pals - new_day_pals
          const old_pal_percentage = total_day_pals !== 0 ? Math.round((old_day_pals / total_day_pals) * 100) : 0
          const percentofMissed = total_day_talks > 0 ? Math.round((day_missed_talks / total_day_talks) * 100) : 0
             const percentOfRejected= total_day_talks > 0 ? Math.round((day_rejected_talks/total_day_talks)*100) : 0
 
          return (
            
              
                <tr className={[bodyRow].join(' ')} key={`${index}-sdas`} onClick={() => props.dailyRowClickHandler(record)}>
                    <td>{index+1}</td>
                    <td>{new Date(record.date).toLocaleDateString('en')}</td>
                    <td>{total_day_accounts}</td>
                    <td>{android_accounts}</td>
                    <td>{ios_accounts}</td>                  
                    <td>{web_accounts}</td>
                    <td>{total_day_talks}</td>
                    <td>{talk_count}</td>
                    <td>{call_count}</td>
                    <td>{number_count}</td>
                    <td className={pair}>
                        <div className={single}>{day_successful_talks}</div>
                        <div className={single}>{percentOfSuccess}%</div>
                    </td>
                    <td className={pair}>
                        <div className={single}>{failed_day_talks}</div>
                        <div className={single}>{percentOfFailed}%</div>
                    </td>
                    <td className={pair}>
                        <div className={single}>{day_exited_talks}</div>
                        <div className={single}>{percentOfExited}%</div>
              </td>
               <td className={pair}>
                        <div className={single}>{day_missed_talks}</div>
                        <div className={single}>{percentofMissed}%</div>
              </td>
               <td className={pair}>
                        <div className={single}>{day_rejected_talks}</div>
                        <div className={single}>{percentOfRejected}%</div>
                    </td>
                    <td>{total_day_pals}</td>
                    <td className={pair}>
                        <div className={single}>{new_day_pals}</div>
                        <div className={single}>{new_pal_percentage}%</div>
                    </td>
                    <td className={pair}>
                        <div className={single}>{old_day_pals}</div>
                        <div className={single}>{old_pal_percentage}%</div>
                    </td>

                   
                    
              </tr>
              
            )

        })
    }

    return(
      <>
            <div className={exportBtn}>
                <CSVLink data={exportable_data} headers={exportable_headers} filename={"test.csv"} >
                    <ButtonA children={`Export Excel`} onClick={() => {}} type="secondary"/>
                </CSVLink>
            </div>
            <table className={dailyAccountReportTable}>
                <thead>
                    <tr className={headRow}>
                        <td style={{minWidth: '2em'} as React.CSSProperties}></td>
                        <td>Date</td>
                        <td>New accounts</td>
                        <td>android account</td>
                        <td>ios account</td>
                        <td>web account </td>
                        <td>New Talks </td>
                        <td>talk count</td>
                        <td>call count</td>
                        <td>number count </td>
                        <td>Successful Talks </td>
                        <td>Failed Talks </td>
                        <td>Exited Talks </td>
                        <td>missed Talk </td>
                        <td>Rejected talks </td>
                        <td>Total unique accounts with more than 1 talk </td>
                        <td>New unique accounts with more than 1 talk </td>
                        <td>old unique accounts with more than 1 talk </td>

                        
             
                    </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={8}>there is no record</td></tr>
                    }
                </tbody>
            </table>
        </>
    )
}


export default DailyAccountReportTable