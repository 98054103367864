import styles from './filterE.module.scss'

import DatePicker from '../datePicker/reactDatePicker'
import ButtonA from '../buttons/buttonA'
import RightImg from '../../assets/images/right.png'
import CalenderImg from '../../assets/images/calender.png'
import AddCharsFormControl from '../formControl/addCharsFormControl/addCharsFormControl'
import AddItemFormControl from '../formControl/addItemFormControl'
import FormControl,{SelectContent, OtherInputsContent} from '../formControl/formControl'
import AddObjectsFormControl from '../formControl/addObjectFormControl copy/addObjectFormControl'
import { CharacterT } from '../../utils/models'
import Check from '../formControl/check'

const {filter, filterItem, filterLabel, filterValue, arrIcon, calIcon, talkId,firstChars,
        secondChars, lang, firstAccount, secondAccount, firstDevice, secondDevice, 
        createdRange, confirmBtn, dateBox, sort, ascending, accountId, finisherId, endReason,
        country, appVersion, search, status, platform, minDuration, maxDuration, internetQuality, isReconnectedC,
        pass, topic, qualityOfService, type, platformsAdd, versionsAdd, retained, languagesAdd,talkTypeCss,mediaDomain
    } = styles


type FilterProps = {
    createdAt?: {from: Date, to: Date}
    setCreatedAt?: (a: any) => void

    talkIDContent?: OtherInputsContent
    talkIDValue?: string
    setTalkIDValue?: (a: string) => void

    typeContent?: OtherInputsContent
    typeValue?: string
    setTypeValue?: (a: string) => void

    passContent?: OtherInputsContent
    passValue?: string
    setPassValue?: (a: string) => void

    topicContent?: OtherInputsContent
    topicValue?: string
    setTopicValue?: (a: string) => void

    accountIDContent?: OtherInputsContent
    accountIDValue?: string
    setAccountIDValue?: (a: string) => void

    appVersionContent?: OtherInputsContent
    appVersionValue?: string
    setAppVersionValue?: (a: string) => void

    finisherIdContent?: OtherInputsContent
    finisherIdValue?: string
    setFinisherIdValue?: (a: string) => void

    countryContent?: OtherInputsContent
    countryValue?: string
    setCountryValue?: (a: string) => void

    searchContent?: OtherInputsContent
    searchValue?: string
    setSearchValue?: (a: string) => void

    minDurationContent?: OtherInputsContent
    minDurationValue?: string
    setMinDurationValue?: (a: string) => void

    maxDurationContent?: OtherInputsContent
    maxDurationValue?: string
    setMaxDurationValue?: (a: string) => void

    langsFC?: SelectContent
    langValue?: number
    setLangValue?: (e: any, value: number, label: string, object: object) => void

    endReasonFC?: SelectContent
    endReasonValue?: number
    setEndReasonValue?: (e: any, value: number, label: string, object: object) => void

    talkType?: SelectContent
    talkTypeValue?: number
    setTalkTypeValue?: (e: any, value: number, label: string, object: object) => void
    
    sortFC?: SelectContent
    sortValue?: number|string
    setSortValue?: (e: any, value: number, label: string, object: object) => void

    qualityOfServiceFC?: SelectContent
    qualityOfServiceValue?: number|string
    setQualityOfServiceValue?: (e: any, value: number, label: string, object: object) => void

    statusFC?: SelectContent
    statusValue?: number
    setStatusValue?: (e: any, value: number, label: string, object: object) => void

    platformFC?: SelectContent
    platformValue?: number
    setPlatformValue?: (e: any, value: number, label: string, object: object) => void

    internetQualityFC?: SelectContent
    internetQualityValue?: number
    setInternetQualityValue?: (e: any, value: number, label: string, object: object) => void

    allChars?: CharacterT[]
    firstCharValues?: CharacterT[]
    setFirstCharValues?: (a) => void

  

    firstAccountIDContent?: OtherInputsContent
    firstAccountIDValue?: string
    setFirstAccountIDValue?: (a: string) => void

    secondAccountIDContent?: OtherInputsContent
    secondAccountIDValue?: string
    setSecondAccountIDValue?: (a: string) => void

    firstDeviceIDContent?: OtherInputsContent
    firstDeviceIDValue?: string
    setFirstDeviceIDValue?: (a: string) => void

    secondDeviceIDContent?: OtherInputsContent
    secondDeviceIDValue?: string
    setSecondDeviceIDValue?: (a: string) => void

    hasAscending?: boolean
    isAscending?: boolean 
    setIsAscending?: (a: boolean) => void

    hasRetained?: boolean
    isRetained?: boolean 
    setIsRetained?: (a: boolean) => void

    hasReconnected?: boolean
    isReconnected?: boolean 
    setIsReconnected?: (a: boolean) => void

    confirmHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void
    loading?: boolean
    hideConfirmBtn?: boolean

    versionsAdd_FC?: boolean
    versionAdd_addHandler?: () => void
    versionAdd_children?: React.ReactNode
    versionAdd_confirmedArr?: string[]
    versionAdd_removeHandler?: (a: string) => void


    platformAdd_FC?: boolean
    platformAdd_isMultiValue?: boolean
    platformAdd_options?: {name: string, value: string|number}[]
    platformAdd_setValues?: (a: any) => void
    platformAdd_values?: {name: string, value: string|number}[]
    platformAdd_removeHandler?: (value: string|number) => void


    languagesAdd_FC?: boolean
    languagesAdd_isMultiValue?: boolean
    languagesAdd_options?: {name: string, value: string|number}[]
    languagesAdd_setValues?: (a: any) => void
    languagesAdd_values?: {name: string, value: string|number}[]
  languages_removeHandler?: (value: string | number) => void
    mediaDomainContent?:OtherInputsContent
    mediaDomainValue?: string
    setMediaDomainValue?:(a:string)=>void

}

const Filter:React.FC<FilterProps> = ({
    talkIDContent, talkIDValue, setTalkIDValue,
    accountIDContent, accountIDValue, setAccountIDValue,
    langsFC, langValue, setLangValue,
    allChars, firstCharValues, setFirstCharValues,
    firstAccountIDContent, firstAccountIDValue, setFirstAccountIDValue,
    secondAccountIDContent, secondAccountIDValue, setSecondAccountIDValue,
    firstDeviceIDContent, firstDeviceIDValue, setFirstDeviceIDValue,
    secondDeviceIDContent, secondDeviceIDValue, setSecondDeviceIDValue,
    createdAt, setCreatedAt,
    sortFC, sortValue, setSortValue,
    isAscending, setIsAscending, hasAscending,
    countryContent, countryValue, setCountryValue,
    searchContent, searchValue, setSearchValue,
    statusFC, statusValue, setStatusValue,
    appVersionContent, appVersionValue, setAppVersionValue,
    platformFC, platformValue, setPlatformValue,
    internetQualityFC, internetQualityValue, setInternetQualityValue,
    minDurationContent, minDurationValue, setMinDurationValue,
    maxDurationContent, maxDurationValue, setMaxDurationValue,
    confirmHandler, loading,
    isReconnected, setIsReconnected, hasReconnected,
  endReasonFC, endReasonValue, setEndReasonValue,
    talkType,talkTypeValue,setTalkTypeValue,
    finisherIdContent, finisherIdValue, setFinisherIdValue,
    hideConfirmBtn,
    versionsAdd_FC, versionAdd_addHandler, versionAdd_children, versionAdd_confirmedArr, versionAdd_removeHandler,
    platformAdd_FC, platformAdd_isMultiValue, platformAdd_options, platformAdd_setValues, platformAdd_values, platformAdd_removeHandler,
    passContent, passValue, setPassValue,
    topicContent, topicValue, setTopicValue,
    isRetained, setIsRetained, hasRetained,
    qualityOfServiceFC, setQualityOfServiceValue, qualityOfServiceValue,
    typeContent, setTypeValue, typeValue,
    languagesAdd_FC, languagesAdd_isMultiValue, languagesAdd_options, languages_removeHandler, languagesAdd_setValues, languagesAdd_values, mediaDomainValue,setMediaDomainValue,mediaDomainContent
}) => {




    return (
    <div className={filter}>
   
        {
        createdAt && setCreatedAt ?
        <div className={[filterItem, createdRange].join(' ')}>
            <div className={filterLabel}>created at:</div>
            <div className={filterValue}>
                <div className={dateBox}><DatePicker date={createdAt.from} setDate={(date) => {setCreatedAt(prev => ({...prev,from: date}))}} placeholder="Start date"/></div>
                <img src={RightImg} className={arrIcon}/>
                <div className={dateBox}><DatePicker date={createdAt.to} setDate={(date) => {setCreatedAt(prev => ({...prev, to: date}))}} placeholder="End date"/></div>
                <img src={CalenderImg} className={calIcon}/>
            </div>
        </div>
        :""
        }
        {
        passContent ?
        <div className={[filterItem, pass].join(' ')}>
            <div className={filterLabel}>password:</div>
            <div className={filterValue}>
                <FormControl content={passContent} value={passValue} setParentValue={setPassValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        typeContent ?
        <div className={[filterItem, type].join(' ')}>
            <div className={filterLabel}>type:</div>
            <div className={filterValue}>
                <FormControl content={typeContent} value={typeValue} setParentValue={setTypeValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }

        {
        topicContent ?
        <div className={[filterItem, topic].join(' ')}>
            <div className={filterLabel}>topic:</div>
            <div className={filterValue}>
                <FormControl content={topicContent} value={topicValue} setParentValue={setTopicValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        
        {
        talkIDContent ?
        <div className={[filterItem, talkId].join(' ')}>
            <div className={filterLabel}>talk ID:</div>
            <div className={filterValue}>
                <FormControl content={talkIDContent} value={talkIDValue} setParentValue={setTalkIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }

        {
        accountIDContent ?
        <div className={[filterItem, accountId].join(' ')}>
            <div className={filterLabel}>account ID:</div>
            <div className={filterValue}>
                <FormControl content={accountIDContent} value={accountIDValue} setParentValue={setAccountIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }

        {
        countryContent ?
        <div className={[filterItem, country].join(' ')}>
            <div className={filterLabel}>country:</div>
            <div className={filterValue}>
                <FormControl content={countryContent} value={countryValue} setParentValue={setCountryValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }

        {
        minDurationContent ?
        <div className={[filterItem, minDuration].join(' ')}>
            <div className={filterLabel}>min duration:</div>
            <div className={filterValue}>
                <FormControl content={minDurationContent} value={minDurationValue} setParentValue={setMinDurationValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }

        {
        maxDurationContent ?
        <div className={[filterItem, maxDuration].join(' ')}>
            <div className={filterLabel}>max duration:</div>
            <div className={filterValue}>
                <FormControl content={maxDurationContent} value={maxDurationValue} setParentValue={setMaxDurationValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }

        {
        appVersionContent ?
        <div className={[filterItem, appVersion].join(' ')}>
            <div className={filterLabel}>app version:</div>
            <div className={filterValue}>
                <FormControl content={appVersionContent} value={appVersionValue} setParentValue={setAppVersionValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }

        {
        searchContent ?
        <div className={[filterItem, search].join(' ')}>
            <div className={filterLabel}>search:</div>
            <div className={filterValue}>
                <FormControl content={searchContent} value={searchValue} setParentValue={setSearchValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }

        {
        finisherIdContent ?
        <div className={[filterItem, finisherId].join(' ')}>
            <div className={filterLabel}>finisher id:</div>
            <div className={filterValue}>
                <FormControl content={finisherIdContent} value={finisherIdValue} setParentValue={setFinisherIdValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
 {
        talkType ?
        <div className={[filterItem, talkTypeCss].join(' ')}>
            <div className={filterLabel}>Type:</div>
            <div className={filterValue}>
                <FormControl content={talkType} value={talkTypeValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setTalkTypeValue}/>
            </div>
        </div>
        :""
        }
         
        {
        langsFC ?
        <div className={[filterItem, lang].join(' ')}>
            <div className={filterLabel}>language:</div>
            <div className={filterValue}>
                <FormControl content={langsFC} value={langValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setLangValue}/>
            </div>
        </div>
        :""
        }

        {
        statusFC ?
        <div className={[filterItem, status].join(' ')}>
            <div className={filterLabel}>status:</div>
            <div className={filterValue}>
                <FormControl content={statusFC} value={statusValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setStatusValue}/>
            </div>
        </div>
        :""
        }

        {
        endReasonFC ?
        <div className={[filterItem, endReason].join(' ')}>
            <div className={filterLabel}>reason:</div>
            <div className={filterValue}>
                <FormControl content={endReasonFC} value={endReasonValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setEndReasonValue}/>
            </div>
        </div>
        :""
        }
 
        {
        internetQualityFC ?
        <div className={[filterItem, internetQuality].join(' ')}>
            <div className={filterLabel}>internet quality:</div>
            <div className={filterValue}>
                <FormControl content={internetQualityFC} value={internetQualityValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setInternetQualityValue}/>
            </div>
        </div>
        :""
        }

        {
        qualityOfServiceFC ?
        <div className={[filterItem, qualityOfService].join(' ')}>
            <div className={filterLabel}>quality of service:</div>
            <div className={filterValue}>
                <FormControl content={qualityOfServiceFC} value={qualityOfServiceValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setQualityOfServiceValue}/>
            </div>
        </div>
        :""
        }
      
        {    
        sortFC ? 
        <div className={[filterItem, sort].join(' ')}>
            <div className={filterLabel}>sort by:</div>
            <div className={filterValue}>
                <FormControl content={sortFC} value={sortValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setSortValue}/>
            </div>
        </div>
        : ""
        }

        {    
        platformFC ? 
        <div className={[filterItem, platform].join(' ')}>
            <div className={filterLabel}>platform:</div>
            <div className={filterValue}>
                <FormControl content={platformFC} value={platformValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setPlatformValue}/>
            </div>
        </div>
        : ""
        }

        {    
        hasAscending ?
        <div className={[filterItem, ascending].join(' ')}>
            <div className={filterLabel}>is ascending:</div>
            <div className={filterValue}>
                <Check id='ascending' children="" value={isAscending} onChangeHandler={setIsAscending}/>
            </div>
        </div>
        :
        ""
        }
        {    
        hasRetained ?
        <div className={[filterItem, retained].join(' ')}>
            <div className={filterLabel}>retained:</div>
            <div className={filterValue}>
                <Check id='retained' children="" value={isRetained} onChangeHandler={setIsRetained}/>
            </div>
        </div>
        :
        ""
        }
        {    
        hasReconnected ?
        <div className={[filterItem, isReconnectedC].join(' ')}>
            <div className={filterLabel}>is reconnected:</div>
            <div className={filterValue}>
                <Check id='has_been_reconnected' children="" value={isReconnected} onChangeHandler={setIsReconnected}/>
            </div>
        </div>
        :
        ""
        }
        {
        firstAccountIDContent ?
        <div className={[filterItem, firstAccount].join(' ')}>
            <div className={filterLabel}>first account ID:</div>
            <div className={filterValue}>
                <FormControl content={firstAccountIDContent} value={firstAccountIDValue} setParentValue={setFirstAccountIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        secondAccountIDContent ?
        <div className={[filterItem, secondAccount].join(' ')}>
            <div className={filterLabel}>second account ID:</div>
            <div className={filterValue}>
                <FormControl content={secondAccountIDContent} value={secondAccountIDValue} setParentValue={setSecondAccountIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        firstDeviceIDContent ?
        <div className={[filterItem, firstDevice].join(' ')}>
            <div className={filterLabel}>first device ID:</div>
            <div className={filterValue}>
                <FormControl content={firstDeviceIDContent} value={firstDeviceIDValue} setParentValue={setFirstDeviceIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        secondDeviceIDContent ?
        <div className={[filterItem, secondDevice].join(' ')}>
            <div className={filterLabel}>second device ID:</div>
            <div className={filterValue}>
                <FormControl content={secondDeviceIDContent} value={secondDeviceIDValue} setParentValue={setSecondDeviceIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        allChars && setFirstCharValues ?
        <div className={[filterItem, firstChars].join(' ')}>
            <div className={filterLabel}>character:</div>
            <div className={filterValue}>
                <AddCharsFormControl values={firstCharValues} setValues={setFirstCharValues} name="character" options={allChars} isMultiValue={false}/>
            </div>
        </div>
        :
        ""
        }
        {
        versionsAdd_FC ?
        <div className={[filterItem, versionsAdd].join(' ')}>
            <div className={filterLabel}>versions:</div>
            <div className={filterValue}>
                <AddItemFormControl 
                    addHandler={versionAdd_addHandler}
                    btnTitle='add version'
                    children={(
                        <div className={filterValue}>
                            {versionAdd_children}
                        </div>)}
                    confirmedArr={versionAdd_confirmedArr}
                    isValid={true}
                    removeHandler={versionAdd_removeHandler}
                />
            </div>
        </div>
        :
        ""
        }

        {
        languagesAdd_FC ?
        <div className={[filterItem, languagesAdd].join(' ')}>
            <div className={filterLabel}>languages:</div>
            <div className={filterValue}>
                <AddObjectsFormControl 
                    isMultiValue={languagesAdd_isMultiValue}
                    name='languagesAdd'
                    options={languagesAdd_options}
                    setValues={languagesAdd_setValues}
                    values={languagesAdd_values}
                    removeHandler={languages_removeHandler}
                />
            </div>
        </div>
        :
        ""
        }

        {
        platformAdd_FC ?
        <div className={[filterItem, platformsAdd].join(' ')}>
            <div className={filterLabel}>platforms:</div>
                <div className={filterValue}>
                    <AddObjectsFormControl 
                        isMultiValue={platformAdd_isMultiValue}
                        name='platform_add'
                        options={platformAdd_options}
                        setValues={platformAdd_setValues}
                        values={platformAdd_values}
                        removeHandler={platformAdd_removeHandler}
                    />
            </div>

        </div>
        
        :
        ""
        }
        { 
          mediaDomainContent ?
            <div className={[filterItem, mediaDomain].join(' ')}>
            <div className={filterLabel}>media Domain:</div>
            <div className={filterValue}>
                <FormControl content={mediaDomainContent} value={mediaDomainValue} setParentValue={setMediaDomainValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
          </div>: ""
          
        }
       
        {
            !hideConfirmBtn &&  
            <div className={confirmBtn}>
                <ButtonA children={'filter result'} type='primary' loading={loading} onClick={confirmHandler}/>
            </div>
        }
    </div>
    )
}


export default Filter