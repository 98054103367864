import styles from './countryTalkDetailTable.module.scss'

import {CountryTalkT} from '../../../utils/models'




const {countryTalkDetailTable, filterBox, tableBox, userTable,
     headRow, bodyRow, noRecord, paginationBox, exportBtn, pair, single,
    redCss, greenCss, blueCss, yellowCss, body
    } = styles





type CountryTalkDetailTablePropsT = {
    access_token: string
    refresh_token: string
    countryTalkList: CountryTalkT[]
}

const CountryTalkDetailTable:React.FC<CountryTalkDetailTablePropsT> = (props) => {



  




    



let rows = []
if(props.countryTalkList.length > 0){

    rows = props.countryTalkList.map((countryRec,index) => {

        const {above_five_minutes_talks, characters, country, exited_talks, failed_talks,
            successful_talks, total_count_non_zero_pings, total_duration, total_pings_in_millisecond, total_talks
        } = countryRec

        const manChars = characters.filter((char, index) => [1,2,12,13,14,15,16,17,18,24,28,30,32,34,36,38,40,42,44,46,48,50,52,54,56,58].includes(char.id)).reduce((acc, rec) => acc+rec.count, 0)
        const womanChars = characters.filter((char, index) => [3,4,5,6,7,8,9,10,11,23,25,26,27,29,31,32,33,35,37,39,41,43,45,47,49,51,53,55,57,59].includes(char.id)).reduce((acc, rec) => acc+rec.count, 0)
        const totalChars = manChars + womanChars
        const manPercentage = Math.round((manChars / totalChars)*100)
        const womanPercentage = Math.round((womanChars / totalChars)*100)
        const avgDuration = successful_talks > 0 ? Math.round((total_duration / successful_talks)) : 0
        
     
        return (
            <tr className={[bodyRow].join(' ')} key={`${index}-sdas`}>
                <td>{index+1}</td>
                <td>{country}</td>
                <td>{total_talks}</td>
                <td>{successful_talks}</td>
                <td>{failed_talks}</td>
                <td>{exited_talks}</td>
                <td className={pair}>
                    <div className={single}>{manChars}</div>
                    <div className={single}>{manPercentage}%</div>
                </td>
                <td className={pair}>
                    <div className={single}>{womanChars}</div>
                    <div className={single}>{womanPercentage}%</div>
                </td>
                <td>{above_five_minutes_talks}</td>
                <td>{total_duration}</td>
                <td>{avgDuration}</td>
            </tr>
        )
    })

}

  
    return(
    <div className={countryTalkDetailTable}>
         <div className={tableBox}>
        <table className={userTable}>
            <thead>
                <tr className={headRow}>
                    <td style={{minWidth: '2em'} as React.CSSProperties}></td>
                    <td>Country</td>
                    <td>Total Call</td>
                    <td>Success</td>
                    <td>Failed</td>
                    <td>Exit</td>
                    <td>Man</td>
                    <td>Woman</td>
                    <td>Last 5 Minutes</td>
                    <td>Total Duration</td>
                    <td>Avg Duration</td>
                </tr>
            </thead>
            <tbody>
                {
                rows.length > 0 ?
                rows : 
                <tr className={noRecord}><td colSpan={13}>there is no record</td></tr>
                }
            </tbody>
        </table>
    </div>

    </div>


    )
} 


export default CountryTalkDetailTable