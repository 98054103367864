import { TalkStatusResT } from '../../../utils/requests'
import ButtonA from '../../buttons/buttonA'
import styles from './talkstatustable.module.scss'
import { CSVLink} from 'react-csv'
const { dailyAccountReportTable, headRow, bodyRow, noRecord, pair, single,exportBtn} = styles
type props = {
  talkStatusData:TalkStatusResT
}
const TalkStatusTable: React.FC<props> = ({ talkStatusData}) => {
   let rows = []
   let exportable_data: {
        index: number,
        date: string,
        ExitedCount: number,
     FinishCount: number,
        FinishPercent:number,
        InProgressPercent: number,
        count: number,
     exitedPercent: number,
     inProgressCount: number,
       notStartCount:number,
        notStartPercent: number,

  }[] = []
     let exportable_headers = [
        {key: 'index', label: 'index'},
        {key: 'date', label: 'date'},
        {key: 'ExitedCount', label: 'ExitedCount'},
        {key: 'FinishCount', label: 'FinishCount'},
        {key: 'FinishPercent', label: 'FinishPercent'},
        { key: 'InProgressPercent', label: 'InProgressPercent' },
        { key: 'count', label: 'count' },
      { key: 'exitedPercent', label: 'exitedPercent' },
          {key: 'inProgressCount', label: 'inProgressCount'},
        { key: 'notStartCount', label: 'notStartCount' },
        { key: 'notStartPercent', label: 'notStartPercent' },
       
  ]
               talkStatusData.forEach((record,index) => {
      const { ExitedCount,FinishCount,FinishPercent,InProgressPercent,count,exitedPercent,fiveMinutes,inProgressCount,notStartCount,notStartPercent} = record
    exportable_data.push({
        index: index+1,
      date:fiveMinutes,
     ExitedCount: ExitedCount,
      FinishCount: FinishCount,
      FinishPercent: FinishPercent,
      InProgressPercent: InProgressPercent,
      count: count,
      exitedPercent:exitedPercent,
      inProgressCount: inProgressCount,
      notStartCount:notStartCount ,
        notStartPercent: notStartPercent
        
    })
   })
   if(talkStatusData){
        rows = talkStatusData.map((record, index) => {
            
         const { ExitedCount,FinishCount,FinishPercent,InProgressPercent,count,exitedPercent,fiveMinutes,inProgressCount,notStartCount,notStartPercent} = record      
 
          return (
            
              
                <tr className={[bodyRow].join(' ')} key={`${index}-sdas`} >
                    <td>{index+1}</td>
                    <td>{fiveMinutes}</td>
                    <td>{new Date(fiveMinutes).toLocaleDateString('en')} T {new Date(fiveMinutes).toLocaleTimeString()}</td>
                    <td>{ExitedCount}</td>
                    <td>{exitedPercent}</td>
                    <td>{FinishCount}</td>
                    <td>{FinishPercent}</td>
                    <td>{inProgressCount}</td>
                    <td>{InProgressPercent}</td>             
                    <td>{notStartCount}</td>
                    <td>{notStartPercent}</td>
                    <td>{count}</td>
                              

                  
               
              </tr>
              
            )

        })
    }
     return(
      <>
            <div className={exportBtn}>
                <CSVLink data={exportable_data} headers={exportable_headers} filename={"test.csv"} >
                    <ButtonA children={`Export Excel`} onClick={() => {}} type="secondary"/>
                </CSVLink>
            </div>
            <table className={dailyAccountReportTable}>
                <thead>
                    <tr className={headRow}>
                        <td style={{minWidth: '2em'} as React.CSSProperties}></td>
                        <td>Date</td>
                        <td>Date (local)</td>     
                        <td>Exited Count</td>
                        <td>Exited Percent </td>
                        <td>Finish Count</td>
                        <td>Finish Percent</td>
                        <td>in Progress Count </td>
                        <td>In Progress Percent </td>
                        <td>not Start Count </td>
                        <td>not Start Percent </td>
                        <td>Total count </td>
             </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={8}>there is no record</td></tr>
                    }
                </tbody>
            </table>
        </>
    )
}
export default TalkStatusTable