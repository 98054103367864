import Template from '../../components/template/template'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import { useState, useEffect } from 'react'
import SpinnerB from '../../components/spinner/spinnerB'
import NewTalkListTable from '../../components/tables/newTalkListTable/newTalkListTable'
import styles from './newTalkList2.module.scss'
import { useTypedSelector, useDispatch } from '../../redux'
import {SelectContent} from '../../components/formControl/formControl'
import {LangT, CharacterT} from '../../utils/models'
import {endReasonFC_creator, internetQualityFC_creator, langFC_creator, platformFC_creator, sortFC_creator, statusFC_creator, talkTypeFC_creator} from '../../utils/formControl'
import { useLocation, useNavigate } from 'react-router-dom'
import { historyPropertiesExistanceCheck } from '../../utils/utility'
import { ButtonAProps } from '../../components/buttons/buttonA'
import { PATHES, PLATFORMS_ARR } from '../../utils/contants'
import { DailyReportT } from '../../utils/requests'
import Tabs from '../../components/tab/tabs'
import NewTalkListTable2 from '../../components/tables/newTalkListTable2/newTalkListTable2'
const {newTalkList, container, backBtn} = styles

const NewTalkList2 = () => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const filteredDates: {from: Date | null, to: Date | null, fromHr: number, toHr: number} = historyPropertiesExistanceCheck(location, 'filteredDates', {from: null, to: null, fromHr: 0, toHr: 23})
  const from: string = historyPropertiesExistanceCheck(location, 'from', null)
  const dailyReportData: DailyReportT = historyPropertiesExistanceCheck(location, 'dailyReport', [])
  const [langFC, setLangFC] = useState<SelectContent | null>(null)
  const [sortFC, setSortFC] = useState<SelectContent | null>(null)
  const [endReasonFC, setEndReasonFC] = useState<SelectContent | null>(null)
   const [talkTypeFC, setTalkTypeFC] = useState<SelectContent | null>(null)
  const [statusFC, setStatusFC] = useState<SelectContent | null>(null)
  const [platformFC, setPlatformFC] = useState<SelectContent | null>(null)
  const [internetQualityFC, setInternetQualityFC] = useState<SelectContent | null>(null)
  const [allChars, setAllChars] = useState<CharacterT[] | null>(null)
  const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const [loading, setLoading] = useState(false)

 
//  console.log('filteredDates -AAA-->', filteredDates)
// console.log('config redux',configRedux);


  //configs based on roles and status comes from redux for dropdown
  useEffect(() => {
    const sort_arr = [{name: "id", value: 1}, {name: "created_at", value: 2}, {name: "duration", value: 3}]   //string
    setSortFC(sortFC_creator(sort_arr)) 

    setTalkTypeFC(talkTypeFC_creator(configRedux.talkType) )
    setStatusFC(statusFC_creator(configRedux.talkStatuses))

    setEndReasonFC(endReasonFC_creator(configRedux.talkEndReasons))

    
    setPlatformFC(platformFC_creator(PLATFORMS_ARR))

    const internet_quality_arr = [{name: "very bad", value: 1}, {name: "bad", value: 2}, {name: "normal", value: 3}, {name: "good", value: 4}, {name: "very good", value: 5}]
    setInternetQualityFC(internetQualityFC_creator(internet_quality_arr))

    if(configRedux.allLanguages.length > 0){
      setLangFC(langFC_creator(configRedux.allLanguages))
    }
    if(configRedux.allCharacters.length > 0){
      setAllChars(configRedux.allCharacters)
    }
 
  },[configRedux])


  const backBtnHandler = () => {
    navigate(PATHES.home)
    //if user had come from daily-hour-list page we want to navigate him there again
    // if(from === PATHES.talkList){
    //   navigate(PATHES.talkList, {
    //     state: {
    //       filteredDates: {
    //         from : filteredDates.from,
    //         to: filteredDates.to
    //       },
    //       from: PATHES.talkList,
    //       dailyReport: dailyReportData
    //     }
   
    //   })
  
    // }
  }


  const btnPropsArr: ButtonAProps[] = [
    {
      children: 'Back',
      type: 'terthiary',
      externalClass: backBtn,
      onClick: backBtnHandler
    }
  ]

  return(
    <Template>
      <div className={newTalkList}>
        {
        // !loading && langFC && allChars ?
        !loading && langFC && statusFC && allChars && sortFC && platformFC && internetQualityFC && endReasonFC && userRedux.access_token ?
            <MainPanel title='Talks' headButtons={btnPropsArr}>
        
              <div className={container} style={{ width: '100%' } as React.CSSProperties}>
          
            <NewTalkListTable2 
                  createdAt={filteredDates}
                  endReasonFC={endReasonFC}
                  langFC={langFC} sortFC={sortFC} statusFC={statusFC} platformFC={platformFC} internetQualityFC={internetQualityFC}
                  allChars={allChars} access_token={userRedux.access_token} refresh_token={userRedux.refresh_token}
                  talkType={talkTypeFC }
                />
              
              </div>
               
        </MainPanel>
        :
        <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
          <SpinnerB/>
        </div>
        }
      </div>
  </Template>  
  )
}



export default NewTalkList2