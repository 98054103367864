import axios, { AxiosRequestHeaders } from "axios";
import CONFIG from '../config.json'
import { UserReduxActionsT, ConfigsReduxActionsT, UserReduxActionTypesT, ConfigsReduxActionTypesT } from '../redux/index'
import { BackendCompleteUserDocT, LangT, ResourceWithActions, TalkT, TalkReportsDataT, LangStatT, TalkV2T, CountryTalkWithPalCountriesT, BackendCompleteUserSuspensionDocT, TalkPieT } from './models'
import { PATHES } from './contants'
import { CharT } from './models'







type ArgBX32 = {
  dispatch: (a: UserReduxActionsT | ConfigsReduxActionsT) => void
  navigate: (a: NavigateArg1T, b?: NavigateArg2T) => void,
}

const signout_and_redirect_to_login = ({ dispatch, navigate }: ArgBX32) => {
  dispatch({ type: UserReduxActionTypesT.removeAuthUser })
  dispatch({ type: ConfigsReduxActionTypesT.removeConfigs })
  navigate({ pathname: PATHES.login }, { replace: true })
}

//------------------------------------------------------------------------------------------
type ToastObjT = {
  error: (a: string) => void
}
type NavigateArg1T = {
  pathname: string
}
type NavigateArg2T = {
  replace?: boolean,
  state?: object
}

type AxiosOptionsT = {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  url: string
  headers?: AxiosRequestHeaders
  data?: any
}
type SendGenralReqT = {
  axiosOptions: AxiosOptionsT
  toast: ToastObjT,
  dispatch: (a: UserReduxActionsT | ConfigsReduxActionsT) => void
  navigate: (a: NavigateArg1T, b?: NavigateArg2T) => void,
  refresh_token: string
}
export const send_general_req = async ({ axiosOptions, toast, dispatch, navigate, refresh_token }: SendGenralReqT, has_refreshed_token_before?: boolean) => {
  let refreshed_token_before = has_refreshed_token_before ? has_refreshed_token_before : false
  const axios_options_for_refreshing_the_token: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_1 }/user/token`,
    headers: {
      Authorization: `Bearer ${ refresh_token }`
    }
  }
  //first we have to stop the sending another req for refreshing token for second-time, and we have to sign-out the user  
  if (axiosOptions.url === axios_options_for_refreshing_the_token.url && axiosOptions.method === axios_options_for_refreshing_the_token.method && refreshed_token_before) {
    return signout_and_redirect_to_login({ dispatch, navigate })
  }
  //first we have to send the req
  let response
  try {
    response = await axios(axiosOptions)
    //in the case of a req for refreshing the token 
    if (axiosOptions.url === axios_options_for_refreshing_the_token.url && axiosOptions.method === axios_options_for_refreshing_the_token.method) {
      //in the case of any error while refreshing the tokens, we have to throw an error to handle this condition in the catch block
      if (response.status !== 200) {
        const error: any = new Error('')
        error.response = response
        throw error
      }
      //in the case of successful response while refreshing the token, we have to save those tokens into redux and session-storage
      dispatch({ type: UserReduxActionTypesT.updateTokens, payload: response.data.tokens })
    }
    //in the case of a req for other pathes
    else {
      //in the case of any error we have to throw an error to handle this condition in the catch block
      if (response.status !== 200 && response.status !== 201) {
        const error: any = new Error('')
        error.response = response
        throw error
      }
    }
    //returning whole response body to make it accessible for handling other things
    return response
  }
  catch (err) {
    //in the case of any error in a req for refreshing the token 
    if (axiosOptions.url === axios_options_for_refreshing_the_token.url && axiosOptions.method === axios_options_for_refreshing_the_token.method) {
      //in the case of any error while refreshing the tokens, we have to sign-out the user and redirect to login
      signout_and_redirect_to_login({ dispatch, navigate })
    }
    //in the case of any error in a req for other pathes
    else {
      //in the case of 401 res
      if (err.response.status === 401) {
        try {
          const refreshing_res = await send_general_req({ axiosOptions: axios_options_for_refreshing_the_token, toast, dispatch, navigate, refresh_token }, refreshed_token_before)
          //if we get a successful res for refreshing the token
          if (refreshing_res && refreshing_res.status === 200) {
            refreshed_token_before = true
            const new_access_token = refreshing_res.data.tokens.access_token
            const new_refresh_token = refreshing_res.data.tokens.refresh_token
            const new_axios_options: AxiosOptionsT = {
              ...axiosOptions,
              headers: {
                ...axiosOptions.headers,
                Authorization: `Bearer ${ new_access_token }`
              }
            }
            let last_try_response
            try {
              last_try_response = send_general_req({ axiosOptions: new_axios_options, dispatch, navigate, refresh_token: new_refresh_token, toast }, refreshed_token_before)

              if (last_try_response.status !== 200 || last_try_response.status !== 201) {
                throw new Error('an error occures while trying for second time')
              }
              //if we get a 200 res for last try, we have to return last_try_response 
              return last_try_response
            }
            catch (err) {
              //if any error occures while last_try_response, we have to return it
              return last_try_response
            }
          }
          //in the case of any error for refreshing the token
          else {
            throw new Error('an error occures while refreshing the token')
          }
        }
        catch (err) {
          //if any error occures while refreshing the tokens, we have to return the first response 
          return response
        }
      }
      ///in the case of status === 422, first we have to check for existance error property in res.data, if it doesnt exist we have to show message property of res.data
      else if (err.response.status === 422) {
        if (err.response.data && (err.response.data as object).hasOwnProperty('error')) {
          //if error is an obj with some key/value pairs, we have to show all of them
          if (typeof err.response.data.error === 'object') {
            const errorKeys = Object.keys(err.response.data.error)
            let final_combined_error_message: string = ''
            for (const errorKey of errorKeys) {
              final_combined_error_message = final_combined_error_message + err.response.data.error[errorKey].toString() + '/n'
            }
            toast.error(final_combined_error_message)
          }
          //if error is a string
          else if (typeof err.response.data.error === 'string') {
            toast.error(err.response.data.error)
          }
          else {
            toast.error(err.response.data.message)
          }
        }
        //if it doesnt have error property in res.data, we have to show message property
        else {
          toast.error(err.response.data.message)
        }
      }
      //in the case of other kinds of errory req, we have to show the error to the user
      else if (err.response.status !== 200 && err.response.status !== 201) {
        toast.error(err.response.data.message)
      }
      return err.response
    }
  }
}
//----------------------------------------------------------------------
//type which is needed for passing tools of send_general_req
type GeneralReqArgX12T = {
  toast: ToastObjT,
  dispatch: (a: UserReduxActionsT | ConfigsReduxActionsT) => void
  navigate: (a: NavigateArg1T, b?: NavigateArg2T) => void,
  refresh_token: string
}

//----------------------------------------------------------------------
type SendLoginReqT = {
  email: string
  password: string
}
export type LoginResponseT = {
  tokens: {
    access_token: string;
    refresh_token: string
  },
  permissions: ResourceWithActions[]
}
export const sendLoginRequset = async (data: SendLoginReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `${ CONFIG.API_URL_1 }/v1/login`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//-------------------------------------------------------------------
export type SendRegisterReqT = {
  email: string
  password: string
  role: number
}
export type RegisterUserResponseT = {
  user_id: number
  tokens: {
    access_token: string
    refresh_token: string
  }
}
export const sendRegisterRequest = async (data: SendRegisterReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `${ CONFIG.API_URL_1 }/v1/register`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...data
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//-------------------------------------------------------------------
export const getUserInfo = async (token, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_1 }/user/users`,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//-------------------------------------------------------------------
export const getConfigs = async (token, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_1 }/v1/config`,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//------------------------------------------------------------------------
export type GetUserListResT = {
  data: BackendCompleteUserDocT[]
  meta: {
    total: number
    per_page: number
    current_page: number
    last_page: number
  }
}
export const getUserList = async (token, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_1 }/user/users/list`,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}


//-----------------------------------------------------------------------------
//http://localhost:8096/user/users/list? search=as & is_ascending=false & status=2 & role=2 & size=5 & sort=created_at & page=2
export type GetFilteredUserListReqT = {
  size?: number
  page?: number
  search?: string
  is_ascending?: boolean
  status?: number
  role?: number
  sort?: 'created_at' | 'id' | 'phone_number' | 'email' | 'username' | 'first_name' | 'last_name' | 'role' | 'status'
  from?: string
  to?: string
}
export const getFilteredUserList = async (token, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T, filterObj: GetFilteredUserListReqT, page: number = undefined) => {
  const newFilterObj = { ...filterObj }
  if (page) {
    newFilterObj.page = page
  }
  let query = ''
  for (const key of Object.keys(newFilterObj)) {
    query = query + `&${ key }=${ newFilterObj[key] }`
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_1 }/user/users/list?${ query }`,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}


//-----------------------------------------------------------------------------------
export type AdminCreateNewUserReqBodyT = {
  email: string
  password: string
  role: number
  phone_number: string,
  username: string,
  first_name: string,
  last_name: string
}
export const adminCreateNewUser = async (token, data: AdminCreateNewUserReqBodyT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `${ CONFIG.API_URL_1 }/admin/user`,
    headers: {
      Authorization: `Bearer ${ token }`
    },
    data: {
      ...data
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//------------------------------------------------------------------------------------
export const adminChangeUserRole = async (token, userId, role: number, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'PATCH',
    url: `${ CONFIG.API_URL_1 }/admin/user/${ userId }/role`,
    headers: {
      Authorization: `Bearer ${ token }`
    },
    data: {
      role: role
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//--------------------------------------------------------------------
export const adminChangeUserStatus = async (token, userId, status: number, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'PATCH',
    url: `${ CONFIG.API_URL_1 }/admin/user/${ userId }/status`,
    headers: {
      Authorization: `Bearer ${ token }`
    },
    data: {
      status: status
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//------------------------------------------------------------------------
export type AdminChangeUserDetailsReqBody = {
  user_id: number
  first_name: string
  last_name: string
  username: string
  phone_number: string
}
export const adminChangeUserDetails = async (token, data: AdminChangeUserDetailsReqBody, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'PUT',
    url: `${ CONFIG.API_URL_1 }/admin/user`,
    headers: {
      Authorization: `Bearer ${ token }`
    },
    data: {
      ...data
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//------------------------------------------------------------------------
export type EditProfileReqBodyT = {
  phone_number: string
  first_name: string
  last_name: string
  username: string
  // email: string
}
export const editProfile = async (token, data: EditProfileReqBodyT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'PUT',
    url: `${ CONFIG.API_URL_1 }/user/users`,
    headers: {
      Authorization: `Bearer ${ token }`
    },
    data: {
      ...data
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//--------------------------------------------------------------------------------
export type ChangeProfilePasswordReqT = {
  current_password: string,
  new_password: string,
  confirmed_new_password: string
}
export const changeProfilePassword = async (token, data: ChangeProfilePasswordReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'PATCH',
    url: `${ CONFIG.API_URL_1 }/user/users`,
    headers: {
      Authorization: `Bearer ${ token }`
    },
    data: {
      ...data
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}


//---------------------------------------------------------------------
export type ConfirmedResourcePolicyT = {
  name?: string
  resource: number
  action: number
  is_denied: boolean
  is_applied: boolean
}
export type AdminEditUserAccessPoliciesReqT = {
  user_id: number
  access_policies: {
    name?: string
    resource: number
    action: number
    is_denied: boolean
  }[]
}
export type AdminEditUserAccessPoliciesResT = {
  user_id: number
  access_policies: {
    name: string
    resource: number
    action: number
    is_denied: boolean
    is_applied: boolean
  }[]
}
export const adminEditUserAccessPolicies = async (token, data: AdminEditUserAccessPoliciesReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `${ CONFIG.API_URL_1 }/admin/user/access-policies`,
    headers: {
      Authorization: `Bearer ${ token }`
    },
    data: {
      ...data
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}


//-----------------------------------------------------------------------------------
export type GetRolePoliciesResT = {
  permissions: ResourceWithActions[] | null
}
export const getRolePolcies = async (token, role: number, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_1 }/admin/role/${ role }/permissions`,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//--------------------------------------------------------------------------------
export type AdminEditRoleAccessPolicyReqT = {
  role: number,
  access_policies: {
    name?: string
    resource: number
    action: number
    is_denied: boolean
  }[]
}
export type ResourcePolicyEditT = {
  name: string
  resource: number,
  action: number,
  is_denied: boolean
  is_applied: boolean
}
export type AdminEditRoleAccessPolicyResT = {
  role: number,
  access_policies: ResourcePolicyEditT[]
}
export const adminEditRoleAccessPolicies = async (token, data: AdminEditRoleAccessPolicyReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `${ CONFIG.API_URL_1 }/admin/role/access-policies`,
    headers: {
      Authorization: `Bearer ${ token }`
    },
    data: {
      ...data
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//---------------------------------------------------------------
export type RequestToChangePasswordReqT = {
  email: string
}
export const requestToChangePassword = async (data: RequestToChangePasswordReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `${ CONFIG.API_URL_1 }/v1/send-reset-password-code`,
    data: {
      ...data
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//-------------------------------------------------------------
export type RefreshPasswordReqT = {
  email: string
  code: string
  password: string
}
export const refreshPassword = async (data: RefreshPasswordReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'PATCH',
    url: `${ CONFIG.API_URL_1 }/v1/forgot-password`,
    data: {
      ...data
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//------------------------------------------------------------
export type GetCharactersResT = {
  list: CharT[]
  total: number
}
export const GetCharacters = async (token, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_2 }/v1/characters?version=1`,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//------------------------------------------------------------
export type GetLangsResT = {
  list: LangT[]
  total: number
}
export const GetLangs = async (token, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_2 }/v1/languages?version=1`,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//------------------------------------------------------------
export type GetTalksResT = {
  list: TalkT[] | null
}

export type GetTalkFiltersT = {
  talk_id?: string
  language_id?: string
  character_id?: string
  from?: string
  to?: string
}
export type GetTalksReqT = {
  token: string
  filters?: GetTalkFiltersT
}

export const GetTalks = async ({ token, filters }: GetTalksReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_1 }/admin/reports/talk`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//////////////////////////////////////////////
export type GetTalksV2ResT = {
  data: TalkV2T[] | null
  meta: {
    total: number
    per_page: number
    current_page: number
    last_page: number
  }
}

export type GetTalksV2FiltersT = {
  from?: string
  to?: string
  sort?: string
  page?: number
  size?: number
  is_ascending?: boolean
  country?: string
  app_version?: string
  search?: string
  status?: number
  platform?: number
  character_id?: number
  min_duration?: number
  max_duration?: number
  internet_quality?: number
  language_id?: number
  is_reconnected?: boolean
  talk_id?: number
  account_id?: number
  reason?: number
  finisher_id?: number
  type?: number
  media_domain?: string

}
export type GetTalksV2ReqT = {
  token: string
  filters?: GetTalksV2FiltersT
}

export const GetTalksV2 = async ({ token, filters }: GetTalksV2ReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_1 }/admin/reports/talks`
  console.log('filter', filters);

  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//////////////////////////////////////////////

export type GetTalkStatsFiltersT = {
  talk_id?: string
  language_id?: string
  character_id?: string
  from?: string
  to?: string
}

export type GetTalkStatsReqT = {
  token: string
  filters?: GetTalkStatsFiltersT
}


export type GetTalkStatsResT = TalkReportsDataT
export const GetTalkStats = async ({ token, filters }: GetTalkStatsReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_1 }/admin/reports/stat`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}

//------------------------------------------------------------
export type GetTalkAnotherLangT = {
  language_id: number,
  requests: {
    account_id: number,
    character_id: number,
    waiting_time: number,
    country: string,
    gender: string
  }[] | null
}
export type GetTalkAnotherResT = {
  languages: GetTalkAnotherLangT[]
}
export const GetTalkAnother = async (token, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_2 }/v1/requests`,
    // headers: {
    //   Authorization: `Bearer ${token}`
    // }
  }
  return await axios(options)
}
//------------------------------------------------------------
export type GetDashboardFiltersT = {
  from?: string
  to?: string
}
export type GetDashboard1ReqT = {
  token: string
  filters?: GetTalkStatsFiltersT
}
export type GetDashboard1ResT = {
  active_room_count: number,
  total_room: number,
  started_talk_average: number,
  total_talk_average: number,
  total_exited_talks: number,
  total_failed_talks: number
  men_pals: number
  women_pals: number
  total_pals: number
  progress_call: number
}
export const GetDashboard1 = async ({ token, filters }: GetTalkStatsReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 

  let url = `${ CONFIG.API_URL_1 }/admin/reports/dashboard`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  //  console.log('dashboard filter',filters); 

  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}


//------------------------------------------------------------
export type GetDashboard2ResT = {
  online_users: number
  search_users: number
}
export const GetDashboard2 = async (token: string, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_2 }/v1/requests`,
    // headers: {
    //   Authorization: `Bearer ${token}`
    // }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}


//------------------------------------------------------------
export type GetStatsListFilterT = {
  from?: string
  to?: string
  language_id?: number
}
export type GetStatsListReqT = {
  token: string
  filters?: GetStatsListFilterT
}
export type GetStatsListResT = {
  total_count: number
  total_duration: number
  average_duration: number
  stats: LangStatT[],
}
export const GetStatsList = async ({ token, filters }: GetStatsListReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_1 }/admin/stats/talk`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}



//------------------------------------------------------------
export type GetCountryTalkListFilterT = {
  from?: string
  to?: string
}
export type GetCountryTalkListReqT = {
  token: string
  filters?: GetCountryTalkListFilterT
}
export type GetCountryTalkListResT = {
  data: {
    [countryName: string]: CountryTalkWithPalCountriesT
  }
}
export const GetCountryTalkList = async ({ token, filters }: GetCountryTalkListReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {

  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_1 }/admin/reports/countries`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}


//------------------------------------------------------------
// export type GetDailyTalkListFilterT = {
//   from?: string
//   to?: string
// }
// export type GetDailyTalkListReqT = {
//   token: string
//   filters?: GetStatsListFilterT
// }
// export type AccountReportT = {
//   total_day_accounts: number,
//   total_day_pals: number,
//   new_day_pals: number,
//   total_day_talks: number,
//   day_successful_talks: number
// }
// export type DurationReportT = {
//   five: number,
//   fifteen: number,
//   thirty: number,
//   sixty: number,
//   hundred: number
// }
// export type HourReportT = {
//   hour: number,
//   total_talk: number,
//   failed_talk: number,
//   successful_talk: number,
//   exited_talk: number
// }
// export type DailyReportT = {
//   date: string,
//   account_report: AccountReportT,
//   duration_report: DurationReportT,
//   hour_report: HourReportT[]
// }
// export type GetDailyTalkListResT = {
//   report: DailyReportT[]
// }
// export const GetDailyTalkList = async ({ token, filters }: GetDailyTalkListReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
//   //first we have to create a url based on the filters 
//   let url = `${ CONFIG.API_URL_1 }/admin/reports/daily/summary`
//   if (filters) {
//     let counter = 1
//     const filter_keys = Object.keys(filters)
//     for (const filter_key of filter_keys) {
//       if (counter === 1) {
//         url = url + '?' + filter_key + '=' + filters[filter_key]
//       }
//       else {
//         url = url + '&' + filter_key + '=' + filters[filter_key]
//       }
//       counter = counter + 1
//     }
//   }
//   const options: AxiosOptionsT = {
//     method: 'GET',
//     url,
//     headers: {
//       Authorization: `Bearer ${ token }`
//     }
//   }
//   return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
// }


//------------------------------------------------------------

export type SendAdminPublishMessageReqT = {
  pass: string,
  topic: string,
  qos: number,
  retained: boolean,
  payload: {
    type: string,
    content: {
      timestamp: number,
      platforms: string[]
      versions: string[]
      accounts: string[],
      languages: {
        language_id: number,
        content: string
      }[]
    }[]
  }
}
export type SendAdminPublishMessageResT = {
}
export const sendAdminPublishMessage = async (token: string, reqBody: SendAdminPublishMessageReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  // console.log('reg body',reqBody);  
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_2 }/admin/publish`

  const options: AxiosOptionsT = {
    method: 'POST',
    url,
    headers: {
      Authorization: `Bearer ${ token }`,
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(reqBody)
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//------------------------------------------------------------------------
export type GetUserSuspendListResT = {
  suspensions: BackendCompleteUserSuspensionDocT[]
  meta: {
    total: number
    per_page: number
    current_page: number
    last_page: number
  }
}
export const getUserSuspensionList = async (token, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_2 }/admin/suspensions`,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//-----------------------------------------------------------------------------
//http://localhost:8096/user/users/list? search=as & is_ascending=false & status=2 & role=2 & size=5 & sort=created_at & page=2
export type GetFilteredUserSuspensionListReqT = {
  size?: number
  page?: number
  account_id?: string
  is_ascending?: boolean
  status?: number
  role?: number
  sort?: 'created_at' | 'id' | 'account_id' | 'expiration'
  from?: string
  to?: string

}
export const getFilteredUserSuspensionList = async (token, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T, filterObj: GetFilteredUserSuspensionListReqT, page: number = undefined) => {
  const newFilterObj = { ...filterObj }
  if (page) {
    newFilterObj.page = page
  }
  let query = ''
  for (const key of Object.keys(newFilterObj)) {
    query = query + `&${ key }=${ newFilterObj[key] }`
  }

  const options: AxiosOptionsT = {
    method: 'GET',
    url: `${ CONFIG.API_URL_2 }/admin/suspensions?${ query }`,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//-----------------------------------------------------------------------------------
export type AdminCreateNewUserSuspensionReqBodyT = {
  account_id: number
  duration_in_hour: number
  type: number
}
export const adminCreateNewUserSuspension = async (token, data: AdminCreateNewUserSuspensionReqBodyT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  // console.log('suspend data',data);  
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `${ CONFIG.API_URL_2 }/admin/suspensions`,
    headers: {
      Authorization: `Bearer ${ token }`
    },
    data
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
export const adminDeleteSuspensionMember = async (token, id: number, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  const options: AxiosOptionsT = {
    method: 'DELETE',
    url: `${ CONFIG.API_URL_2 }/admin/suspensions/${ id }`,
    headers: {
      Authorization: `Bearer ${ token }`
    },


  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
///////////////////////////////////////////////////////////////////
export type GetGroupedTalksResT = {
  data: TalkPieT[]

}

export type GetGroupedTalksFiltersT = {
  from?: string
  to?: string
  sort?: string
  page?: number
  size?: number
  is_ascending?: boolean
  country?: string
  app_version?: string
  search?: string
  status?: number
  platform?: number
  character_id?: number
  min_duration?: number
  max_duration?: number
  internet_quality?: number
  language_id?: number
  is_reconnected?: boolean
  talk_id?: number
  account_id?: number
  reason?: number
  finisher_id?: number
  type?: number

}
export type GetGrupedTalkReqT = {
  token: string
  filters?: GetGroupedTalksFiltersT
}

export const GetGroupedTalks = async ({ token, filters }: GetGrupedTalkReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_1 }/admin/reports/talks/grouped`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//------------------------------------------------------------
export type GetDailyTalkListFilterT = {
  from?: string
  to?: string
}
export type GetDailyTalkListReqT = {
  token: string
  filters?: GetStatsListFilterT
}
export type AccountReportT = {
  total_day_accounts: number,
  total_day_pals: number,
  new_day_pals: number,
  total_day_talks: number,
  inprogress_day_talks: number,
  failed_day_talks: number,
  day_successful_talks: number,
  day_exited_talks: number,
  day_waiting_talks: number,
  day_rejected_talks: number,
  day_missed_talks: number,
  day_cancelled_talks: number,
  day_calling_talks: number,
  ringing_talks: number,
  day_canceled_ring_talks: number
}
// export type DurationReportT = {
//   five: number,
//   fifteen: number,
//   thirty: number,
//   sixty: number,
//   hundred: number
// }
// export type HourReportT = {
//   hour: number,
//   total_talk: number,
//   failed_talk: number,
//   successful_talk: number,
//   exited_talk: number
// }
export type DailyReportT = {
  date: string,
  // account_report: AccountReportT,
  // duration_report: DurationReportT,
  // hour_report: HourReportT[]
  total_day_accounts: number,
  total_day_pals: number,
  new_day_pals: number,
  total_day_talks: number,
  inprogress_day_talks: number,
  failed_day_talks: number,
  day_successful_talks: number,
  day_exited_talks: number,
  day_waiting_talks: number,
  day_rejected_talks: number,
  day_missed_talks: number,
  day_cancelled_talks: number,
  day_calling_talks: number,
  ringing_talks: number,
  day_canceled_ring_talks: number
  five: number,
  fifteen: number,
  thirty: number,
  sixty: number,
  hundred: number,
  call_count: number,
  talk_count: number,
  number_count: number,
  ios_accounts: number,
  android_accounts: number,
  web_accounts: number


}
export type GetDailyTalkListResT = DailyReportT[]

export const GetDailyTalkList = async ({ token, filters }: GetDailyTalkListReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_1 }/admin/reports/daily/summary`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//-----------------------------------------------------------------------------------------------------
//------------------------------------------------------------
export type GetHourReportFilterT = {
  from?: string
  to?: string
}
export type GetHourTalkListReqT = {
  token: string
  filters?: GetStatsListFilterT
}
export type AccountNewReportT = {
  total_day_accounts: number,
  total_day_pals: number,
  new_day_pals: number,
  total_day_talks: number,
  inprogress_day_talks: number,
  failed_day_talks: number,
  day_successful_talks: number,
  // day_exited_talks: number,
  // day_waiting_talks: number,
  // day_rejected_talks: number,
  // day_missed_talks: number,
  // day_cancelled_talks: number,
  // day_calling_talks: number,
  // ringing_talks: number,
  // day_canceled_ring_talks: number
}
export type DurationReportT = {
  five: number,
  fifteen: number,
  thirty: number,
  sixty: number,
  hundred: number
}
export type HourReportT = {
  hour: number,
  total_talk: number,
  failed_talk: number,
  successful_talk: number,
  exited_talk: number
}
export type DailyHoursReportT = {
  date: string,
  account_report: AccountNewReportT,
  duration_report: DurationReportT,
  hour_report: HourReportT[]
}
export type GetHoursTalkListResT = {
  report: DailyHoursReportT[]
}
export const GetHoursDailyTalkList = async ({ token, filters }: GetHourTalkListReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_1 }/admin/reports/daily`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//---------------------------------------------------------
export type TalkListStatusFilterT = {
  from?: string
  to?: string
  per?: string
  p1_media?: string
  p2_media?: string
  p1_platform?: number
  p2_platform?: number
  talk_type?: number

}
export type TalkListStatusReqT = {
  token: string
  filters?: TalkListStatusFilterT
}
export type talkStatusReportT = {
  fiveMinutes: string,
  count: number,
  notStartCount: number,
  notStartPercent: number,
  inProgressCount: number,
  InProgressPercent: number,
  FinishCount: number,
  FinishPercent: number,
  ExitedCount: number,
  exitedPercent: number
}
export type TalkStatusResT = talkStatusReportT[]

export const GetTalkStatus = async ({ token, filters }: TalkListStatusReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_1 }/admin/reports/get-grouped-talk-status-by-time`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//----------------------------------------------
export type TalkStepFilterT = {
  from?: string
  to?: string
}
interface DataItem {
  COMPLETED?: number;
  NOT_GET_CREATE_ROOM_RESPONSE?: number;
  NOT_GET_JOIN_RESPONSE?: number;
  NOT_GET_PRODUCE_DATA_RESPONSE?: number;
  WITHOUT_STEP?: number;
  NOT_GET_CONNECT_TRANSPORT_RESPONSE?: number;
  NOT_GET_DO_CONSUME?: number;
  CONNECT_WS?: number,
  LOAD_BALANCER?: number
}
interface talkStepReportItem {
  id: number;
  created_at: string;
  updated_at: string;
  time_range_start: string;
  time_range_end: string;
  data: {
    [key: string]: DataItem;
  }[];
}

export type TalkStepReqT = {
  token: string
  filters?: TalkStepFilterT
}
export type talkStepReportT = {
  report: talkStepReportItem[];
}
export type TalkStepResT = talkStepReportT[]

export const GetTalkStep = async ({ token, filters }: TalkListStatusReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  let url = `${ CONFIG.API_URL_1 }/admin/steps/report`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//----------------------------------------------------------------
export type FailTalkFilterT = {
  from?: string
  to?: string
  p1_platform?: number
  p2_platform?: number
  sort?: string
  call_type?: number
  p1_media?: string
  p2_media?: string
  media?: string
}
export type FailTalkReqT = {
  token: string
  filters?: FailTalkFilterT
}
export type FailTalkItemT = {
  id: number,
  p1_media: string,
  p2_media: string,
  notStartedCount: number,
  notStartedPercent: number,
  inProgressPercent: number
  inProgressCount: number,
  finishedCount: number,
  finishedPercent: number,
  exitedCount: number,
  exitedPercent: number,
  total: number
}
export type FailTalkResT = {
  items: FailTalkItemT[],
  total_failed: number,
  total_inprogress: number,
  total_finished: number,
  total_exited: number,
}

export const GetFailTalk = async ({ token, filters }: TalkListStatusReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_1 }/admin/reports/get-grouped-failed-talk-by-domain`
  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}
//--------------------------------
export type GetTalksV3ResT = {
  data: TalkV2T[] | null
  meta: {
    total: number
    per_page: number
    current_page: number
    last_page: number
  }
}

export type GetTalksV3FiltersT = {
  from?: string
  to?: string
  sort?: string
  page?: number
  size?: number
  is_ascending?: boolean
  country?: string
  app_version?: string
  search?: string
  status?: number
  platform?: number
  character_id?: number
  min_duration?: number
  max_duration?: number
  internet_quality?: number
  language_id?: number
  is_reconnected?: boolean
  talk_id?: number
  account_id?: number
  reason?: number
  finisher_id?: number
  type?: number
  media_domain?: string

}
export type GetTalksV3ReqT = {
  token: string
  filters?: GetTalksV2FiltersT
}

export const GetTalksV3 = async ({ token, filters }: GetTalksV2ReqT, { dispatch, navigate, refresh_token, toast }: GeneralReqArgX12T) => {
  //first we have to create a url based on the filters 
  let url = `${ CONFIG.API_URL_4 }/v1/talks-query`
  console.log('filter', filters);

  if (filters) {
    let counter = 1
    const filter_keys = Object.keys(filters)
    for (const filter_key of filter_keys) {
      if (counter === 1) {
        url = url + '?' + filter_key + '=' + filters[filter_key]
      }
      else {
        url = url + '&' + filter_key + '=' + filters[filter_key]
      }
      counter = counter + 1
    }
  }
  const options: AxiosOptionsT = {
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${ token }`
    }
  }
  return await send_general_req({ axiosOptions: options, dispatch, navigate, refresh_token, toast })
}