import Template from '../../components/template/template'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import { useState, useEffect } from 'react'
import styles from './dailyHourList.module.scss'
import { useDispatch, useTypedSelector } from '../../redux'
import DailyHourReportTable from '../../components/tables/dailyHourReportTable/dailyHourReportTable'
import {DailyHoursReportT, DailyReportT, GetHourReportFilterT, GetHoursDailyTalkList, GetHoursTalkListResT, HourReportT} from '../../utils/requests'
import { useNavigate, useLocation } from 'react-router-dom'
import { PATHES} from '../../utils/contants'
import { historyPropertiesExistanceCheck } from '../../utils/utility'
import Tabs from '../../components/tab/tabs'
import Filter from '../../components/filters/filterE'
import {toast} from 'react-toastify'
// import { reconstruct_hourly_report_data_for_graph_v_1 } from '../../utils/others'
import StackedAreaChart from '../../components/charts/stackedAreaChart/stackedAreaChart'
import { ButtonAProps } from '../../components/buttons/buttonA'
import { reconstruct_hourly_report_data_for_graph_v_1 } from '../../utils/others'
const {dailyHourList, container, graphSpot, filterSpot, body, backBtn} = styles

const DailyHourList = (props) => {
  // const [loading, setLoading] = useState(false)
  const [dailyTalkReport, setDailyTalkReport] = useState<DailyHoursReportT[]>([])
   const [hoursData, setHoursData] = useState<HourReportT[]>([])
    const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const location = useLocation()
  const dailyReportData: DailyReportT = historyPropertiesExistanceCheck(location, 'dailyReport', [])
  const from = historyPropertiesExistanceCheck(location, 'createdAtFrom', null)
  const to = historyPropertiesExistanceCheck(location,'createdAtFrom',null)
  const filteredDates = historyPropertiesExistanceCheck(location, 'filteredDates', null)
  // console.log('filterdata',from,to);
  
  // console.log('location --->', location)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedTabId, setSelectedTabId] = useState(0)


  let reconstructedDataForChart = reconstruct_hourly_report_data_for_graph_v_1(hoursData)
   useEffect(() => {
    const fetchData = async (access_token: string) => {
        try {
            // setLoading(true)
            const filters: GetHourReportFilterT = {
              from: from,
              to: to 
            }
            const res = await GetHoursDailyTalkList({token: access_token, filters},{dispatch,navigate,refresh_token: props.refresh_token,toast})
            // setLoading(false)
            if(res.status === 200){
              const resData = res.data as GetHoursTalkListResT
             
              
                
              if (resData.report) {
                  //  console.log('data from hours',resData.report);
                setDailyTalkReport(resData.report)
                setHoursData(resData.report[0].hour_report)
                }
                else {
                  setDailyTalkReport([])
                }
            }
        }
        catch(err){
            // setLoading(false)
        }
    }
    if(userRedux.access_token){
        fetchData(userRedux.access_token)
        // setFirstRender(false)
    }
},[userRedux.access_token])

  const backToCountryTalkListHandler = () => {
    navigate({
      pathname: PATHES.dailyTalkList,
    }, {
      state: {
          filteredDates: filteredDates,
      }
  })
  }

  const buttonsArr: ButtonAProps[] = [{
    children: 'Back',
    type: 'primary',
    onClick: backToCountryTalkListHandler,
    externalClass: backBtn
  }]


  const hourClickHandler = (record: HourReportT) => {
      
    navigate(
      PATHES.talkList,
      {
        state: {
          filteredDates: {
            from: new Date(from),
            to: new Date(to),
            fromHr: record.hour,
            toHr: record.hour
          }, 
          from: PATHES.dailyHourList,
          dailyReport: dailyReportData
        }
      }
    )
  }


  return(
    <Template>
      <div className={dailyHourList}>
        {
        // !loading && langFC && allChars ?
        <MainPanel title='Hour Report' headButtons={buttonsArr}>
          <div className={container} style={{width: '100%'} as React.CSSProperties}>
            <div className={filterSpot}>
              <Filter  
                
              />
            </div>
            <div className={body}>
              <Tabs 
                components={[
                  <DailyHourReportTable data={hoursData} hourClickHandler={hourClickHandler}/>,
                  <StackedAreaChart data={reconstructedDataForChart} title='' xInterval={1}/>
                ]}
                data={[
                  {id: 0, title: 'Table'},
                  {id: 1, title: 'Graph'}
                ]}
                selectedTabId={selectedTabId}
                tabChanger={(tabId) => setSelectedTabId(tabId)}
              />
            </div>
          </div>
        
        </MainPanel>
        }
      </div>
  </Template>  
  )
}



export default DailyHourList