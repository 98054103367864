import styles from './languageCountTable.module.scss'
import {TalkReportsLanguageT} from '../../../utils/models'
const { languageCountTable, headRow, bodyRow, noRecord} = styles

type LanguageMacthedCountTablePropsT = {
    data: TalkReportsLanguageT[]
}



const LanguageCountTable: React.FC<LanguageMacthedCountTablePropsT> = props => {
    const totalCount = props.data.reduce((acc,rec) => rec.count+acc, 0)
    const totalDuration = props.data.reduce((acc,rec) => rec.duration+acc, 0)
    let totalAvgDurationPerCall = 0
    if(totalCount > 0){
        totalAvgDurationPerCall = totalDuration/totalCount
    } 
    const rows = props.data.map((record, index) => {
        let percentageCallCount = 0
        let percentageCallDuration = 0
        let durationPerCall = 0
        if(totalCount > 0){
            percentageCallCount = (record.count/totalCount)*100
        }
        if(totalDuration > 0){
            percentageCallDuration = (record.duration/totalDuration)*100
        }
        if(record.count > 0){
            durationPerCall = record.duration/record.count
        }
        return (
        <tr className={bodyRow} key={`${index}-seqe`}>
            <td>{record.language_name}</td>
            <td>{record.count}</td>
            {/* <td>{Math.round(percentageCallCount)}</td> */}
            <td>{record.duration}</td>
            {/* <td>{Math.round(percentageCallDuration)}</td> */}
            <td>{Math.round(durationPerCall)}</td>
        </tr>
        )
    })
    

    return(
        <>
            <table className={languageCountTable}>
                <thead>
                    <tr className={headRow}>
                        <td>Language</td>
                        <td>Call Count</td>
                        {/* <td>Call Count( percentage) </td> */}
                        <td>Duration</td>
                        {/* <td>Duration(percentage)</td> */}
                        <td>Duration(per call)</td>
                    </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={3}>there is no record</td></tr>
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td style={{background: "skyblue"}}>totals</td>
                        <td style={{background: "lightblue"}}>{totalCount}</td>
                        {/* <td></td> */}
                        <td style={{background: "lightblue"}}>{totalDuration}</td>
                        {/* <td></td> */}
                        <td style={{background: "lightblue"}}>{Math.ceil(totalAvgDurationPerCall)}</td>
                    </tr>
                </tfoot>
            </table>
        </>
    )
}


export default LanguageCountTable