import CanvasJSReact,{CanvasJSOptionsT} from '../../../0-0/canvasJS/canvasjs.react'
import './pieChart.module.scss'



type PieChartPropsT = {
  title: string,
  xLabel: string,
  yLabel: string,
  dataPoints: { label: string, value: number }[]
  sign?:string
}


const PieChart: React.FC<PieChartPropsT> = (props) => {
  // console.log('end point',props.dataPoints);
  
    const CanvasJSChart = CanvasJSReact.CanvasJSChart
  
    // const toggleDataSeries = (e: CanvasJS.ChartEvent) => {
    //     if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
    //       e.dataSeries.visible = false;
    //     } else {
    //       e.dataSeries.visible = true;
    //     }
    // }
    
    const options: CanvasJSOptionsT = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light1", // "light1", "dark1", "dark2"
        title:{
            text: props.title
        },
        axisY: {
          title: props.yLabel
        },
        data: [{
            type: "pie",
            indexLabel: `{label}: {y} ${props.sign ? props.sign : ''} `,		
            startAngle: -90,
            dataPoints: props.dataPoints.map(dp => ({label: dp.label, y: dp.value }))
        }]


    }
    

    // const options = {
    //   //backgroundColor: 'rgb(0, 255, 255,0.1)',  //background-color of graph
    //   animationEnabled: true,
    //   // width specific styles
    //   height:
    //     props.size.width <= 453 ? gt200pxStyles.height : gt453pxStyles.height,
  
    //   axisX: {
    //     // labelFontColor: "white",
    //     lineThickness: 2,
    //     // lineColor: "yellow",
    //     gridThickness: 0,
    //     labelFontFamily:
    //       props.size.width <= 453
    //         ? gt200pxStyles.axisX.fontFamily
    //         : gt453pxStyles.axisX.fontFamily,
    //     labelFontSize:
    //       props.size.width <= 453
    //         ? gt200pxStyles.axisX.fontSize
    //         : gt453pxStyles.axisX.fontSize,
    //     // shows each data obj.label as a label
    //     // labelFormatter: function (e) {
    //     //   return e.label;
    //     // },
  
    //     //shows nothing as a label
    //      labelFormatter: function (e) {
    //       return '';
    //     },
        
    //   },
    //   axisY: {
    //     // title: "قیمت (تومان)",
  
    //     //suffix before each label on y-axis
    //     // suffix: "تومان"
  
    //     //thickness of gridlines of graph --> 0 = noting to show
    //     // labelFontColor: "white",
    //     lineThickness: 2,
    //     // lineColor: "yellow",
    //     gridThickness: 0,
    //     labelFontFamily:
    //       props.size.width <= 453
    //         ? gt200pxStyles.axisY.fontFamily
    //         : gt453pxStyles.axisY.fontFamily,
    //     labelFontSize:
    //       props.size.width <= 453
    //         ? gt200pxStyles.axisY.fontSize
    //         : gt453pxStyles.axisY.fontSize,
    //     //it shows each data obj.value / 1000 + 'K' as a label    
    //     labelFormatter: function (e) {
    //       return `${e.value / 1000}K`;
    //     },
    //   },
    //   legend: {
    //     cursor: "pointer",
    //     fontSize: 16,
    //     itemclick: toggleDataSeries,
    //   },
    //   toolTip: {
    //     //access to other dataEntries would be free
    //     shared: true,
  
    //     //function which is responsible for rendering tooltip element for hovering points in graph
    //     contentFormatter: function (e) {
    //       //we want to show time and date in tooltip
    //       const time = e.entries[0].dataPoint.time
    //       const persianDate = e.entries[0].dataPoint.persianDate
  
    //       var str = [];
    //       str.push(
    //         `<div class='tooltipX'><h1 class='tooltipHeader'>${persianDate.jDay} ${persianDate.jMonthName} ${persianDate.jYear}<span class="tooltipTime">ساعت ${time}</span></h1><hr/>`
    //       );
    //       for (var i = 0; i < e.entries.length; i++) {
    //         str.push(
    //           `<div class="${e.entries[i].dataSeries.name}">${e.entries[i].dataSeries.name}: ${e.entries[i].dataPoint.y}<span class="currency">تومان</span></div>`
    //         );
    //       }
    //       str.push("</div>");
    //       return str.join(" ");
    //     },
    //   },
    //   data: [
    //     {
    //       name: "خرید",
    //       //color of graph
    //       color: 'rgb(41, 179, 90,0.9)',
    //       //type of graph
    //       type: 'area',
    //       yValueFormatString: "#0.##",
    //       //hides the legends
    //       showInLegend: false,
    //       //dataPoints which are rendered through canvas
    //       dataPoints: props.dataPoints.sell,
    //     },
    //     {
    //       name: "فروش",
    //       //color of graph
    //       color: 'rgb(255, 49, 0,0.5)',
    //       type: 'area', 
    //       //types: 
    //       //bar line spline chart column area splineArea stackedArea
    //       //stepArea scatter bubble pie doughnut funnel pyramid waterfall
    //       //stackedBar100 stackedBar rangeBar stackedColumn100 rangeColumn stackedColumn
    //       yValueFormatString: "#0.##",
    //       showInLegend: false,
    //       dataPoints: props.dataPoints.buy,
    //     },
    //   ],
    // };



    return(
      <div>
        <CanvasJSChart options={options} containerProps={undefined} onRef={undefined} />
      </div>
    )
}



export default PieChart