import styles from './languageTotalListTable.module.scss'
import { useState ,useEffect, Fragment} from 'react'
import Filter from '../../filters/filterD'
import { CSVLink} from 'react-csv'
import MobilePagination from '../../pagination/mobilePagination'
import { SelectContent } from '../../formControl/formControl'
import {CharacterT, TalkT, LangStatT} from '../../../utils/models'
import {changeHourOfISODate} from '../../../utils/utility'

import ButtonA from '../../buttons/buttonA'
import {GetStatsList, GetStatsListReqT, GetStatsListFilterT, GetStatsListResT} from '../../../utils/requests'
import { useNavigate } from 'react-router-dom'
import {useDispatch} from '../../../redux'
import {toast} from 'react-toastify'
import {FirstAccountIdFC, SecondAccountIdFC, FirstDeviceIdFC, SecondDeviceIdFC, TalkIdFC} from '../../../utils/formControl'
import { modifyLangStatRecords, reorder_date_fields } from '../../../utils/others'
import Check from '../../formControl/check'

const {languageTotalListTable, filterBox, tableBox, userTable, headRow, bodyRow, noRecord,checkBoxRow , checkBoxSpot, exportBtnSpot, btnRow, totalRow, visible, hidden, checkBox,allDaysRowC} = styles







type LanguageTotalListTablePropsT = {
    langFC: SelectContent
    allChars: CharacterT[]
    access_token: string
    refresh_token: string
}

const LanguageTotalListTable:React.FC<LanguageTotalListTablePropsT> = (props) => {
    const today = new Date()
    const yesterday = new Date(new Date().setDate(today.getDate()-1))
    const [firstRender, setFirstRender] = useState(true)
    const [langStatRecords,setLangStatRecords] = useState<LangStatT[]>([])
    const [langV,setLangV] = useState(props.langFC.options[0].value as number)
    const [showTotals, setShowTotals] = useState(true)
    const [showRecords, setShowRecords] = useState(true)

    const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({from: yesterday, to: today})
    const [loading,setLoading] = useState(false)
    const [confirmedFilterPhrase, setConfirmedFilterPhrase] = useState<GetStatsListFilterT | null>(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()



   //the first fetch after the mounting component
    useEffect(() => {
        const fetchData = async (access_token) => {
            try {
                const todayDateStr = changeHourOfISODate(today, 23, 59, 59)
                const yesterdayDateStr = changeHourOfISODate(yesterday, 0, 0, 0)
                setLoading(true)
                const res = await GetStatsList(
                    {
                        token: access_token, 
                        filters: {
                            from: yesterdayDateStr,
                            to: todayDateStr
                        }
                    },
                    {dispatch,navigate,refresh_token: props.refresh_token,toast}
                )
                setLoading(false)
                if(res.status === 200){
                    const resData = res.data as GetStatsListResT
                    //first we have to modify the data then we have to save inside state
                    const modifiedStats = modifyLangStatRecords(resData.stats)
                    setLangStatRecords(modifiedStats)     
                }
            }
            catch(err){
                setLoading(false)
            }
        }
        //we want to do this just for the first time
        if(props.access_token && firstRender){
            fetchData(props.access_token)
            setFirstRender(false)
        }
    },[props.access_token])


    

    
    //data we need to export as excel 
    let exportable_lang_headers = [
        {key: 'index', label: 'index'},
        {key: 'language', label: 'Language'},
        {key: 'failed_call_count', label: 'Failed Call Count'},
        {key: 'total_call_count', label: 'Total Call Count'},
        {key: 'total_duration', label: 'Total Duration'},
        {key: 'avg_duration', label: 'Avg Duration'},
        {key: 'date', label: 'Date'}]
    let exportable_lang_data: {
        index: number
        language: string
        failed_call_count: number
        total_call_count: number
        total_duration: number
        avg_duration: number
        date: string
    }[] = []
    
    let exportable_daily_data: {
        index: number
        failed_call_count: number
        total_call_count: number
        total_duration: number
        avg_duration: number
        date: string
    }[] = []
    let exportable_daily_headers = [
        {key: 'index', label: 'index'},
        {key: 'failed_call_count', label: 'Failed Call Count'},
        {key: 'total_call_count', label: 'Total Call Count'},
        {key: 'total_duration', label: 'Total Duration'},
        {key: 'avg_duration', label: 'Avg Duration'},
        {key: 'date', label: 'Date'}]
    


    const fetchData = async (access_token: string,filterPhrase: GetStatsListFilterT) => {
        try {
            setLoading(true)
            const res = await GetStatsList({token: access_token,filters: filterPhrase},{dispatch,navigate,refresh_token: props.refresh_token,toast})
            setLoading(false)
            if(res.status === 200){
                const resData = res.data as GetStatsListResT
                //first we have to modify the data then we have to save inside state
                const modifiedStats = modifyLangStatRecords(resData.stats)
                setLangStatRecords(modifiedStats) 
            }
        }
        catch(err){
            setLoading(false)
        }
    }


    const confirmHandler = async() => {
        try {
            if(loading){
                return
            }     
            //first we have to populate input values if exists any
            const filteredPhrase: GetStatsListFilterT = {}
            if(langV !== 0){
                filteredPhrase.language_id = +langV.toString()
            }
            if(createdAt && createdAt.from){
                const from = createdAt.from
                filteredPhrase.from = changeHourOfISODate(from, 0, 0, 0)
            }
            if(createdAt && createdAt.to){
                const to = createdAt.to
                filteredPhrase.to = changeHourOfISODate(to, 23, 59, 59)
            }
            //then we have to correct the order of from and to 
            reorder_date_fields(createdAt, filteredPhrase)
            await fetchData(props.access_token, filteredPhrase)
        }
        catch(err){

        }
    }

    const languageChanger = (e: any, value: number, label: string, object: object) => {
        setLangV(value)
    }


    let allDaysTotalCallCount = 0
    let allDaysTotalDuration = 0
    let allDaysTotalFailedCount = 0

    let lastDayDate: string
    if(langStatRecords.length > 0){
        lastDayDate = langStatRecords[0].date
    } 
    let lastDayTotalCallCount = 0
    let lastDayTotalDuration = 0
    let lastDayTotalFailedCount = 0
    let lastDayIndx = 1

    const rows = langStatRecords.map((langStatRec,index) => {
        const {average, created_at, date, duration, fail, id, language_id, success, updated_at} = langStatRec
        const langName = props.langFC.options.find(lang => lang.value === language_id).label as string
        //to append this lang record to the excel exported data
        exportable_lang_data.push({
            index: index+1,
            avg_duration: average,
            date: date,
            failed_call_count: fail,
            language: langName,
            total_call_count: success,
            total_duration: duration
        })
        let lastDayTotalRow: React.ReactNode = ''
        //first we want to check whether date of record is changed or not
        //if its changed we have to append the totalRow of that date to the table

        //if there is not any other next record in the array or the next record's date is different 
        //we want to show the current accumaulative date values as another row at the bottom of the record's row
        let nextRecord
        if(index+1 <= langStatRecords.length-1){
            nextRecord = langStatRecords[index+1]
        }
        if(!nextRecord || nextRecord.date !== langStatRec.date){
            //here we know that this element is the last element for this specific date
            //first we have to increament lastDay values with the current record values
            lastDayTotalCallCount += langStatRec.success
            lastDayTotalDuration += langStatRec.duration
            lastDayTotalFailedCount += langStatRec.fail
            //we also have to increament allDays values
            allDaysTotalCallCount += lastDayTotalCallCount
            allDaysTotalDuration += lastDayTotalDuration
            allDaysTotalFailedCount += lastDayTotalFailedCount

            //then we have to calculate the avgDuration for the lastDay
            let lastDayAvgDuration = 0
            if(lastDayTotalCallCount > 0){
                lastDayAvgDuration = Math.round(lastDayTotalDuration / lastDayTotalCallCount)
            }
            
            lastDayTotalRow = (
                <tr key={`${index}-res`} className={[totalRow, showTotals ? visible : hidden].join(' ')}>
                    <td>{lastDayIndx}</td>
                    <td>Daily</td>
                    <td>{lastDayTotalFailedCount}</td>
                    <td>{lastDayTotalCallCount}</td>
                    <td>{lastDayTotalDuration}</td>
                    <td>{lastDayAvgDuration}</td>
                    <td>{lastDayDate}</td>
                </tr>
            )
            //then we have to append the total row into exportable obj
            exportable_daily_data.push({
                index: lastDayIndx,
                date: lastDayDate,
                failed_call_count: lastDayTotalFailedCount,
                total_call_count: lastDayTotalCallCount,
                total_duration: lastDayTotalDuration,
                avg_duration: lastDayAvgDuration,
            })
            //then we have to refresh the lastDay values if there is any next element
            if(nextRecord){
                lastDayDate = nextRecord.date
                ++lastDayIndx
                lastDayTotalCallCount = 0
                lastDayTotalDuration = 0
                lastDayTotalFailedCount = 0
            }
        }

        //if the last record day is same as the current record day, we have to increament the values 
        else if(lastDayDate === langStatRec.date){
            lastDayTotalCallCount += langStatRec.success
            lastDayTotalDuration += langStatRec.duration
            lastDayTotalFailedCount += langStatRec.fail
        }
     
        return (
            <Fragment key={`${index}-dsad`}>
                <tr key={`${index}-reb`} className={[bodyRow, showRecords ? visible : hidden].join(' ')}>
                    <td>{index+1}</td>
                    <td>{langName}</td>
                    <td>{fail}</td>
                    <td>{success}</td>
                    <td>{duration}</td>
                    <td>{average}</td>
                    <td>{date}</td>
                </tr>
                {/* if this record is the last record with this day, the component below will contain value */}
                {lastDayTotalRow}
            </Fragment>
        )
    })

    //to sum up all the days and show as the last row in the table
    let allDaysAvgDuration = 0
    if(allDaysTotalCallCount > 0){
        allDaysAvgDuration = Math.round(allDaysTotalDuration / allDaysTotalCallCount)
    }
    const allDaysRow = (
        <tr key={`ressd`} className={[allDaysRowC, showTotals ? visible : hidden].join(' ')}>
            <td colSpan={2}>All Days</td>
            <td>{allDaysTotalFailedCount}</td>
            <td>{allDaysTotalCallCount}</td>
            <td>{allDaysTotalDuration}</td>
            <td>{allDaysAvgDuration}</td>
            <td></td>
        </tr>
    )

    return(
    <div className={languageTotalListTable}>
        <div className={filterBox}>
            <Filter confirmHandler={confirmHandler} loading={loading} //
                langsFC={props.langFC} langValue={langV} setLangValue={languageChanger} //
                createdAt={createdAt} setCreatedAt={setCreatedAt} //
             />
        </div>
        <div className={btnRow}>
            <div className={exportBtnSpot}>
                <CSVLink data={exportable_lang_data} headers={exportable_lang_headers} filename={"test.csv"} >
                    <ButtonA children={`export lang records`} onClick={() => {}} type="secondary"/>
                </CSVLink>
            </div>
            <div className={exportBtnSpot}>
                <CSVLink data={exportable_daily_data} headers={exportable_daily_headers} filename={"test.csv"} >
                    <ButtonA children={`export daily records`} onClick={() => {}} type="secondary"/>
                </CSVLink>
            </div>
        </div>
        <div className={checkBoxRow}>
            <div className={checkBoxSpot}>
                <Check id='show-totals' onChangeHandler={() => setShowTotals(prev => !prev)} value={showTotals} >
                    <div className={checkBox}>show totals</div>
                </Check>
            </div>
            <div className={checkBoxSpot}>
                <Check id='show-langs' onChangeHandler={() => setShowRecords(prev => !prev)} value={showRecords} >
                    <div className={checkBox}>show records</div>
                </Check>
            </div>
        </div>
        <div className={tableBox}>
            <table className={userTable}>
                <thead>
                    <tr className={headRow}>
                        <td style={{minWidth: '2em'} as React.CSSProperties}></td>
                        <td>Language</td>
                        <td>Failed Call Count</td>
                        <td>Total Call Count</td>
                        <td>Total Duration</td>
                        <td>Avg Duration</td>
                        <td>Date</td>
                    </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={10}>there is no record</td></tr>
                    }
                </tbody>
                {
                    // we only want to show the allDays row if there is any record in the langStats
                    langStatRecords?.length > 0 &&
                    <tfoot>
                        {allDaysRow}
                    </tfoot>
                }
            </table>
        </div>
  
    </div>

    )
} 


export default LanguageTotalListTable