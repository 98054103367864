import Template from '../../../components/template/template'
import MainPanel from '../../../components/panels/mainPanel/mainPanel'
import { useState, useEffect } from 'react'
import SpinnerB from '../../../components/spinner/spinnerB'
import styles from './characterCount.module.scss'
import { useTypedSelector, useDispatch } from '../../../redux'
import {SelectContent} from '../../../components/formControl/formControl'
import {LangT, CharacterT, TalkReportsDataT, LanguageT} from '../../../utils/models'
import {langFC_creator} from '../../../utils/formControl'
import { useNavigate } from 'react-router-dom'
import {GetTalkStats, GetTalkStatsFiltersT} from '../../../utils/requests'

import CharacterCount from '../../../components/panels/characterCount/characterCount'

import FilterC from '../../../components/filters/filterC'
import {reconstruct_talk_reports, reorder_date_fields} from '../../../utils/others'
import { changeHourOfISODate} from '../../../utils/utility'
import {toast} from 'react-toastify'

const {talkReports, container, body, filterSpot} = styles

const TalkReportsCharacterCount = () => {
  const today = new Date()
  const yesterday = new Date(today.getTime() - 1 *24*60*60*1000)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [talks, setTalks] = useState<TalkReportsDataT | null>(null)
  const [langFC, setLangFC] = useState<SelectContent | null>(null)
  const [allChars, setAllChars] = useState<CharacterT[] | null>(null)

  const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({from: today, to: today})
  const [langV, setLangV] = useState<number>(0)

  const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const [loading, setLoading] = useState(false)

 
  const languageChanger = (e: any, value: number, label: string, object: object) => {
    setLangV(value)
  }

  //first fetch as soon as component mount
  // useEffect(() => {
  //   const fetchData = async (access_token: string,filterPhrase: GetTalkStatsFiltersT) => {
  //     try {
  //       setLoading(true)
  //       const res = await GetTalkStats({token: access_token,filters: filterPhrase},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
  //       setLoading(false)
  //       if(res.status === 200){
  //           const resData = res.data as TalkReportsDataT
  //           if(resData){
  //               setTalks(resData)
  //           }
  //           else {
  //               setTalks(null)
  //           }
  //       }
  //     }
  //     catch(err){
  //       setLoading(false)
  //     }
  //   }
  //   if(userRedux.access_token && isFirstRender){
  //     fetchData(userRedux.access_token, {
  //       from: changeHourOfISODate(today, 0, 0, 1),
  //       to: changeHourOfISODate(today, 23, 59, 59),  
  //     })
  //     setIsFirstRender(false)
  //   }
  // }
  // ,[userRedux])

  //configs based on roles and status comes from redux for dropdown
  useEffect(() => {
    if(configRedux.allLanguages.length > 0){
      setLangFC(langFC_creator(configRedux.allLanguages))
    }
    if(configRedux.allCharacters.length > 0){
      setAllChars([...configRedux.allCharacters])
    }
 
  },[configRedux])


  let filteredLanguages: LanguageT[] = [] 
  let reconstructedData = []
  if(langFC && talks){
    if(langV !== 0){
      filteredLanguages = langFC.options.filter(lang => lang.value === langV).map(lang => ({name: lang.label.toString(), value: +lang.value}))
    }
    else {
      filteredLanguages = langFC.options.map(lang => ({name: lang.label.toString(), value: +lang.value}))
    }
    reconstructedData = reconstruct_talk_reports(talks.languages, filteredLanguages)
  }
  


  const fetchData = async (access_token: string,filterPhrase: GetTalkStatsFiltersT) => {
    try {
      setLoading(true)
      const res = await GetTalkStats({token: access_token,filters: filterPhrase},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
      setLoading(false)
      if(res.status === 200){
        const resData = res.data as any
        if(resData){
          setTalks(resData)
        }
        else {
          setTalks(null)
        }
      }
    }
    catch(err){
      setLoading(false)
    }
  }


  const confirmHandler = async() => {
    if(loading){
        return
    }
    //we want to validate inputs 
   
    //first we have to populate input values if exists any
    const filteredPhrase: GetTalkStatsFiltersT = {}
  

 
    if(createdAt && createdAt.from){
        const from = createdAt.from
        filteredPhrase.from = changeHourOfISODate(from, 0, 0, 0)
    }
    if(createdAt && createdAt.to){
        const to = createdAt.to
        filteredPhrase.to = changeHourOfISODate(to, 23, 59, 59)
    }
    //then we have to correct the order of from and to 
    reorder_date_fields(createdAt, filteredPhrase)
    await fetchData(userRedux.access_token, filteredPhrase)
  }


  return(
    <Template>
      <div className={talkReports}>
        {
        // !loading && langFC && allChars ?
        // !loading && langFC && allChars && talks ?
          allChars ?
        <MainPanel title="Talks Reports, Character's count" headButtons={[]}>
          <div className={container} style={{width: '100%'} as React.CSSProperties}>
            <div className={filterSpot}>
                <FilterC 
                  confirmHandler={confirmHandler}  langsFC={langFC} langValue={langV} setLangValue={languageChanger} loading={loading}
                  createdAt={createdAt} setCreatedAt={setCreatedAt} 
                  />
            </div>
            <div className={body}>
              <CharacterCount data={reconstructedData} allChars={allChars}/>
            </div>
          </div>
        </MainPanel>
        :
        <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
          <SpinnerB/>
        </div>
        }
      </div>
  </Template>  
  )
}




export default TalkReportsCharacterCount