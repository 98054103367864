import { SelectContent } from '../components/formControl/formControl'
import { ResourcePolicy, ResourceT, ActionT, ResourceWithActions, BackendRoleBasedAccessPolicyT, TalkReportsLanguageT, CharacterT, LangT, LanguageT, TalkLanguageDataT, ReconstructedTalkReportsDataT, TalkReprtDataForCharacterChart, CharacterCountDataT, TalkAnotherT, LangStatT, CountryTalkWithPalCountriesT, CountryTalkDataForChart, CountryTalkT, DailyReportDataForChart } from './models'
import { ConfirmedResourcePolicyT, GetTalkAnotherResT, GetTalkAnotherLangT, DailyReportT, HourReportT } from './requests'
import { changeHourOfISODate } from './utility'




//---------------------------------------------------------------------------------------------
//ex => makeSureOfExistanceOfEntireStateInSessionStorageANDRedux(userReducer,REDUX_ACTIONS.addToMainCart,SESSION_STORAGE_KEYS.user,dispatch)
//we need to write a function to make sure if client removes the localStorage and just we have redux ,copies the redux to the localStorage
//if client removes the redux ,copies the localStorage to the redux
export const makeSureOfExistanceOfEntireStateInSessionStorageANDRedux = (reduxState, reduxActionType, sessionStorageKey, dispatch) => {
  //you have to put it in the componentDidMount to prevent infinit number of updation
  //reduxActionType must be action for replacing the entire reduxState
  //first we have to get the session_State from the session-storage
  let session_state_in_json_form, session_State
  session_state_in_json_form = sessionStorage.getItem(sessionStorageKey)
  if (session_state_in_json_form) {
    session_State = JSON.parse(session_state_in_json_form)
  }
  if (!session_State) {
    //when session_State is empty we want to copy the redux state to it
    return sessionStorage.setItem(sessionStorageKey, JSON.stringify(reduxState))
  }
  else if (session_State) {
    //if session_State exists we have to copy it to the redux store again
    //because it has higher priority in compare to the redux
    return dispatch({ type: reduxActionType, payload: session_State })
  }
  //here we know both states exist
}

//---------------------------------------------------------------------------------------------
//it checks/unchecks all policies based on the wildCard_wildCard check status
export const update_wildCard_wildCart_action_permissions_in_resource_policies = (current_resource_policies: ResourcePolicy[], isPermitted: boolean): ResourcePolicy[] => {
  let updated_version_of_resource_policies: ResourcePolicy[]
  updated_version_of_resource_policies = current_resource_policies.map(resource_policy => {
    return {
      resource: resource_policy.resource,
      action: resource_policy.action,
      is_denied: !isPermitted
    }
  })
  return updated_version_of_resource_policies
}

//---------------------------------------------------------------------------------------------
export enum DirectionT {
  resource = 'resource',
  action = 'action'
}
type ArgTypeX11 = {
  current_resource_policies: ResourcePolicy[]
  direction: DirectionT
  direction_value: number
  isPermitted: boolean
}
export const update_wildCard_action_permissions_in_resource_policies = ({ current_resource_policies, direction_value, direction, isPermitted }: ArgTypeX11): ResourcePolicy[] => {
  //we have to do caclculation in 3 steps
  //1 --> same direction
  //we have to update the incoming_wildCard related policies based on this wildCard check status, whether its checked/unchecked
  //if the incoming_wildCard is unchecked, we have also uncheck the wildCard_wildCard
  //for the rest we have to return unchanged
  //2 --> opposite direction
  //we have to extract all wildCards in the opposite direction(except wildCard_wildCard)
  //update these wildCard based on the fact that if their related policies are all checked or not
  //3 --> wildCard_wildCard
  //we have to extract all related_policies fo the wildCard_wildCard policy
  //update that wildCard_wildCard policy based on the fact that whether other policies are all checked or not
  //then we have to return this updated policies in these 3 different steps
  let updated_version_of_resource_policies: ResourcePolicy[]
  let opp_dir = direction === DirectionT.action ? DirectionT.resource : DirectionT.action
  updated_version_of_resource_policies = current_resource_policies.map(resource_policy => {
    //we change all this wildCard_related_policies
    if (resource_policy[direction] === direction_value) {
      return {
        resource: resource_policy.resource,
        action: resource_policy.action,
        is_denied: !isPermitted
      }
    }
    //if user unchecks a wildCard, then we have to uncheck wildCard_wildCard also
    if (resource_policy[direction] === 1 && isPermitted === false) {
      return {
        resource: resource_policy.resource,
        action: resource_policy.action,
        is_denied: true
      }
    }
    //we have to return other policies unchanged
    else {
      return resource_policy
    }
  })
  //then we have do another calculation in opposite axis to update corresponding wildCards
  const opposite_dir_wildCards_except_wildCard_wildCard = updated_version_of_resource_policies.filter(vd => {
    return vd[direction] === 1 && vd[opp_dir] !== 1
  })
  for (const opp_wildCard of opposite_dir_wildCards_except_wildCard_wildCard) {
    updated_version_of_resource_policies = check_wildCard_if_its_related_policies_are_checked_and_return_policies(updated_version_of_resource_policies, opp_wildCard)
  }
  //after we check wildCards which their policies are checked, then we have to investigate the wildCard_wildCard
  //then we have to check whether wildCard_wildCard related policies have been checked, and we have to check that also as a result
  const wildCard_wildCard = updated_version_of_resource_policies.find(po => po.resource === 1 && po.action === 1)
  updated_version_of_resource_policies = check_wildCard_if_its_related_policies_are_checked_and_return_policies(updated_version_of_resource_policies, wildCard_wildCard)
  return updated_version_of_resource_policies
}
//-------------------------------------------------------------------------------------------
//it checks a wildCard policy if its corresponding policies are checked
const check_wildCard_if_its_related_policies_are_checked_and_return_policies = (incoming_policies: ResourcePolicy[], wildCard_policy: ResourcePolicy): ResourcePolicy[] => {
  let returned_policies = incoming_policies
  const related_policies = extract_wildCard_related_policies_without_wildCard(incoming_policies, wildCard_policy)
  let all_have_been_checked = true
  for (const related_policy_of_current_wildCard of related_policies) {
    all_have_been_checked = all_have_been_checked && !related_policy_of_current_wildCard.is_denied
  }
  if (all_have_been_checked) {
    //if all this wildCard_related_policies are checked, then we have check this wildCard as well
    returned_policies = incoming_policies.map(po => {
      if (po.resource === wildCard_policy.resource && po.action === wildCard_policy.action) {
        return {
          ...po,
          is_denied: false
        }
      }
      else {
        return po
      }
    })
  }
  return returned_policies
}

//---------------------------------------------------------------------------------------------
//it accept resource_policies and an id with the form ==> '{resourceValue}__{actionValue}', and returns related resource_policy obj to that resource and action 
export const extact_action_permission_from_recourcePolicies = (resource_policies: ResourcePolicy[], resource_action_id: string): ResourcePolicy => {
  //resource_action_id ===in form of===> {resourceValue}__{actionValue}
  const splittedId = resource_action_id.split('__')
  const resource_value = +splittedId[0]
  const action_value = +splittedId[1]
  const finded_resource_policy = resource_policies.find(resource_policy => resource_policy.action === action_value && resource_policy.resource === resource_value)
  return finded_resource_policy
}


//---------------------------------------------------------------------------------------------
//it extracts all policies which are changed from their initial values
export const extract_changed_access_policies = (current_access_policies: ResourcePolicy[], initial_access_policies: ResourcePolicy[]): ResourcePolicy[] => {
  const changedPolicies = current_access_policies.filter(currentPolicy => {
    const resource_action_id = `${ currentPolicy.resource }__${ currentPolicy.action }`
    const related_initial_user_policy = extact_action_permission_from_recourcePolicies(initial_access_policies, resource_action_id)
    return currentPolicy.is_denied !== related_initial_user_policy.is_denied
  })
  return changedPolicies
}

//---------------------------------------------------------------------------------------------
//it creates a resourcePolicies arr of from premissions arr
export const resourcePolicies_creator = (permissionsArr: ResourceWithActions[], allResources: ResourceT[], allActions: ActionT[]): ResourcePolicy[] => {
  let populatedArr: ResourcePolicy[] = []
  for (const resource of allResources) {
    //first we have to extract current resource from permissionsArr, it might not exist at all, so resource_related_permission would be 'null'
    let resource_related_permissions: any = null
    resource_related_permissions = permissionsArr?.find(user_resource => resource.value === user_resource.value) ? permissionsArr.find(user_resource => resource.value === user_resource.value) : false
    for (const action of allActions) {
      //if current resource exists in permissions and if it has the current action as well, then the value of is_denied would be true, otherwise it would be false 
      let current_action_is_permitted = resource_related_permissions?.actions?.find(user_action => action.value === user_action.value) ? true : false
      populatedArr.push({
        name: '',
        resource: resource.value,
        action: action.value,
        is_denied: !current_action_is_permitted
      })
    }
  }
  return populatedArr
}



//it creates a resourcePolicies arr of user from his premissions arr
export const resourcePolicies_creator_from_role_policies = (rolePermissions: BackendRoleBasedAccessPolicyT[], allResources: ResourceT[], allActions: ActionT[]): ResourcePolicy[] => {
  let populatedArr: ResourcePolicy[] = []
  //if some permissions has been set for role till now

  for (const resource of allResources) {
    //first we have to extract current resource from role permissionsArr, it might not exist at all, so related_role-resource would be 'null'
    let related_resource_permissions_arr: BackendRoleBasedAccessPolicyT[] = []

    related_resource_permissions_arr = rolePermissions?.filter(policy => resource.value === policy.resource)

    for (const action of allActions) {
      //if current resource exists in role permissions and if it has the current action as well, then the value of is_denied would be true, otherwise it would be false 
      let current_action_is_permitted = related_resource_permissions_arr.find(policy => action.value === policy.action) ? !related_resource_permissions_arr.find(policy => action.value === policy.action).is_denied : false
      populatedArr.push({
        name: '',
        resource: resource.value,
        action: action.value,
        is_denied: !current_action_is_permitted
      })
    }
  }
  return populatedArr
}

//------------------------------------------------------------------------
type ReturnTypeX12 = {
  final_access_policies: ResourcePolicy[]
  confirmed_policies: ResourcePolicy[]
}

//based on backend_api instructions when we are sending wildCard_wildCard policy, we dont have put any other policies except that policies
export const update_local_access_policies_based_on_confirmed_changed_policies_from_the_server_response_v3 = (locally_changed_policies: ResourcePolicy[], server_response_policies: ConfirmedResourcePolicyT[], initial_resource_policies: ResourcePolicy[]): ReturnTypeX12 => {
  //we have to extract confirmed_policies from the server_response
  //we have to loop through locally_changed_policies
  //if locally_changed_policy is a none_wildCard policy
  //if that locally_changed_policy exists in server_repsonse and also is applied, we have to push it into confirmed_policies
  //if locally_changed_policy is a wildCard_policy(we dont send wildCard policies to the server at all, we have to confimrm that wildCard based on its related_policies confirm state)
  //if that locally_changed_policy which is wildCard, is checked
  //if all its related policies in the server_response is applied, we have to put the wildCard into the confirmed_policy as well
  //if that locally_changed_policy which is wildCard, is unchecked
  //if just one of its related policies is applied, and is unchecked now, we have to uncheck this wildCard policy and put it into confirmed_policies
  let confirmed_policies: ResourcePolicy[] = []
  for (const changedPolicy of locally_changed_policies) {
    //if it is a non-wildCard policy, we have to check whether it exists in the server response or not, if it exists then we have to push it into the confirmed_policies
    if (changedPolicy.action !== 1 && changedPolicy.resource !== 1) {
      const related_policy_in_server_response = server_response_policies.find(server_policy => server_policy.action === changedPolicy.action && server_policy.resource === changedPolicy.resource && server_policy.is_denied === changedPolicy.is_denied && server_policy.is_applied)
      if (related_policy_in_server_response) {
        confirmed_policies.push(changedPolicy)
      }
    }
    else {
      //if changedPolicy is wildCard, first we have to find its corresponding none_wildCard_changed_policies
      //if all those wildCard_related_policies have been confirmed, we have to push this wildCard into confirmed_policy as well
      const related_policies_of_this_wildCard = extract_wildCard_related_policies(server_response_policies, changedPolicy) as ConfirmedResourcePolicyT[]
      //if wildCard is checked
      if (!changedPolicy.is_denied) {
        //we have to loop through all related_policies and then if all thses policies are confirmed, then we have confirm wildCard as well
        let all_are_confirmed = true
        for (const related_policy_of_this_wildCard of related_policies_of_this_wildCard) {
          all_are_confirmed = all_are_confirmed && related_policy_of_this_wildCard.is_applied
        }
        //if all related policies are confirmed, then we want to check the wildCard as well 
        if (all_are_confirmed) {
          confirmed_policies.push(changedPolicy)
        }
      }
      //if wildCard is not checked
      else {
        //in this case if just one of the relate_policies is applied and unchecked now, we want to confirm and uncheck the wildCard as well
        const applied_and_related_policies_of_this_wildCard = related_policies_of_this_wildCard.filter(p => p.is_applied)
        if (applied_and_related_policies_of_this_wildCard.length > 0) {
          confirmed_policies.push(changedPolicy)
        }
      }
    }
  }
  //then we have to update these confirmed policies in the initial_access_policies
  let final_access_policies = initial_resource_policies.map(initialPolicy => {
    const related_confirmed_policy = confirmed_policies.find(confirm_policy => confirm_policy.resource === initialPolicy.resource && confirm_policy.action === initialPolicy.action)
    //if this policy is not found in the confirm_policies, it means it is not changed
    if (!related_confirmed_policy) {
      return initialPolicy
    }
    //if this policy is changed and confirmed, we have to update its corresponding is_denied value
    else {
      return {
        ...initialPolicy,
        is_denied: related_confirmed_policy.is_denied
      }
    }
  })
  return { final_access_policies, confirmed_policies }
}


//--------------------------------------------------------------------
const extract_wildCard_related_policies = (input_policies: ResourcePolicy[], wildCard_policy: ResourcePolicy): ResourcePolicy[] => {
  let related_non_wildCard_policies: ResourcePolicy[] = []


  if (wildCard_policy.resource === 1 && wildCard_policy.action !== 1) {

    related_non_wildCard_policies = input_policies.filter(input_policy => input_policy.action === wildCard_policy.action && input_policy.resource !== 1)
  }
  else if (wildCard_policy.resource !== 1 && wildCard_policy.action === 1) {

    related_non_wildCard_policies = input_policies.filter(input_policy => input_policy.resource === wildCard_policy.resource && input_policy.action !== 1)
  }
  else if (wildCard_policy.resource === 1 && wildCard_policy.action === 1) {

    related_non_wildCard_policies = input_policies.filter(input_policy => input_policy.resource !== 1 && input_policy.action !== 1)
  }

  return related_non_wildCard_policies
}
//---------------------------------------------------------------------
const extract_wildCard_related_policies_without_wildCard = (input_policies: ResourcePolicy[], wildCard_policy: ResourcePolicy): ResourcePolicy[] => {
  let related_non_wildCard_policies: ResourcePolicy[] = []


  if (wildCard_policy.resource === 1 && wildCard_policy.action !== 1) {

    related_non_wildCard_policies = input_policies.filter(input_policy => input_policy.action === wildCard_policy.action && input_policy.resource !== 1 && input_policy.action !== 1)
  }
  else if (wildCard_policy.resource !== 1 && wildCard_policy.action === 1) {

    related_non_wildCard_policies = input_policies.filter(input_policy => input_policy.resource === wildCard_policy.resource && input_policy.action !== 1 && input_policy.resource !== 1)
  }
  else if (wildCard_policy.resource === 1 && wildCard_policy.action === 1) {

    related_non_wildCard_policies = input_policies.filter(input_policy => input_policy.resource !== 1 && input_policy.action !== 1)
  }

  return related_non_wildCard_policies
}


//--------------------------------------------------------------------
//it not only updates a none_wildCard_policy in policies arr, but also updates it's corresponding wildCards based on this change 
export const update_action_permission_in_resource_policies_arr_v2 = (resource_policies: ResourcePolicy[], resource_action_id: string, isPermitted: boolean): ResourcePolicy[] => {
  //first we have to update the newely_changed_policy in the resource_policies
  //then
  //if newely_changed_policy is checked, we have also update its corresponding wildCards in the policies according to their_related_ploicies ( based on the fact that this newely_changed_policy is not applied in the policies array yet )
  //if newely change_policy is unchecked, we have to uncheck its corresponding wildCards also
  //and also we must not change any other none_wildCard policies at all

  //resource_action_id ===in form of===> {resourceValue}__{actionValue}
  const splittedId = resource_action_id.split('__')
  const resource_value = +splittedId[0]
  const action_value = +splittedId[1]
  const newely_changed_policy: ResourcePolicy = {
    action: action_value,
    resource: resource_value,
    is_denied: !isPermitted
  }
  const updated_version_of_resource_policies: ResourcePolicy[] = resource_policies.map(resource_policy => {
    //updating the newely_changed_policy itself
    if (resource_policy.action === newely_changed_policy.action && resource_policy.resource === newely_changed_policy.resource) {
      return newely_changed_policy
    }
    //if newely_changed_policy is checked, we have to check its corresponding wildCards, if all those wildCard_related_policies are checked
    if (isPermitted === true) {
      let updated_wildCard_policy: ResourcePolicy
      //action_wildCard policy investigation
      if (resource_policy.action === newely_changed_policy.action && resource_policy.resource === 1) {
        updated_wildCard_policy = find_wildCard_related_policies_and_check_that_wildCard_if_all_policies_are_checked_except_newly_checked_policy(resource_policies, resource_policy, newely_changed_policy, 'action_wildCard')
        return updated_wildCard_policy
      }
      //resource_wildCard policy investigation
      if (resource_policy.resource === newely_changed_policy.resource && resource_policy.action === 1) {
        updated_wildCard_policy = find_wildCard_related_policies_and_check_that_wildCard_if_all_policies_are_checked_except_newly_checked_policy(resource_policies, resource_policy, newely_changed_policy, 'resource_wildCard')
        return updated_wildCard_policy
      }
      //wildCard_wildCard policy investigation
      if (resource_policy.resource === 1 && resource_policy.action === 1) {
        updated_wildCard_policy = find_wildCard_related_policies_and_check_that_wildCard_if_all_policies_are_checked_except_newly_checked_policy(resource_policies, resource_policy, newely_changed_policy, 'wildCard_wildCard')
        return updated_wildCard_policy
      }
    }
    //we want to uncheck newely_changed_policy's corresponding wildCards, if it's unchecked
    else {
      //unchecking resource wildCard
      if (resource_policy.action === newely_changed_policy.action && resource_policy.resource === 1) {
        return {
          resource: resource_policy.resource,
          action: resource_policy.action,
          is_denied: true
        }
      }
      //unchecking action wildCard
      if (resource_policy.resource === newely_changed_policy.resource && resource_policy.action === 1) {
        return {
          resource: resource_policy.resource,
          action: resource_policy.action,
          is_denied: true
        }
      }
      //unchecking wildCard_wildCard
      if (resource_policy.resource === 1 && resource_policy.action === 1) {
        return {
          resource: resource_policy.resource,
          action: resource_policy.action,
          is_denied: true
        }
      }
    }
    //for other none_wildCard policies we return themselves
    return resource_policy
  })
  return updated_version_of_resource_policies
}
//--------------------------------------------------------------------
//it gets a wildCard policy and a newely checked none_wildCard policy which is not applied in the policies arr yet, then finds that wildCard_related_policies, and checks that wildCard if its related policies are checked except that newely_checked_policy
const find_wildCard_related_policies_and_check_that_wildCard_if_all_policies_are_checked_except_newly_checked_policy = (incoming_policies: ResourcePolicy[], wildCard_policy: ResourcePolicy, newely_checked_policy: ResourcePolicy, wildCard_direction: 'wildCard_wildCard' | 'action_wildCard' | 'resource_wildCard'): ResourcePolicy => {
  let related_wildCard_policies_except_newely_checked_policy: ResourcePolicy[]
  if (wildCard_direction === "action_wildCard") {
    related_wildCard_policies_except_newely_checked_policy = incoming_policies.filter(p => (p.action === wildCard_policy.action && p.resource !== 1) && (p.action !== newely_checked_policy.action || p.resource !== newely_checked_policy.resource))
  }
  else if (wildCard_direction === 'resource_wildCard') {
    related_wildCard_policies_except_newely_checked_policy = incoming_policies.filter(p => (p.resource === wildCard_policy.resource && p.action !== 1) && (p.action !== newely_checked_policy.action || p.resource !== newely_checked_policy.resource))
  }
  else if (wildCard_direction === "wildCard_wildCard") {
    related_wildCard_policies_except_newely_checked_policy = incoming_policies.filter(p => (p.resource !== 1 && p.action !== 1) && (p.action !== newely_checked_policy.action || p.resource !== newely_checked_policy.resource))
  }
  let all_item_have_been_checked = true
  for (const related_policy of related_wildCard_policies_except_newely_checked_policy) {
    all_item_have_been_checked = all_item_have_been_checked && related_policy.is_denied === related_wildCard_policies_except_newely_checked_policy[0].is_denied && !related_policy.is_denied
  }
  if (all_item_have_been_checked) {
    return {
      resource: wildCard_policy.resource,
      action: wildCard_policy.action,
      is_denied: false
    }
  }
  else {
    return wildCard_policy
  }
}

//--------------------------------------------------------------------
type Argxc142 = {
  policies: ResourcePolicy[],

}
//it removes all wildCards from the req.body based on backend instructions
export const modify_req_data_for_update_policies_based_on_backend_instruction_v2 = (resource_policies: ResourcePolicy[], allResources: ResourceT[], allActions: ActionT[]): Argxc142 => {
  let req_data: ResourcePolicy[] = []
  const cleared_policies_from_wildCards = remove_all_wildCards_from_the_policies(resource_policies)
  return { policies: cleared_policies_from_wildCards }

}



//------------------------------------------------------------
const remove_all_wildCards_from_the_policies = (incoming_policies: ResourcePolicy[]): ResourcePolicy[] => {
  let cleared_resource_policies_from_wildCards: ResourcePolicy[] = []
  for (const policy of incoming_policies) {
    if (policy.action === 1 || policy.resource === 1) {
      continue
    }
    else {
      cleared_resource_policies_from_wildCards.push(policy)
    }
  }
  return cleared_resource_policies_from_wildCards
}

//----------------------------------------
export const modifierForLangChart = (data: TalkReportsLanguageT[], filteredLangs: LanguageT[] | 'ALL', firstChars: CharacterT[], secondChars: CharacterT[]): TalkLanguageDataT[] => {
  let totalCount = 0
  let totalDuration = 0
  let filteredLangsIds = []
  let filteredLangRecs: TalkReportsLanguageT[] = data
  if (filteredLangs !== 'ALL') {
    filteredLangsIds = filteredLangs.map(lang => lang.value)
    filteredLangRecs = data.filter(lang => filteredLangsIds.includes(lang.language_id))
  }
  const updatedTalkData = filteredLangRecs.map(langRec => {
    let updatedLanguageCount = 0
    let updatedLanguageDuration = 0
    //we have to filter characters based on selected chars
    const firstCharsValues = firstChars.map(ch => ch.value)
    const secondCharsValues = secondChars.map(ch => ch.value)
    //we have to filter out only the characters which exists inside the chars arr
    const filteredFirstChars = langRec.characters.filter(fch => firstCharsValues.includes(fch.character_id))
    //then we have to loop through the filtered characters and filters the second characters
    const filteredResult = filteredFirstChars.map(char => {
      //first we have to filter out just the chars that are selected as second chars
      let updatedCount = 0
      let updatedDuration = 0
      const filteredStats = char.stats.filter(sch => {
        if (secondCharsValues.includes(sch.pal_character_id)) {
          //then we have to sum up all the filtered stats result
          updatedCount = sch.count + updatedCount
          updatedLanguageCount = sch.count + updatedLanguageCount
          totalCount = sch.count + totalCount
          updatedDuration = sch.duration + updatedDuration
          updatedLanguageDuration = sch.duration + updatedLanguageDuration
          totalDuration = sch.duration + totalDuration
          return true
        }
        return false
      })

      return {
        ...char,
        count: updatedCount,
        duration: updatedDuration,
        stats: filteredStats
      }
    })
    return {
      ...langRec,
      duration: updatedLanguageDuration,
      count: updatedLanguageCount,
      characters: filteredResult
    }
  })

  //then we have update percentage values for languages
  return updatedTalkData.map(lang => {
    return {
      language_id: lang.language_id,
      language_name: lang.language_name,
      count: lang.count,
      count_in_percentage: (lang.count / totalCount) * 100,
      duration: lang.duration,
      duration_in_percentage: (lang.duration / totalDuration) * 100,
    }
  })
}

//-------------------------------------------------------
export const filter_talk_reports = (data: TalkReportsLanguageT[], filteredLangs: LanguageT[] | 'ALL', firstChars: CharacterT[], secondChars: CharacterT[]): TalkReportsLanguageT[] => {


  let filteredLangsIds = []
  let filteredLangRecs: TalkReportsLanguageT[] = data
  if (filteredLangs !== 'ALL') {
    filteredLangsIds = filteredLangs.map(lang => lang.value)
    filteredLangRecs = data.filter(lang => filteredLangsIds.includes(lang.language_id))
  }
  const updatedTalkData = filteredLangRecs.map(langRec => {
    let updatedLanguageCount = 0
    let updatedLanguageDuration = 0
    //if the length of firstChars we have to return an empty array


    //we have to filter characters based on selected chars
    const firstCharsValues = firstChars.map(ch => ch.value)
    const secondCharsValues = secondChars.map(ch => ch.value)
    //we have to filter out only the characters which exists inside the chars arr
    const filteredFirstChars = langRec.characters.filter(fch => firstCharsValues.includes(fch.character_id))
    //then we have to loop through the filtered characters and filters the second characters
    const filteredResult = filteredFirstChars.map(char => {
      //first we have to filter out just the chars that are selected as second chars
      let updatedCharCount = 0
      let updatedCharDuration = 0
      const filteredStats = char.stats.filter(sch => {
        if (secondCharsValues.includes(sch.pal_character_id)) {
          //then we have to sum up all the filtered stats result
          updatedCharCount = sch.count + updatedCharCount
          updatedLanguageCount = sch.count + updatedLanguageCount

          updatedCharDuration = sch.duration + updatedCharDuration
          updatedLanguageDuration = sch.duration + updatedLanguageDuration

          return true
        }
        return false
      })

      return {
        ...char,
        count: updatedCharCount,
        duration: updatedCharDuration,
        stats: filteredStats
      }
    })
    return {
      ...langRec,
      duration: updatedLanguageDuration,
      count: updatedLanguageCount,
      characters: filteredResult
    }
  })
  return updatedTalkData
}

//-------------------------------------------------------
//-------------------------------------------------------
export const filter_talk_reports_v2 = (data: TalkReportsLanguageT[], filteredLangs?: LanguageT[] | 'ALL', firstChar?: number, secondChar?: number): TalkReportsLanguageT[] => {
  //firstChar = A, all
  //secondChar = B, all

  // console.log('firstChar -->', firstChar)
  // console.log('secondChar -->', secondChar)

  let filteredLangsIds = []
  let filteredLangRecs: TalkReportsLanguageT[] = data
  if (filteredLangs !== 'ALL') {
    filteredLangsIds = filteredLangs.map(lang => lang.value)
    filteredLangRecs = data.filter(lang => filteredLangsIds.includes(lang.language_id))
  }
  const updatedTalkData = filteredLangRecs.map(langRec => {

    let updatedLanguageCount = 0
    let updatedLanguageDuration = 0
    //we have to filter characters based on selected chars
    let filteredFirstChars = langRec.characters
    if (firstChar !== 0) {
      //we have to filter out only the characters which exists inside the chars arr
      filteredFirstChars = langRec.characters.filter(fch => fch.character_id === firstChar)
    }

    //then we have to loop through the filtered characters and filters the second characters
    const filteredResult = filteredFirstChars.map(char => {
      //first we have to filter out just the chars that are selected as second chars
      let updatedCharCount = 0
      let updatedCharDuration = 0

      let filteredStats = char.stats
      if (secondChar !== 0) {
        //we have to filter out only the characters which exists inside the chars arr
        filteredStats = char.stats.filter(sch => sch.pal_character_id === secondChar)
      }
      filteredStats.forEach(sch => {
        //then we have to sum up all the filtered stats result
        updatedCharCount = sch.count + updatedCharCount
        updatedLanguageCount = sch.count + updatedLanguageCount

        updatedCharDuration = sch.duration + updatedCharDuration
        updatedLanguageDuration = sch.duration + updatedLanguageDuration
      })

      return {
        ...char,
        count: updatedCharCount,
        duration: updatedCharDuration,
        stats: filteredStats
      }
    })
    return {
      ...langRec,
      duration: updatedLanguageDuration,
      count: updatedLanguageCount,
      characters: filteredResult
    }
  })
  return updatedTalkData
}

//-------------------------------------------------------
export const reconstruct_talk_reports = (data: TalkReportsLanguageT[], filteredLangs: LanguageT[]): ReconstructedTalkReportsDataT[] => {
  const returnedValue: ReconstructedTalkReportsDataT[] = []
  const filtered_lang_ids = filteredLangs.map(lang => lang.value)
  for (const langRec of data) {
    //we just want to push all the talk records inside the filtered langs
    if (!filtered_lang_ids.includes(langRec.language_id)) {
      continue
    }
    for (const firstChar of langRec.characters) {
      for (const secondChar of firstChar.stats) {
        returnedValue.push({
          language_id: langRec.language_id,
          language_name: langRec.language_name,
          language_count: langRec.count,
          language_duration: langRec.duration,

          first_character_id: firstChar.character_id,
          first_character_name: firstChar.character_name,
          first_character_count: firstChar.count,
          first_character_duration: firstChar.duration,

          second_character_id: secondChar.pal_character_id,
          second_character_name: secondChar.pal_character_name,
          second_character_count: secondChar.count,
          second_character_duration: secondChar.duration
        })
      }
    }
  }
  return returnedValue
}
//---------------------------------------------------------
export const recontruct_talk_data_for_characters_table = (data: ReconstructedTalkReportsDataT[], allChars: CharacterT[], desiredField: 'count' | 'duration') => {
  //first we have to find out all the unique characters
  const returnedValue: { [first_char_name: string]: { [second_char_name: string]: number } } = {}
  const first_characters_with_their_second_chars: { [first_char_name: string]: { [second_char_name: string]: ReconstructedTalkReportsDataT[] } } = {}
  const first_characters: { [first_char_name: string]: ReconstructedTalkReportsDataT[] } = {}
  //building an object including characters
  for (const char of allChars) {
    first_characters[char.name] = []
  }
  // console.log('first_characters  -->', first_characters)
  //we have to collect all the data with the same first_character_id
  for (const rec of data) {
    first_characters[rec.first_character_name] = [...first_characters[rec.first_character_name], rec]
  }
  //then we have to loop through all the rec of each first-characters and sepetate their second charcters
  for (const first_char_name of Object.keys(first_characters)) {
    const second_characters: { [second_char_name: string]: ReconstructedTalkReportsDataT[] } = {}
    for (const char of allChars) {
      second_characters[char.name] = []
    }

    for (const rec of first_characters[first_char_name]) {
      second_characters[rec.second_character_name] = [...second_characters[rec.second_character_name], rec]
    }

    //here all the second characters are seperated, now we have to calculate total count of them
    const second_chars_total: { [second_char_name: string]: number } = {}
    //then we have to loop through all the second chars and calculate the count
    for (const second_char_name of Object.keys(second_characters)) {
      let second_char_total = 0
      for (const rec of second_characters[second_char_name]) {
        if (desiredField === 'count') {
          second_char_total = second_char_total + rec.second_character_count
        }
        else if (desiredField === 'duration') {
          second_char_total = second_char_total + rec.second_character_duration
        }

      }
      second_chars_total[second_char_name] = second_char_total
    }
    // console.log('second_chars_total  -->', second_chars_total)
    //then we have to update the object appended as value to each first charcter
    returnedValue[first_char_name] = { ...second_chars_total }
  }
  // console.log('returnedValue  -->', returnedValue)
  return returnedValue
}
//-------------------------------------------------------
export const recontruct_talk_data_for_characters_chart = (data: CharacterCountDataT): TalkReprtDataForCharacterChart[] => {
  const returnedValue: TalkReprtDataForCharacterChart[] =
    Object.keys(data).map(char => {
      const secondChar_name_arr = Object.keys(data[char])
      const secondChar_value_arr = Object.values(data[char])
      const total_secondChar_value = secondChar_value_arr.reduce((acc, curr) => acc + curr, 0)
      // console.log('char --->', char)
      // console.log('secondChar_name_arr --->', secondChar_name_arr)
      // console.log('secondChar_value_arr --->', secondChar_value_arr)
      return {
        type: 'stackedBar',
        name: char,
        dataPoints: secondChar_value_arr.map((value, index) => {
          const valueInPercentage = total_secondChar_value > 0 ? (value / total_secondChar_value) : 0
          return { x: -index, y: value, label: secondChar_name_arr[index] }
        })
      }
    })
  // console.log('returnedValue --->', returnedValue)
  return returnedValue
}
//-------------------------------------------------------
//a function for preparing data for both charts which accept percentageValue or raw values based on a field
export const modifier_for_language_duration_charts = (data: TalkReportsLanguageT[], plottedField: 'duration' | 'count', percentage: boolean): { label: string, value: number }[] => {
  let total = 0
  if (percentage) {
    //if the percentage value is desired, we have to calculate the total
    total = data.reduce((acc, langRec) => {
      const val = plottedField === 'duration' ? langRec.duration : plottedField === 'count' ? langRec.count : 0
      return acc + val
    }, 0)
  }
  return data.map(langRec => {
    let value = undefined
    //if percentage value is desired we have to calculate the percentage of each lang
    if (percentage) {
      if (total === 0) {
        value = 0
      }
      else {
        value = plottedField === 'duration' ? (langRec.duration / total) * 100 : plottedField === 'count' ? (langRec.count / total) * 100 : 0
      }
    }
    //if raw value is desired
    else {
      value = plottedField === 'duration' ? langRec.duration : plottedField === 'count' ? langRec.count : 0
    }
    return {
      label: langRec.language_name,
      value
    }
  })
}
//------------------------------------------------------
export const reconstruct_talk_another_data = (data: any): TalkAnotherT[] => {
  const returnedValue: TalkAnotherT[] = []
  for (const lang of data.languages) {
    if (!lang.requests) {
      continue
    }
    for (const req of lang.requests) {
      returnedValue.push({
        language_id: lang.language_id,
        account_id: req.account_id,
        character_id: req.character_id,
        waiting_time: req.waiting_time,
        country: req.country,
        gender: req.gender
      })
    }
  }
  return returnedValue
}
//------------------------------------------------------

export const reconstruct_talk_another_data_v2 = (data: GetTalkAnotherLangT): TalkAnotherT[] | null => {
  const returnedValue: TalkAnotherT[] = []

  if (!data.requests) {
    return null
  }
  for (const req of data.requests) {
    returnedValue.push({
      language_id: data.language_id,
      account_id: req.account_id,
      character_id: req.character_id,
      waiting_time: req.waiting_time,
      country: req.country,
      gender: req.gender
    })
  }
  return returnedValue
}
//-------------------------------------
export const filter_talk_another_data = (data: TalkAnotherT[] | null, selected_lang?: number): TalkAnotherT[] | null => {
  const returnedValue: TalkAnotherT[] = []
  if (!data) {
    return null
  }
  for (const rec of data) {
    let recIsValid = true
    if (selected_lang) {
      recIsValid = recIsValid && rec.language_id === selected_lang
    }
    if (recIsValid) {
      returnedValue.push(rec)
    }
  }
  return returnedValue
}

//---------------------------------------------
export const modifyLangStatRecords = (langStats: LangStatT[]): LangStatT[] => {
  let returnedValue: LangStatT[] = []
  //first we have to remove all the records which has total_call_count === 0
  returnedValue = langStats.filter((stat, index) => stat.success !== 0)
  //then we have to sort based on the date
  returnedValue = returnedValue.sort((firstEl, secondEl) => {
    if (firstEl.date > secondEl.date) {
      return 1
    }
    if (firstEl.date < secondEl.date) {
      return -1
    }
    else {
      return 0
    }
  })

  return returnedValue
}
//----------------------------------------------------
export const reconstruct_country_talk_data_for_graph_v_1 = (
  data: CountryTalkWithPalCountriesT[] | CountryTalkT[]): CountryTalkDataForChart[] => {

  const returnedValue: CountryTalkDataForChart[] = []
  //first we have to loop through all countries
  data.forEach((countryRec, index) => {

    returnedValue.push({
      name: countryRec.country,
      type: 'stackedBar',
      showInLegend: true,
      // xValueFormatString: '',
      // yValueFormatString: '',
      dataPoints: Object.keys(countryRec).filter(prop => {
        return ![
          'total_pings_in_millisecond', 'total_pings_in_millisecond', 'total_duration', 'total_count_non_zero_pings',
          'country', 'characters', 'PalCountries'
        ].includes(prop)

      }).map((propKey, index) => {
        return { x: -index, y: countryRec[propKey], label: propKey }
      })
    })
  })

  return returnedValue
}
//----------------------------------------------------
export const reconstruct_country_talk_data_for_graph_v_2 = (
  data: CountryTalkWithPalCountriesT[] | CountryTalkT[]): CountryTalkDataForChart[] => {

  const returnedValue: CountryTalkDataForChart[] = []
  //first we have to loop through all countries
  const extractedProps: { [propKey: string]: { [countryName: string]: number } } = {}
  data.forEach((countryRec, index) => {
    //extarctedProps : {langA: 12, langB: 13}
    //then we have to loop throughh all props
    Object.keys(countryRec).forEach((propKey, index) => {
      //we dont want to extarct these fields
      if ([
        'total_pings_in_millisecond', 'total_pings_in_millisecond', 'total_duration', 'total_count_non_zero_pings',
        'country', 'characters', 'PalCountries'].includes(propKey)) {
        return
      }
      if (!extractedProps[propKey]) {
        extractedProps[propKey] = {}
      }
      extractedProps[propKey][countryRec.country] = countryRec[propKey]
    })

  })
  //then we have to loop through all props
  Object.keys(extractedProps).forEach((propKey, index) => {
    returnedValue.push({
      name: propKey,
      type: 'stackedBar',
      showInLegend: true,
      xValueFormatString: '',
      yValueFormatString: '',
      dataPoints: Object.keys(extractedProps[propKey]).map((countryName, index) => {
        return {
          x: -index,
          y: extractedProps[propKey][countryName],
          label: countryName
        }
      })
    })
  })

  return returnedValue
}


//----------------------------------------------------
export const reconstruct_country_talk_data_for_graph_v_3 = (
  data: CountryTalkWithPalCountriesT[] | CountryTalkT[]): CountryTalkDataForChart[] => {

  const returnedValue: CountryTalkDataForChart[] = []
  //first we have to loop through all countries


  const extractedProps: { [gender: string]: { [countryName: string]: number } } = {}
  data.forEach((countryRec, index) => {
    //extarctedProps : {langA: 12, langB: 13}
    //then we have to loop throughh all props
    Object.keys(countryRec).forEach((propKey, index) => {
      //then we have to extract the man and wemon based on characters
      const manChars = countryRec.characters.filter((char, index) => [1, 2, 12, 13, 14, 15, 16, 17, 18, 24, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)
      const womanChars = countryRec.characters.filter((char, index) => [3, 4, 5, 6, 7, 8, 9, 10, 11, 23, 25, 26, 27, 29, 31, 32, 33, 35, 37, 39, 41, 43, 45, 47, 49, 51, 53, 55, 57, 59].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)
      if (!extractedProps.man) {
        extractedProps.man = {}
      }
      if (!extractedProps.woman) {
        extractedProps.woman = {}
      }
      extractedProps['man'][countryRec.country] = manChars
      extractedProps['woman'][countryRec.country] = womanChars
    })

  })
  //then we have to loop through all props
  Object.keys(extractedProps).forEach((propKey, index) => {
    returnedValue.push({
      name: propKey,
      type: 'stackedBar',
      showInLegend: true,
      xValueFormatString: '',
      yValueFormatString: '',
      dataPoints: Object.keys(extractedProps[propKey]).map((countryName, index) => {
        return {
          x: -index,
          y: extractedProps[propKey][countryName],
          label: countryName
        }
      })
    })
  })

  return returnedValue
}

//----------------------------------------------------
export const reconstruct_country_talk_data_for_graph_v_4 = (
  data: CountryTalkWithPalCountriesT[] | CountryTalkT[]): CountryTalkDataForChart[] => {

  const returnedValue: CountryTalkDataForChart[] = []
  //first we have to loop through all countries


  const extractedProps: { [totalDuration: string]: { [countryName: string]: number } } = { totalDuration: {} }
  data.forEach((countryRec, index) => {
    //extarctedProps : {langA: 12, langB: 13}
    //then we have to loop through all props
    Object.keys(countryRec).forEach((propKey, index) => {
      //we dont want to extarct these fields
      extractedProps['totalDuration'][countryRec.country] = countryRec.total_duration
    })

  })
  //then we have to loop through all props
  Object.keys(extractedProps).forEach((propKey, index) => {
    returnedValue.push({
      name: propKey,
      type: 'stackedBar',
      showInLegend: true,
      xValueFormatString: '',
      yValueFormatString: '',
      dataPoints: Object.keys(extractedProps[propKey]).map((countryName, index) => {
        return {
          x: -index,
          y: extractedProps[propKey][countryName],
          label: countryName
        }
      })
    })
  })

  return returnedValue
}


//----------------------------------------------------
export const reconstruct_country_talk_data_for_graph_v_5 = (
  data: CountryTalkWithPalCountriesT[] | CountryTalkT[]): CountryTalkDataForChart[] => {

  const returnedValue: CountryTalkDataForChart[] = []
  //first we have to loop through all countries


  const extractedProps: { [totalDuration: string]: { [countryName: string]: number } } = { avgDuration: {} }
  data.forEach((countryRec, index) => {
    //extarctedProps : {langA: 12, langB: 13}
    //then we have to loop through all props
    Object.keys(countryRec).forEach((propKey, index) => {
      //first we have to calculate the avg duration
      const avgDuration = countryRec.successful_talks !== 0 ? countryRec.total_duration / countryRec.successful_talks : 0;
      extractedProps['avgDuration'][countryRec.country] = avgDuration
    })

  })
  //then we have to loop through all props
  Object.keys(extractedProps).forEach((propKey, index) => {
    returnedValue.push({
      name: propKey,
      type: 'stackedBar',
      showInLegend: true,
      xValueFormatString: '',
      yValueFormatString: '',
      dataPoints: Object.keys(extractedProps[propKey]).map((countryName, index) => {
        return {
          x: -index,
          y: extractedProps[propKey][countryName],
          label: countryName
        }
      })
    })
  })

  return returnedValue
}

//------------------------------------------------------------
export type DesiredFieldX123T = 'total_talks' | 'successful_talks' | 'failed_talks' |
  'exited_talks' | 'country' | 'total_duration' |
  'total_pings_in_millisecond' | 'total_count_non_zero_pings' | 'above_five_minutes_talks' |
  'man' | 'woman' | 'avg_duration' | 'man%' | 'woman%' | 0
export const reorder_country_talks = (data: CountryTalkWithPalCountriesT[] | CountryTalkT[], key: DesiredFieldX123T): CountryTalkWithPalCountriesT[] | CountryTalkT[] => {
  let returnedValue: CountryTalkWithPalCountriesT[] | CountryTalkT[] = []
  switch (key) {
    case 'above_five_minutes_talks': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        if (firstEl.above_five_minutes_talks < secondEl.above_five_minutes_talks) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'man': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        const firstElManChars = firstEl.characters.filter((char, index) => [1, 2].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)
        const secondElManChars = secondEl.characters.filter((char, index) => [1, 2].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)

        if (firstElManChars < secondElManChars) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'woman': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        const firstElWomanChars = firstEl.characters.filter((char, index) => [3, 4].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)
        const secondElWomanChars = secondEl.characters.filter((char, index) => [3, 4].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)

        if (firstElWomanChars < secondElWomanChars) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    //'avg_duration', 'man%', 'woman%'
    case 'man%': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        const firstElManChars = firstEl.characters.filter((char, index) => [1, 2].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)
        const firstElTotalChars = firstEl.characters.reduce((acc, rec) => acc + rec.count, 0)
        const firstElManPercentage = Math.round((firstElManChars / firstElTotalChars) * 100)
        const secondElManChars = secondEl.characters.filter((char, index) => [1, 2].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)
        const secondElTotalChars = secondEl.characters.reduce((acc, rec) => acc + rec.count, 0)
        const secondElManPercentage = Math.round((secondElManChars / secondElTotalChars) * 100)
        if (firstElManPercentage < secondElManPercentage) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'woman%': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        const firstElWomanChars = firstEl.characters.filter((char, index) => [3, 4].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)
        const firstElTotalChars = firstEl.characters.reduce((acc, rec) => acc + rec.count, 0)
        const firstElWomanPercentage = Math.round((firstElWomanChars / firstElTotalChars) * 100)
        const secondElWomanChars = secondEl.characters.filter((char, index) => [3, 4].includes(char.id)).reduce((acc, rec) => acc + rec.count, 0)
        const secondElTotalChars = secondEl.characters.reduce((acc, rec) => acc + rec.count, 0)
        const secondElWomanPercentage = Math.round((secondElWomanChars / secondElTotalChars) * 100)
        if (firstElWomanPercentage < secondElWomanPercentage) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'exited_talks': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        if (firstEl.exited_talks < secondEl.exited_talks) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'failed_talks': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        if (firstEl.failed_talks < secondEl.failed_talks) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'successful_talks': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        if (firstEl.successful_talks < secondEl.successful_talks) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'total_count_non_zero_pings': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        if (firstEl.total_count_non_zero_pings < secondEl.total_count_non_zero_pings) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'avg_duration': {
      // const avgDuration = Math.round((total_duration / successful_talks))
      return returnedValue = data.sort((firstEl, secondEl) => {
        const firstEl_avgDuration = Math.round((firstEl.total_duration / firstEl.successful_talks))
        const secondEl_avgDuration = Math.round((secondEl.total_duration / secondEl.successful_talks))
        if (firstEl_avgDuration < secondEl_avgDuration) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'total_duration': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        if (firstEl.total_duration < secondEl.total_duration) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'total_pings_in_millisecond': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        if (firstEl.total_pings_in_millisecond < secondEl.total_pings_in_millisecond) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    case 'total_talks': {
      return returnedValue = data.sort((firstEl, secondEl) => {
        if (firstEl.total_talks < secondEl.total_talks) {
          return 1
        }
        else {
          return -1
        }
      })
    }
    default: {
      return data
    }
  }
}
//-------------------------------------------
//reorder the from and to in dates field
export const reorder_date_fields = (dates: { from: Date | null, to: Date | null }, filteredPhrase: { from?: string; to?: string }) => {
  if (dates.from && dates.to) {
    if (dates.from.getTime() > dates.to.getTime()) {
      filteredPhrase.to = changeHourOfISODate(dates.from, 23, 59, 59)
      filteredPhrase.from = changeHourOfISODate(dates.to, 0, 0, 0)
    }
    else {
      // in this case order is correct
    }
  }
  else {
    //in this case order is correct
  }

}
//----------------------------------------------------
//reorder the from and to in dates field
export const rearrange_date_fields = (dates: { from: Date | null, to: Date | null }): { from: Date | null, to: Date | null } => {
  let returnedValue: { from: Date | null, to: Date | null } = { from: dates.from, to: dates.to }
  if (returnedValue.from && returnedValue.to) {
    if (dates.from.getTime() > dates.to.getTime()) {
      returnedValue.to = dates.from
      returnedValue.from = dates.to
    }
  }

  return returnedValue
}

//----------------------------------------------------
export const reconstruct_daily_report_data_for_graph_v_1 = (
  data: DailyReportT[]): DailyReportDataForChart[] => {

  const returnedValue: DailyReportDataForChart[] = []
  //first we have to loop through all countries

  const extractedProps: { [propKey: string]: { [date: string]: number } } = {}
  //then we have to loop through all props
  data.forEach((dateRec, index) => {
    //extarctedProps : {langA: 12, langB: 13}
    //then we have to loop throughh all props
    Object.keys(dateRec).forEach((propKey, index) => {
      //we dont want to extarct these fields
      if (!['total_day_accounts'].includes(propKey)) {
        return
      }
      if (!extractedProps[propKey]) {
        extractedProps[propKey] = {}
      }
      extractedProps[propKey][dateRec.date] = dateRec[propKey]
    })

  })

  Object.keys(extractedProps).forEach((propKey, index) => {
    returnedValue.push({
      name: propKey,
      type: 'column',
      showInLegend: true,
      xValueFormatString: '',
      yValueFormatString: '',
      dataPoints: Object.keys(extractedProps[propKey]).map((date, index) => {
        return {
          x: -index,
          y: extractedProps[propKey][date],
          label: new Date(date).toLocaleDateString('en')
        }
      })
    })
  })
  return returnedValue
}


//----------------------------------------------------
// new talks (successful & total)
export const reconstruct_daily_report_data_for_graph_v_2 = (
  data: DailyReportT[]): DailyReportDataForChart[] => {

  const returnedValue: DailyReportDataForChart[] = []
  //first we have to loop through all countries
  const extractedProps: { [propKey: string]: { [date: string]: number } } = {}
  data.forEach((dateRec, index) => {
    //extarctedProps : {langA: 12, langB: 13}
    //then we have to loop throughh all props
    Object.keys(dateRec).forEach((propKey, index) => {
      //we dont want to extarct these fields
      if (![
        'total_day_talks', 'day_successful_talks'].includes(propKey)) {
        return
      }
      if (!extractedProps[propKey]) {
        extractedProps[propKey] = {}
      }
      extractedProps[propKey][dateRec.date] = dateRec[propKey]
    })

  })
  //then we have to loop through all props
  Object.keys(extractedProps).forEach((propKey, index) => {
    returnedValue.push({
      name: propKey,
      type: 'column',
      showInLegend: true,
      xValueFormatString: '',
      yValueFormatString: '',
      dataPoints: Object.keys(extractedProps[propKey]).map((date, index) => {
        return {
          x: -index,
          y: extractedProps[propKey][date],
          label: new Date(date).toLocaleDateString('en')
        }
      })
    })
  })

  return returnedValue
}


//----------------------------------------------------
export const reconstruct_daily_report_data_for_graph_v_3 = (
  data: DailyReportT[], first_culomns_data: string, second_colums_data: string): DailyReportDataForChart[] => {

  const returnedValue: DailyReportDataForChart[] = []
  //first we have to loop through all countries
  const extractedProps: { [propKey: string]: { [date: string]: number } } = {}
  data.forEach((dateRec, index) => {
    //extarctedProps : {langA: 12, langB: 13}
    //then we have to loop throughh all props
    Object.keys(dateRec).forEach((propKey, index) => {
      //we dont want to extarct these fields
      if (![first_culomns_data, second_colums_data].includes(propKey)) {
        return
      }
      if (!extractedProps[propKey]) {
        extractedProps[propKey] = {}
      }
      extractedProps[propKey][dateRec.date] = dateRec[propKey]
    })

  })
  //then we have to loop through all props
  Object.keys(extractedProps).forEach((propKey, index) => {
    returnedValue.push({
      name: propKey,
      type: 'column',
      showInLegend: true,
      xValueFormatString: '',
      yValueFormatString: '',
      dataPoints: Object.keys(extractedProps[propKey]).map((date, index) => {
        return {
          x: -index,
          y: extractedProps[propKey][date],
          label: new Date(date).toLocaleDateString('en')
        }
      })
    })
  })

  return returnedValue
}


// ----------------------------------------------------
export const reconstruct_daily_report_data_for_graph_v_4 = (
  data: DailyReportT[]): DailyReportDataForChart[] => {

  const returnedValue: DailyReportDataForChart[] = []
  //first we have to loop through all countries
  const extractedProps: { [propKey: string]: { [date: string]: number } } = {}
  data.forEach((dateRec, index) => {
    //extarctedProps : {langA: 12, langB: 13}
    //then we have to loop throughh all props
    Object.keys(dateRec).forEach((propKey, index) => {
      //we dont want to extarct these fields
      if (![
        'five', 'fifteen', 'thirty', 'sixty', 'hundred'].includes(propKey)) {
        return
      }
      if (!extractedProps[propKey]) {
        extractedProps[propKey] = {}
      }
      extractedProps[propKey][dateRec.date] = dateRec[propKey]
    })

  })
  //then we have to loop through all props
  Object.keys(extractedProps).forEach((propKey, index) => {
    returnedValue.push({
      name: propKey,
      type: 'column',
      showInLegend: true,
      xValueFormatString: '',
      yValueFormatString: '',
      dataPoints: Object.keys(extractedProps[propKey]).map((date, index) => {
        return {
          x: -index,
          y: extractedProps[propKey][date],
          label: new Date(date).toLocaleDateString('en')
        }
      })
    })
  })

  return returnedValue
}


//----------------------------------------------------
export const reconstruct_hourly_report_data_for_graph_v_1 = (


  data: HourReportT[]): DailyReportDataForChart[] => {
  const returnedValue: DailyReportDataForChart[] = []
  //first we have to loop through all countries
  const extractedProps: { [propKey: string]: { [hour: string]: number } } = {}
  data.forEach((hourRec, index) => {
    //extarctedProps : {langA: 12, langB: 13}
    //then we have to loop throughh all props

    Object.keys(hourRec).forEach((propKey, index) => {
      //we dont want to extarct these fields
      if (['hour'].includes(propKey)) {
        return
      }
      if (!extractedProps[propKey]) {
        extractedProps[propKey] = {}
      }
      extractedProps[propKey][hourRec.hour] = hourRec[propKey]
    })


  })
  //then we have to loop through all props
  Object.keys(extractedProps).forEach((propKey, index) => {
    returnedValue.push({
      name: propKey,
      type: 'column',
      showInLegend: true,
      xValueFormatString: '',
      yValueFormatString: '',
      dataPoints: Object.keys(extractedProps[propKey]).map((hour, index) => {
        return {
          x: index,
          y: extractedProps[propKey][hour],
          label: hour
        }
      })
    })
  })

  return returnedValue
}
//--------------------prepre line ghraph data---------------
export const prepare_data_for_NewAccount_lineChart = (dailyReport) => {
  let data_for_daily_line_chart = []
  dailyReport.slice().reverse().map(item => {
    const extractObject = { label: new Date(item.date).toLocaleDateString('en'), value: item.total_day_accounts }
    data_for_daily_line_chart = [...data_for_daily_line_chart, extractObject]
  })
  return data_for_daily_line_chart
}
//--------------------prepre line ghraph data with multi dataset---------------
export const prepare_data_for_talk_MultilineChartDataset = (dailyReport) => {

  let data_for_talk_line_chart = []
  let total_talk = []
  let total_talk_successfull = []

  dailyReport.slice().reverse().map(item => {
    const extractObject = { label: new Date(item.date).toLocaleDateString('en'), value: item.total_day_talks, name: 'Total Daily Talks' }
    total_talk = [...total_talk, extractObject]
  })
  dailyReport.slice().reverse().map(item => {
    const extractObject = { label: new Date(item.date).toLocaleDateString('en'), value: item.day_successful_talks, name: 'Total Daily Successful Talks' }
    total_talk_successfull = [...total_talk_successfull, extractObject]
  })
  data_for_talk_line_chart = [...data_for_talk_line_chart, total_talk, total_talk_successfull]
  // console.log('data_for_talk_line_chart',data_for_talk_line_chart);

  return data_for_talk_line_chart
}
//--------------------prepre line ghraph data with multi dataset---------------
export const prepare_data_for_talk_newAccount_dailyTalks = (dailyReport) => {
  let data_for_talk_line_chart = []
  let total_New_Account = []
  let total_talk = []

  dailyReport.slice().reverse().map(item => {
    const extractObject = { label: new Date(item.date).toLocaleDateString('en'), value: item.total_day_accounts, name: 'Total Daily New Accounts' }
    total_New_Account = [...total_New_Account, extractObject]
  })
  dailyReport.slice().reverse().map(item => {
    const extractObject = { label: new Date(item.date).toLocaleDateString('en'), value: item.total_day_talks, name: 'Total Daily Talks' }
    total_talk = [...total_talk, extractObject]
  })
  data_for_talk_line_chart = [...data_for_talk_line_chart, total_New_Account, total_talk]

  return data_for_talk_line_chart
}
//--------------------------------------split date---------------------------------------------
export const dateSplit = (data: string) => {
  const inputString = data;
  const delimiter = "T"; // Space character as the delimiter
  const arrayOfStrings = inputString.split(delimiter);
  return arrayOfStrings
}
//--------------------------------------------------------------------------------------------------
export const prepare_data_for_talk_MultilineChartDataset_newAccounts_newPals = (dailyReport) => {

  let data_for_talk_line_chart = []
  let new_account = []
  let new_account_talks = []

  dailyReport.slice().reverse().map(item => {
    const extractObject = { label: new Date(item.date).toLocaleDateString('en'), value: item.total_day_accounts, name: 'Daily New Accounts' }
    new_account = [...new_account, extractObject]
  })
  dailyReport.slice().reverse().map(item => {
    const extractObject = { label: new Date(item.date).toLocaleDateString('en'), value: item.new_day_pals, name: 'New Account Talks' }
    new_account_talks = [...new_account_talks, extractObject]
  })
  data_for_talk_line_chart = [...data_for_talk_line_chart, new_account, new_account_talks]
  // console.log('data_for_talk_line_chart', data_for_talk_line_chart);

  return data_for_talk_line_chart
}
//--------------------------------------------------------------------------------------------------
export const prepare_data_for_talk_MultilineChartDataset_TalkStatus_chart1 = (talkStatus) => {

  let data_for_talk_line_chart = []
  let noStart_percent = []
  let exited_percent = []
  let finish_percent = []
  let in_progress_percent = []

  talkStatus.slice().map(item => {
    const extractObject = { label: item.fiveMinutes, value: item.notStartPercent, name: 'noStart percent' }
    noStart_percent = [...noStart_percent, extractObject]
  })
  talkStatus.slice().map(item => {
    const extractObject = { label: item.fiveMinutes, value: item.exitedPercent, name: 'Exited Percent' }
    exited_percent = [...exited_percent, extractObject]
  })
  talkStatus.slice().map(item => {
    const extractObject = { label: item.fiveMinutes, value: item.FinishPercent, name: 'finish Percent' }
    finish_percent = [...finish_percent, extractObject]
  })
  talkStatus.slice().map(item => {
    const extractObject = { label: item.fiveMinutes, value: item.InProgressPercent, name: 'in progress Percent' }
    in_progress_percent = [...in_progress_percent, extractObject]
  })
  data_for_talk_line_chart = [...data_for_talk_line_chart, in_progress_percent, noStart_percent, exited_percent, finish_percent]
  // console.log('data_for_talk_line_chart_talk_list', data_for_talk_line_chart);

  return data_for_talk_line_chart
}
//--------------------------------------------------------------------------------------------------
export const prepare_data_for_talk_MultilineChartDataset_TalkStatus_chart2 = (talkStatus) => {
  let data_for_talk_line_chart = []
  let noStart_count = []
  let exited_count = []
  let inProgress_count = []
  let finish_count = []
  let total_count = []

  talkStatus.slice().map(item => {
    const extractObject = { label: item.fiveMinutes, value: item.notStartCount, name: 'noStart count' }
    noStart_count = [...noStart_count, extractObject]
  })
  talkStatus.slice().map(item => {
    const extractObject = { label: item.fiveMinutes, value: item.ExitedCount, name: 'Exited count' }
    exited_count = [...exited_count, extractObject]
  })
  talkStatus.slice().map(item => {
    const extractObject = { label: item.fiveMinutes, value: item.FinishCount, name: 'finish count' }
    finish_count = [...finish_count, extractObject]
  })
  talkStatus.slice().map(item => {
    const extractObject = { label: item.fiveMinutes, value: item.InProgressPercent, name: 'in progress count' }
    inProgress_count = [...inProgress_count, extractObject]
  })
  talkStatus.slice().map(item => {
    const extractObject = { label: item.fiveMinutes, value: item.count, name: 'Total count' }
    total_count = [...total_count, extractObject]
  })
  data_for_talk_line_chart = [...data_for_talk_line_chart, inProgress_count, noStart_count, exited_count, finish_count, total_count]
  // console.log('data_for_talk_line_chart_talk_list', data_for_talk_line_chart);

  return data_for_talk_line_chart
}
//--------------------------------------
export function prepare_data_for_talkStep_chart(response, step) {
  console.log('response in function:response', response);
  console.log('response in function:step', step);

  const extractedData = {};
  if (response.report) {
    response.report.forEach(item => {
      const { TimeRangeEnd, data } = item;

      Object.keys(data).forEach(key => {
        if (!extractedData[TimeRangeEnd]) {
          extractedData[TimeRangeEnd] = 0;
        }
        if (data[key].hasOwnProperty(step)) {
          extractedData[TimeRangeEnd] += data[key][step];
        }
      });
    });

    // Format the extracted data as an array of objects
    const formattedData = Object.keys(extractedData).map(timeRangeEnd => ({
      label: timeRangeEnd,
      value: extractedData[timeRangeEnd],
      name: step
    }));

    return formattedData;
  } else {
    return []
  }

}
//-----------------
function prepare_data_for_talkStep_chart2(response, step, mediaString) {
  const extractedData = {};
  if (response.report) {
    response.report.forEach(item => {
      const { TimeRangeEnd, data } = item;
      // Check if the media string is provided and filter data accordingly
      const filteredData = mediaString ? { [mediaString]: data[mediaString] } : data;
      Object.keys(filteredData).forEach(key => {
        if (!extractedData[TimeRangeEnd]) {
          extractedData[TimeRangeEnd] = 0;
        }
        if (filteredData[key]?.hasOwnProperty(step)) {
          extractedData[TimeRangeEnd] += filteredData[key][step];
        }
      });
    });

    // Format the extracted data as an array of objects
    const formattedData = Object.keys(extractedData).map(timeRangeEnd => ({
      label: timeRangeEnd,
      value: extractedData[timeRangeEnd],
      name: step
    }));

    return formattedData;
  } else {
    return [];
  }
}
//--------------------
export const prepare_data_for_talk_MultilineChartDataset_TalkStep = (talkStep, media) => {
  const stepArray = [
    "COMPLETED",
    "NOT_GET_CREATE_ROOM_RESPONSE",
    "NOT_GET_JOIN_RESPONSE",
    "NOT_GET_PRODUCE_DATA_RESPONSE",
    "WITHOUT_STEP",
    "NOT_GET_CONNECT_TRANSPORT_RESPONSE",
    "NOT_GET_DO_CONSUME",
    "CONNECT_WS",
    "LOAD_BALANCER"
  ]
  const newArray = stepArray.map(function (item) {
    return prepare_data_for_talkStep_chart2(talkStep, item, media);
  })
  return newArray
}
//-----------------------------
type Item = {
  id: number;
  notStartedPercent: number;
  inProgressPercent: number;
  finishedPercent: number;
  exitedPercent: number;
  p1_media: string;
  p2_media: string;
};

type Dataset = {
  title: string;
  dataPoints: { label: string, value: number }[];
};

// Function to transform response data into datasets
export const transformDataToDatasets = (data: Item[]): Dataset[] => {
  return data.map(item => ({
    title: `${ item.p1_media ? item.p1_media : "---" } VS  ${ item.p2_media ? item.p2_media : "---" }`,
    dataPoints: [
      { label: "Not Started", value: parseFloat(item.notStartedPercent.toFixed(2)), color: '#f46e6e' },
      { label: "In Progress", value: parseFloat(item.inProgressPercent.toFixed(2)), color: '#06dae5' },
      { label: "Finished", value: parseFloat(item.finishedPercent.toFixed(2)), color: '#39e55e' },
      { label: "Exited", value: parseFloat(item.exitedPercent.toFixed(2)), color: 'yellow' }
    ]
  }));
};