import React,{useState} from 'react'
import styles from './userEditPolicies.module.scss'
import {ConfigsReduxT} from '../../../redux/index'
import {BackendCompleteUserDocT, ResourcePolicy} from '../../../utils/models'
import Check from '../../formControl/check'
import ButtonA from '../../buttons/buttonA'
import {adminEditUserAccessPolicies, AdminEditUserAccessPoliciesReqT, AdminEditUserAccessPoliciesResT } from '../../../utils/requests'
import {toast} from 'react-toastify'
import {PoliciesUpdatorArgT} from '../../../pages/userEdit/userEdit'
import {
    update_wildCard_wildCart_action_permissions_in_resource_policies,
    update_wildCard_action_permissions_in_resource_policies,
    extact_action_permission_from_recourcePolicies,
    extract_changed_access_policies,
    update_action_permission_in_resource_policies_arr_v2,
    modify_req_data_for_update_policies_based_on_backend_instruction_v2,
    update_local_access_policies_based_on_confirmed_changed_policies_from_the_server_response_v3,
    resourcePolicies_creator,
    DirectionT
} from '../../../utils/others'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from '../../../redux/index'


const {tableContainer, userEditPolicies, confirmBtn , changed, confirmed} = styles


type UserEditPoliciesProps = {
    configs: ConfigsReduxT
    userData: BackendCompleteUserDocT
    access_token: string
    refresh_token: string
    userDataUpdater: (arg: PoliciesUpdatorArgT) => void
}

const UserEditPolicies: React.FC<UserEditPoliciesProps> = props => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [initialResourcePolicies, setInitialResourcePolicies] = useState<ResourcePolicy[]>(resourcePolicies_creator(props.userData.permissions,props.configs.allResources,props.configs.allActions))
    const [currentResourcePolicies, setCurrentResourcePolicies] = useState<ResourcePolicy[]>(resourcePolicies_creator(props.userData.permissions,props.configs.allResources,props.configs.allActions))
    const [loading, setLoading] = useState(false)
    const [confirmedAccessPolicies, setConfirmedAccessPolicies] = useState<ResourcePolicy[]>([])

    //it updates a none_wildCard policy
    const none_wildCard_currentResourcePoliciesChanger = (resource_action_id: string,isPermitted: boolean) => {
        setCurrentResourcePolicies(prev => update_action_permission_in_resource_policies_arr_v2(prev,resource_action_id,isPermitted))
    }

    //it updates the wildCard_wildCard policy
    const wildCard_wildCard_currentResourcePoliciesChanger = (isPermitted: boolean) => {
        console.log('wildCard_wildCard_currentResourcePoliciesChanger called')
        setCurrentResourcePolicies(prev => update_wildCard_wildCart_action_permissions_in_resource_policies(prev, isPermitted))
    }
    //it updates a resource_wildCard policy
    const resource_wildCard_currentResourcePoliciesChanger = (action_value: number,isPermitted: boolean) => {
        console.log('resource_wildCard_currentResourcePoliciesChanger called')
        setCurrentResourcePolicies(prev => update_wildCard_action_permissions_in_resource_policies(
            {current_resource_policies: prev,direction: DirectionT.action,direction_value: action_value,isPermitted}))
    }
    //it updates a action_wildCard policy
    const action_wildCard_currentResourcePoliciesChanger = (resource_value: number,isPermitted: boolean) => {
        console.log('action_wildCard_currentResourcePoliciesChanger called')
        setCurrentResourcePolicies(prev => update_wildCard_action_permissions_in_resource_policies(
            {current_resource_policies: prev,direction: DirectionT.resource,direction_value: resource_value,isPermitted}))
    }

    //1- first we have to build our table head
    //first we sort all actions in our desired manner then we create their corresponding tableCell for showing as head of columns
    const sortedActions = props.configs.allActions.sort((firstEl,secondEL) => {
        if(firstEl.value < secondEL.value) { return 1; }
    	if(firstEl.value > secondEL.value) { return -1; }
    	return 0;
    })
    const allActionNameCellsInHeadOfTable = sortedActions.sort().map((action,index) => {
        const desc = action.value !== 1 ? action.name : 'All'
        //we dont want to show name of '*' for action => {name: '*', value: 1}
        return (<td key={`${index}-sahg`}>{desc}</td>)
    })

    //2-then we have to build out table rows
    //we have to loop through all resources to create table rows
    const allTableRows = props.configs.allResources.sort((firstEl,secondEL) => {
        if(firstEl.value < secondEL.value) { return -1; }
    	if(firstEl.value > secondEL.value) { return 1; }
    	return 0;
    }).map((resource, index) => {
        return(
            <tr key={`${index}-jsdw`}>
                <td>{index+1}</td>
                <td>{resource.value === 1 ? 'All' : resource.name}</td>  {/* we want to show 'All' instead of '*' resource */}
                {sortedActions.map((action,i) => {
                    //if current resource exists in the user policies, we have to render its corresponding actions, otherwise we have to show this resource actions unchecked
                    const resource_action_id = `${resource.value}__${action.value}`
                    const related_user_action_permission = extact_action_permission_from_recourcePolicies(currentResourcePolicies,resource_action_id)
                    const related_initial_user_action_permission = extact_action_permission_from_recourcePolicies(initialResourcePolicies,resource_action_id)
                    const related_confirmed_action_permission = extact_action_permission_from_recourcePolicies(confirmedAccessPolicies,resource_action_id)
                    return (
                    <td key={resource_action_id} onClick={() => console.log(`resource.value: ${resource.value}    action.value:${action.value}`)}
                        className={[
                            //if current_policy is changed from its initial value, we want to set a specific style for it
                            related_initial_user_action_permission.is_denied !== related_user_action_permission.is_denied ? changed :
                            //if current_policy's change is applied to the server, we want to set a specific style for it
                            related_confirmed_action_permission ? confirmed : '' ].join(' ')}>
                        <Check id={resource_action_id} value={!related_user_action_permission.is_denied} 
                            onChangeHandler={
                                //if current_policy is the wildCard_wildCard policy
                                resource.value === 1 && action.value === 1 ? (value: boolean) => wildCard_wildCard_currentResourcePoliciesChanger(value) :
                                //if current_policy is the resource_wildCard policy
                                resource.value === 1 && action.value !== 1 ? (value: boolean) => resource_wildCard_currentResourcePoliciesChanger(action.value, value) :
                                //if current_policy is the action_wildCard policy
                                action.value === 1 && resource.value !== 1 ? (value: boolean) => action_wildCard_currentResourcePoliciesChanger(resource.value, value) :
                                //if current_policy is the none_wildCard policy
                                (value: boolean) => none_wildCard_currentResourcePoliciesChanger(resource_action_id, value)
                            }/>
                    </td>
                    )
                })}
            </tr>
        )
    })

  

    //it sends all updated access_policies for updating in the server
    const confirmHandler = async (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        //first we have to extract all changed policies from the the currentResourcePolicies
        const changedPolicies = extract_changed_access_policies(currentResourcePolicies, initialResourcePolicies)
        //then we have to change this data to respect the req body form
        const {policies:reduced_size_policies} = modify_req_data_for_update_policies_based_on_backend_instruction_v2(changedPolicies, props.configs.allResources, props.configs.allActions)
   
        const reqBody: AdminEditUserAccessPoliciesReqT = {
            user_id: props.userData.user.id,
            access_policies: reduced_size_policies
        }
        try {
            setLoading(true)
            const res = await adminEditUserAccessPolicies(props.access_token, reqBody, {dispatch,navigate,refresh_token: props.refresh_token,toast})
            setLoading(false)
            if(res.status === 200){
                toast.success('policies were updated successfully')
                const server_confirmed_policies = (res.data as AdminEditUserAccessPoliciesResT).access_policies
                //then we have to find the final confirmed policies from the backend res and our initial_policies
                const {final_access_policies, confirmed_policies} = update_local_access_policies_based_on_confirmed_changed_policies_from_the_server_response_v3(changedPolicies,server_confirmed_policies,initialResourcePolicies)
                //then we have set confirmed policies to change the styles
                setConfirmedAccessPolicies(confirmed_policies)
                //we also to save the final policies into initalPolicies to make use able to track new changes
                setInitialResourcePolicies(final_access_policies)
                //then we have to update parent router state
                return props.userDataUpdater({type: 'policies',payload: final_access_policies})
            }
        }
        catch(err){
            setLoading(false)
        }
    }


    return(
        <form className={userEditPolicies}>
            <div className={confirmBtn}>
                <ButtonA type='primary' children='Confirm changes' onClick={(e) => {confirmHandler(e)}} loading={loading}/>
            </div>
            <div className={tableContainer}>
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Resource</td>
                            {
                            allActionNameCellsInHeadOfTable
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                        allTableRows
                        }
                    </tbody>
                </table>
            </div>
        </form>
    )
}


export default UserEditPolicies