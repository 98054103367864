import Template from '../../components/template/template'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import { useState, useEffect } from 'react'
import SpinnerB from '../../components/spinner/spinnerB'
import DailyAccountReportTable from '../../components/tables/dailyAccountReportTable/dailyAccountReportTable'
import styles from './dailyTalkList.module.scss'
import { useTypedSelector, useDispatch } from '../../redux'
import {DailyReportT, GetDailyTalkList, GetDailyTalkListFilterT, GetDailyTalkListResT, GetLangs, GetLangsResT} from '../../utils/requests'
import { useNavigate, useLocation } from 'react-router-dom'
import {toast} from 'react-toastify'
import { PATHES } from '../../utils/contants'
import { changeHourOfISODate, historyPropertiesExistanceCheck } from '../../utils/utility'
import Tabs from '../../components/tab/tabs'
import Filter from '../../components/filters/filterE'
import { prepare_data_for_NewAccount_lineChart, prepare_data_for_talk_MultilineChartDataset, prepare_data_for_talk_MultilineChartDataset_newAccounts_newPals, prepare_data_for_talk_newAccount_dailyTalks, rearrange_date_fields, reconstruct_daily_report_data_for_graph_v_4, reorder_date_fields } from '../../utils/others'
import StackedAreaChart from '../../components/charts/stackedAreaChart/stackedAreaChart'
import { reconstruct_daily_report_data_for_graph_v_3 } from '../../utils/others'
import DailyDurationReportTable from '../../components/tables/dailyDurationReportTable/dailyDurationReportTable'
const { dailyTalkList, container, filterSpot, body } = styles
import LineChat from '../../components/charts/LineChart/LineChart'
import MultipleLineChartDataset from '../../components/charts/LineChart/MultipleLineChartDataSet'
const DailyTalkList = (props) => {  
  const today = new Date()
  const yesterday = new Date(today.getTime() - 1 *24*60*60*1000)
  //in case of traversing from the countryTalkDetail page
  const location = useLocation()
  const filteredDates = historyPropertiesExistanceCheck(location,'filteredDates',null) as {from: Date | null, to: Date | null} | null
  const [dailyTalkReport, setDailyTalkReport] = useState<DailyReportT[]>([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const [firstRender, setFirstRender] = useState(true)
  const [selectedTabId, setSelectedTabId] = useState(0)
  const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({
    //if user is comming back from the countryTalkDetail page we want to show those filtered dates
    from: filteredDates && filteredDates.from ? filteredDates.from : yesterday,
    to: filteredDates && filteredDates.to ? filteredDates.to : yesterday
  })
  const [loading,setLoading] = useState(false)
  const [confirmedFilterPhrase, setConfirmedFilterPhrase] = useState<GetDailyTalkListFilterT | null>({
      from: changeHourOfISODate(createdAt.from, 0, 0, 0),
      to: changeHourOfISODate(createdAt.to, 0, 0, 0)
  })
  console.log('schema',dailyTalkReport);

  //first fetch as soon as component mount
//   useEffect(() => {
//     const fetchData = async (access_token: string) => {
//         try {
//             setLoading(true)
//             const filters: GetDailyTalkListFilterT = {
//               from: changeHourOfISODate(createdAt.from, 0, 0, 0),
//               to: changeHourOfISODate(createdAt.to, 23, 59, 59) 
//             }
//             const res = await GetDailyTalkList({token: access_token, filters},{dispatch,navigate,refresh_token: props.refresh_token,toast})
//             setLoading(false)
//             if(res.status === 200){
//                 const resData = res.data as GetDailyTalkListResT
//                 // console.log('data',res.data);
                
//                 if(resData){
//                   setDailyTalkReport(resData)
//                 }
//                 else {
//                   setDailyTalkReport([])
//                 }
//             }
//         }
//         catch(err){
//             setLoading(false)
//         }
//     }
//     if(userRedux.access_token){
//         fetchData(userRedux.access_token)
//         setFirstRender(false)
//     }
// },[userRedux.access_token])


const fetchData = async (access_token: string, filterPhrase: GetDailyTalkListFilterT) => {
  try {
      setLoading(true)
      const res = await GetDailyTalkList({token: access_token, filters: filterPhrase},{dispatch,navigate,refresh_token: props.refresh_token,toast})
      setLoading(false)
      if(res.status === 200){
          const resData = res.data as GetDailyTalkListResT
          if(resData){
            setDailyTalkReport(resData)
          }
          else {
            setDailyTalkReport([])
          }
      }
  }
  catch(err){
      setLoading(false)
  }
}

const confirmHandler = async() => {
  if(loading){
    return
  }
  //we want to validate inputs 
  try {
    //first we have to populate input values if exists any
    const filteredPhrase: GetDailyTalkListFilterT = { 
        from: confirmedFilterPhrase.from,
        to: confirmedFilterPhrase.to
    }
    let from: Date | null = null
    let to: Date | null = null
    //if createdAt is selected
    if(createdAt && createdAt.from){
      from = createdAt.from as Date
      filteredPhrase.from = changeHourOfISODate(from, 0, 0, 0)
    }
    if(createdAt && createdAt.to){
      to = createdAt.to as Date
      filteredPhrase.to = changeHourOfISODate(to, 23, 59, 59)
    }
    //then we have to correct the order of from and to 
    reorder_date_fields(createdAt, filteredPhrase)
    setConfirmedFilterPhrase(filteredPhrase)
    await fetchData(userRedux.access_token, filteredPhrase)

  }
  catch(err){

  }
}

  const dailyRowClickHandler = (dailyReport: DailyReportT) => {
    navigate(PATHES.dailyHourList, {
      state: {
        dailyReport: dailyReport,
        filteredDates: rearrange_date_fields(createdAt),
        from: PATHES.dailyTalkList,
        createdAtFrom:changeHourOfISODate(new Date(dailyReport.date), 0, 0, 0) ,
         createdAtTo: changeHourOfISODate(new Date(dailyReport.date), 0, 0,0)
        
      }
    })
  }
  // let reconstructed_data_for_graph_1 = []
  // let reconstructed_data_for_graph_2 = []
  let reconstructed_data_for_graph_3 = []
  let reconstructed_data_for_graph_4 = []
   let  reconstructed_data_for_newAccount_newPals_lineChart = []
  let reconstructed_data_for_newAccount_lineChart = []
  let reconstructed_data_for_talk_lineChart = []
  let reconstructed_data_for_newAccount_dailyTalk_lineChart = []
  
  

  if(dailyTalkReport.length > 0){
    // reconstructed_data_for_graph_1 = reconstruct_daily_report_data_for_graph_v_1(dailyTalkReport)
    // reconstructed_data_for_graph_2 = reconstruct_daily_report_data_for_graph_v_2(dailyTalkReport)
    reconstructed_data_for_graph_3 = reconstruct_daily_report_data_for_graph_v_3(dailyTalkReport,'total_day_pals','new_day_pals')
    reconstructed_data_for_graph_4 = reconstruct_daily_report_data_for_graph_v_4(dailyTalkReport)
    reconstructed_data_for_newAccount_lineChart = prepare_data_for_NewAccount_lineChart(dailyTalkReport)   
    reconstructed_data_for_talk_lineChart = prepare_data_for_talk_MultilineChartDataset(dailyTalkReport)
    reconstructed_data_for_newAccount_dailyTalk_lineChart = prepare_data_for_talk_newAccount_dailyTalks(dailyTalkReport)
    reconstructed_data_for_newAccount_newPals_lineChart =prepare_data_for_talk_MultilineChartDataset_newAccounts_newPals(dailyTalkReport)
  //  console.log('chart result',reconstructed_data_for_newAccount_newPals_lineChart);
   

  }

  return(
    <Template>
      <div className={dailyTalkList}>
        {
        // !loading && langFC && allChars ?
        // !loading &&  userRedux.access_token && dailyTalkReport ?
        <MainPanel title='Daily Talks' headButtons={[]}>
          <div className={container} style={{width: '100%'} as React.CSSProperties}>
            <div className={filterSpot}>
              <Filter  confirmHandler={confirmHandler} loading={loading} 
                createdAt={createdAt} setCreatedAt={setCreatedAt} 
              />
                </div>
        
            <div className={body}>
              <Tabs 
                components={[
                      <DailyAccountReportTable dailyRowClickHandler={dailyRowClickHandler} data={Object.values(dailyTalkReport)} />,
                      <LineChat title='New Accounts' dataPoints={reconstructed_data_for_newAccount_lineChart} xLabel='' yLabel='' />,
                  <MultipleLineChartDataset title='Talks' dataPoints={reconstructed_data_for_talk_lineChart} xLabel='' yLabel=''/>,
                  // <StackedAreaChart data={reconstructed_data_for_graph_1} title='' xInterval={1}/>,
                  // <StackedAreaChart data={reconstructed_data_for_graph_2} title='' xInterval={1}/>,
                  <StackedAreaChart data={reconstructed_data_for_graph_3} title='' xInterval={1} />,
                    <DailyDurationReportTable dailyRowClickHandler={dailyRowClickHandler} data={Object.values(dailyTalkReport)} date={ confirmedFilterPhrase} />,
                      <StackedAreaChart data={reconstructed_data_for_graph_4} title='' xInterval={1} />,
                    <MultipleLineChartDataset title='New Account Vs Daily Talks' dataPoints={reconstructed_data_for_newAccount_dailyTalk_lineChart} xLabel='' yLabel='' />,
                    <MultipleLineChartDataset title='New Accounts VS New Accounts Talk' dataPoints={ reconstructed_data_for_newAccount_newPals_lineChart} xLabel='' yLabel=''/>
                  
                ]}
                data={[
                  {id: 0, title: "Account Table"},
                  {id: 1, title: "New Accounts"},
                  {id: 2, title: "Talks"},
                  {id: 3, title: "Pals"},
                  {id: 4, title: "Duration Table"},
                  { id: 5, title: "Duration Graph" },
                  { id: 6, title: "New Accounts Vs Daily Total Talks" },
                  {id: 7, title: "New Accounts Vs New  accounts talk"}
                ]}
                selectedTabId={selectedTabId}
                tabChanger={(tabId) => setSelectedTabId(tabId)}
              />
            </div>
          </div>
        
        </MainPanel>
        // :
        // <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        //   <SpinnerB/>
        // </div>
        }
      </div>
  </Template>  
  )
}



export default DailyTalkList