import IMGS_URL from '../config.json'
import EmailImg from '../assets/images/email.svg';
import PasswordImg from '../assets/images/password.svg';
import { RoleT, StatusT, LanguageT, CharT, CharacterT, SortTalkT, StatusTalkT, PlatformT, EndReasonTalkT, CountryTalkT, QualityOfSericeT, TalkTypeT } from './models'
import { OtherInputsContent, SelectContent, TextAreaContent, InputTypes } from '../components/formControl/formControl'

//FC ===> formcontrol config
//stateV ===> state value


export const firstNameFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'firstName',
}


export const lastNameFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'lastName'
}

export const emailFC: OtherInputsContent = {
  type: InputTypes.email,
  name: 'email'
}

export const emailX2FC: OtherInputsContent = {
  type: InputTypes.email,
  name: 'email',
  label: 'Email',
}

export const userNameFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'userName'
}

export const phoneNumberFC: OtherInputsContent = {
  type: InputTypes.tel,
  name: 'phoneNumber',
  placeHolder: 'ex: 09121234567'
}

//------------------------------------------------
export const passwordX2FC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'Password',
  label: 'Password',
  icon: PasswordImg,
  iconHover: PasswordImg
}

export const confirmPasswordX2FC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'confirmPassword',
  label: 'confirm password',
  icon: PasswordImg,
  iconHover: PasswordImg
}

export const oldPasswordFC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'oldPassword',
}

export const newPasswordFC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'newPassword'
}

export const confirmNewPasswordFC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'confirmNewPassword'
}

export const passFC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'pass'
}

export const VerificationCodeFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'verificationCode',
  label: 'verification code'
}

export const FirstAccountIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'firstAccountId',
}
export const SecondAccountIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'secondAccountId',
}
export const FirstDeviceIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'firstDeviceId',
}
export const SecondDeviceIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'secondDeviceId',
}
export const TalkIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'talkId',
}

export const AccountIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'talkId',
}
export const mediaDomainFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'mediaDomain',
}
export const CountryFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'country',
}

export const AppVersionFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'appVersion',
}
export const RefreshTimeFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'refreshTime',
}

export const FromHourFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'fromHour',
}

export const ToHourFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'toHour',
}

export const FromMinFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'fromMin',
}

export const ToMinFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'toMin',
}

export const LanguageTopicMessageFC: TextAreaContent = {
  type: InputTypes.textArea,
  rows: 5,
  name: 'languageTopicMessage',
  label: 'message'
}


//-----------------------------------------------
export const TopicFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'topic',
}

export const TypeFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'type',
}


export const searchFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'search'
}
export const accountIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'Account Id'
}
export const finisherIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'finisher_id'
}

export const minDurationFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'min-duration'
}

export const maxDurationFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'max-duration'
}
export const stringFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'string'
}
export const RoleFC_creator = (roles: RoleT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'role',
    options: [{ label: 'choose one role', value: 0 }, ...roles.map(role => ({ label: role.name, value: role.value }))],
  }
}

export const Role_WithoutDefaultOption_FC_creator = (roles: RoleT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'role',
    options: roles.map(role => ({ label: role.name, value: role.value })),
  }
}

export const UserStatusFC_creator = (statuses: StatusT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'userStatus',
    options: [{ label: 'choose one status', value: 0 }, ...statuses.map(status => ({ label: status.name, value: status.value }))],
  }
}

export const UserStatus_WithoutDefaultOption_FC_creator = (statuses: StatusT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'userStatus',
    options: statuses.map(status => ({ label: status.name, value: status.value }))
  }
}


export const langFC_creator = (langs: LanguageT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'languages',
    options: [{ label: 'filter by language', value: 0 }, ...langs.map(lang => ({ label: lang.name, value: lang.value }))]
  }
}

export const characterFC_creator = (chars: CharacterT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'characters',
    options: [{ label: 'filter by charcters', value: 0 }, ...chars.map(char => ({ label: char.name, value: char.value }))]
  }
}


export const sortFC_creator = (sorts: SortTalkT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'talk-sorts',
    options: [{ label: 'no filter', value: 0 }, ...sorts.map(sort => ({ label: sort.name, value: sort.value }))]
  }
}



export const statusFC_creator = (statuses: StatusTalkT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'talk-statuses',
    options: [{ label: 'no filter', value: 0 }, ...statuses.map(status => ({ label: status.name, value: status.value }))]
  }
}

export const endReasonFC_creator = (statuses: EndReasonTalkT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'end-reason',
    options: [{ label: 'no filter', value: -1 }, ...statuses.map(status => ({ label: status.name, value: status.value }))]
  }
}
export const talkTypeFC_creator = (statuses: TalkTypeT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'talk_type',
    options: [{ label: 'no filter', value: -1 }, ...statuses.map(status => ({ label: status.name, value: status.value }))]
  }
}

export const platformFC_creator = (platforms: PlatformT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'talk-platform',
    options: [{ label: 'no filter', value: 0 }, ...platforms.map(platform => ({ label: platform.name, value: platform.value }))]
  }
}




export const internetQualityFC_creator = (qualities: PlatformT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'internet-quality',
    options: [{ label: 'no filter', value: 0 }, ...qualities.map(quality => ({ label: quality.name, value: quality.value }))]
  }
}

export const countryTalkSortFC_creator = (countryTalk: CountryTalkT): SelectContent => {
  //'avg_duration', 'man%', 'woman%'
  return {
    type: InputTypes.select,
    name: 'country-talk',
    options: [
      { label: 'no filter', value: 0 },
      { label: 'man', value: 'man' },
      { label: 'woman', value: 'woman' },
      { label: 'man%', value: 'man%' },
      { label: 'woman%', value: 'woman%' },
      { label: 'avg_duration', value: 'avg_duration' },
      ...Object.keys(countryTalk).filter(key => {
        //we dont want to let user be able to sort based on some props which are not numbers
        return [
          , 'total_talks', 'successful_talks', 'failed_talks',
          'exited_talks', 'above_five_minutes_talks', 'total_duration',
        ].includes(key)
      }).map(prop => ({ label: prop, value: prop }))]
  }
}


const qualityOfServiceFC_arr: QualityOfSericeT[] = [
  { name: 'at least once', value: 1 },
  { name: 'at most once', value: 2 },
  { name: 'exactly once', value: 3 },
]
export const qualityOfServiceFC_creator = (qualities: QualityOfSericeT[] = qualityOfServiceFC_arr): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'quality_of_service',
    options: [...qualities.map(quality => ({ label: quality.name, value: quality.value }))]
  }
}

