import React, { ReactNode } from 'react'
import styles from './sideBar.module.scss'
import {useTypedSelector} from '../../redux/index'
import {ResourceWithActions, ResourceT, ResourceWithGroupT} from '../../utils/models'
import { NavLink} from 'react-router-dom'
import { useLocation,useNavigate} from 'react-router-dom'
import { PATHES, SUBLINKS } from '../../utils/contants'
import SectionLink from '../link/sectionLink'

const {sideBar,linkPanel,linkItem,link,activeItem,backToHomeBtn} = styles

const SideBar: React.FC = props => {
    const navigate = useNavigate()
    const {resources: reduxPermissions, allResources} = useTypedSelector(state => ({...state.user, ...state.configs}))
    // const location = useLocation()
  
    
    //first we have to extract the group value
    const groupsArr = allResources.map(res => res.group)

    //then we have to extract unique values of groups
    const uniqueGroupsArr = groupsArr.filter((c,i,arr) => arr.indexOf(c) === i)
   

    //to show links in a specific order in sidebar, we have to sort the arr
    const sortedGroupsArr = uniqueGroupsArr.sort((first, second) => {
        if(first > second){return 1}
        else if(first < second){return -1}
        else {return 0} 
    })

    //then we have to extract all resources for each group
    let groupWithTheirResources: {resources: ResourceT[],group: string}[] = []
  for (const group of sortedGroupsArr) {
            
        //first we have to filter allResources to just have user related resources
        //**we must not render * as link
    const relatedResources = allResources.filter(res => {
          // console.log(reduxPermissions);
          
            const isUserAuthorised = reduxPermissions.find((permission) => permission.name === res.name && permission.value === res.value && res.value !== 1 && res.group === group) ? true : false
            return isUserAuthorised
        })
       
        groupWithTheirResources.push({group,resources: relatedResources})
    }
   


    // console.log('groupWithTheirResources   ---->',groupWithTheirResources)
  const items = groupWithTheirResources.map((group, ind) => {
           
        //then we have to loop over resources and add the static sublinks
        const resourcesArr = group.resources.map((resource, index) => {
            //for the resource 'talk', we want to add some sublinks
            if(resource.value  === 10){
                return {
                    ...resource,
                    sublinks: SUBLINKS[resource.value]?.sublinks
                }
          }
                
            return resource
        })

    
        return (<SectionLink resources={resourcesArr} key={`${group.group}-${ind}`} group={group.group}/>)
    })
// console.log('itema',items);

    return (
        <div className={sideBar}>
            <ul className={linkPanel}>
                {
                items
                }
            </ul>
            {/* <div className={backToHomeBtn} onClick={() => {navigate(PATHES.home)}}>
                back to home
            </div> */}
        </div>
    )
}



export default SideBar


























// const SideBar: React.FC = props => {
//     const {resources: reduxPermissions, allResources} = useTypedSelector(state => ({...state.user, ...state.configs}))
//     const location = useLocation()
  
    
//     //to show links in a specific order in sidebar, we have to sort the arr
//     const sortedAllResources = allResources.sort((first: ResourceT, second: ResourceT) => {
//         if(first.value > second.value){return 1}
//         else if(first.value < second.value){return -1}
//         else {return 0} 
//     })

//     let availableLinks: ReactNode[] = []

//     //first we have to filter allResources to just have user related resources
//     sortedAllResources.forEach((resource: ResourceT) => {
//         //**we must not render * as link
//         const isUserAuthorised = reduxPermissions.find((permission: ResourceWithActions) => permission.name === resource.name && permission.value === resource.value && resource.value !== 1) ? true : false
//         if(isUserAuthorised){
//             //if current location-path is equal to the current link, we change its styling to be more distinct
//             availableLinks.push(<li key={resource.name} className={[linkItem, location.pathname.includes(PATHES[resource.name]) ? activeItem : ''].join(' ')}><NavLink to={`${PATHES[resource.name]}`} className={link}>{resource.name}</NavLink></li>)
//         }
//     })

//     return (
//         <div className={sideBar}>
//             <ul className={linkPanel}>
//                 {
//                 availableLinks
//                 }
//             </ul>
//         </div>
//     )
// }
