import { Link } from 'react-router-dom';
import styles from './billboard.module.scss'
type Props = {
  title: string,
  data: number | string;
  path?:string
}
const {item, itemLabel, itemValue,currentActiveCallsC, inHistoryActiveCallsC} = styles
const Billboard: React.FC<Props> = ({ data , title,path }) => {
  return (
  <>
    { path ?
      <>
    <Link to={path ? path : '#'} style={{ textDecoration: 'none' }}><div className={[item, inHistoryActiveCallsC].join(' ')}>
     
      <div className={itemLabel}>{ title}</div>
     <div className={itemValue}>{data}</div>
      </div></Link> 
  </>  
  :<div className={[item, inHistoryActiveCallsC].join(' ')}>
     
      <div className={itemLabel}>{ title}</div>
     <div className={itemValue}>{data}</div>
      </div>}
    </>
  );
};

export default Billboard;