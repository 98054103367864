import React, {useState} from 'react'
import styles from './addObjectFormControl.module.scss'
import AddObjectOptions from './addObjectOptions'
import {CharacterT} from '../../../utils/models'

const {addObjectsFormControl, focused,main,main_text,main_icon,options_spot,labelItem} = styles 

type AddObjectsFormControlPropsT = {
    values: {name: string, value: string|number}[]
    setValues: (a) => any
    name: string
    isMultiValue: boolean
    options: {name: string, value: string|number}[]
    removeHandler: (value: string|number) => void
}


const AddObjectsFormControl:React.FC<AddObjectsFormControlPropsT> = props => {
    const [showOptions, setShowOptions] = useState(false)
 
    const labels = props.values.map((obj, index) => {
        return (
            <React.Fragment key={`ie-${index}`}>
                <div className={labelItem}>
                    {obj.name}
                    <span onClick={() => props.removeHandler(obj.value)}>&#10060;</span>
                </div>
            </React.Fragment>
        )
    })

    return (
        <div className={[addObjectsFormControl, showOptions ? focused : ''].join(' ')}>
           <div className={main} onClick={() => setShowOptions(true)}>
            <div className={main_text}>
                {
                labels
                }
            </div>
            <div className={main_icon}></div>
           </div>
           {
            showOptions &&


            <div className={options_spot}>
                <AddObjectOptions confirmedValues={props.values} setValues={props.setValues} options={props.options} setShowOptions={setShowOptions} isMultiValue={props.isMultiValue}/>
            </div>
           }
        </div>
    )
}

export default AddObjectsFormControl






// how to use

// const [userV,setUserV] = useState('')
// const [userIsValid,setUserIsValid] = useState(true)
// const [usersArrV,setUsersArrV] = useState([])
// const [usersArrIsValid,setUsersArrIsValid] = useState(true)

// const userAddHandler = () => {
//     //some validation code
//     setUsersArrV(prev => [...prev,userV])
  
// }


// const usersArrRemoveHandler = (user) => {
//     setUsersArrV(prev => [...prev.filter(c => c !== user)])
// }




{/* <AddItemFormControl btnTitle="تایید" addHandler={userAddHandler} confirmedArr={usersArrV} removeHandler={usersArrRemoveHandler} isValid={usersArrIsValid}>
    <FormControl content={CouponRestrictedUsers} value={userV} setParentValue={setUserV} isValid={userIsValid} setIsValid={setUserIsValid}  errorMsg={null} />
</AddItemFormControl> */}




//when is readOnly
{/* 
<AddItemFormControl btnTitle="تایید" readOnly={true} addHandler={() => {}} confirmedArr={[]} removeHandler={() => {}} isValid={true}>
    <div className={usersFc} style={{border: '1px solid #c9a220',borderRadius: '4px',height: '3em',padding: '0 1em',display: 'flex',justifyContent: 'flex-start',alignItems: 'center'}}>
        <div className={label}>کاربرهای مجاز</div>
    </div>
</AddItemFormControl> */}