import styles from './filterA.module.scss'

import DatePicker from '../datePicker/reactDatePicker'
import ButtonA from '../buttons/buttonA'
import RightImg from '../../assets/images/right.png'
import CalenderImg from '../../assets/images/calender.png'

import FormControl,{SelectContent, OtherInputsContent} from '../formControl/formControl'

const {filter, filterItem, filterLabel, filterValue, arrIcon, calIcon, search, role, status, createdRange, confirmBtn, dateBox,fromHour, toHour,mediaServerA,mediaServerB} = styles


type FilterProps = {
    searchValue?: string 
    setSearchValue?: (a: string) => void
  searchContent?: OtherInputsContent    
      perTimeValue?: string 
    setPerValue?: (a: string) => void
    perContent?: OtherInputsContent 
    roleValue?: number
    setRoleValue?: (e: any, value: number, label: string, object: object) => void
    roleContent?: SelectContent
    statusValue?: number
    setStatusValue?: (e: any, value: number, label: string, object: object) => void
    statusContent?: SelectContent
    createdAt?: {from: Date, to: Date}
    setCreatedAt?: (a: any) => void
    confirmHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
    loading: boolean
   
  
    
}

const Filter:React.FC<FilterProps> = (
    {searchValue,setSearchValue,searchContent,perTimeValue,setPerValue,perContent,roleValue,setRoleValue,roleContent,statusValue,setStatusValue,statusContent,createdAt,setCreatedAt,confirmHandler,loading}: FilterProps) => {
    
    return (
    <div className={filter}>
        {
        searchContent ?
        <div className={[filterItem, search].join(' ')}>
            <div className={filterLabel}>search:</div>
            <div className={filterValue}>
                <FormControl content={searchContent} value={searchValue} setParentValue={setSearchValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
         {
        perContent ?
        <div className={[filterItem, search].join(' ')}>
            <div className={filterLabel}>Time per Minutes:</div>
            <div className={filterValue}>
                <FormControl content={perContent} value={perTimeValue} setParentValue={setPerValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        roleContent ?
        <div className={[filterItem, role].join(' ')}>
            <div className={filterLabel}>role:</div>
            <div className={filterValue}>
                <FormControl content={roleContent} value={roleValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setRoleValue}/>
            </div>
        </div>
        :""
        }
        {
        statusContent ?
        <div className={[filterItem, status].join(' ')}>
            <div className={filterLabel}>status:</div>
            <div className={filterValue}>
                <FormControl content={statusContent} value={statusValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setStatusValue}/>
            </div>
        </div>
        :""
        }
        {
        createdAt && setCreatedAt ?
        <div className={[filterItem, createdRange].join(' ')}>
            <div className={filterLabel}>created at:</div>
            <div className={filterValue}>
                <div className={dateBox}><DatePicker date={createdAt.from} setDate={(date) => {setCreatedAt(prev => ({...prev,from: date}))}} placeholder="Start date"/></div>
                <img src={RightImg} className={arrIcon}/>
                <div className={dateBox}><DatePicker date={createdAt.to} setDate={(date) => {setCreatedAt(prev => ({...prev, to: date}))}} placeholder="End date"/></div>
                <img src={CalenderImg} className={calIcon}/>
            </div>
        </div>
        :""
        }
        
      
        
        <div className={confirmBtn}>
            <ButtonA children={'filter result'} type='primary' loading={loading} onClick={confirmHandler}/>
        </div>
    </div>
    )
}


export default Filter