import CanvasJSReact, { CanvasJSOptionsT } from '../../../0-0/canvasJS/canvasjs.react';
import styles from './multiplePieChart.module.scss';

type PieChartPropsT = {
  charts: {
    title: string;
    dataPoints: { label: string; value: number;color?:string }[];
    sign?: string;
  }[];
};

const MultiplePieChart: React.FC<PieChartPropsT> = (props) => {
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;

  return (
    <div className={styles.chartsContainer}>
      {props.charts.map((chart, index) => (
        <div key={index} className={styles.chartBox}>         
          <CanvasJSChart options={generateChartOptions(chart)} containerProps={undefined} onRef={undefined} />
        </div>
      ))}
    </div>
  );
};

const generateChartOptions = (chartData: {
  title: string;
  dataPoints: { label: string; value: number;color?:string }[];
  sign?: string;
}): CanvasJSOptionsT => {
  return {
    animationEnabled: true,
    exportEnabled: true,
    theme: 'light1',
    title: {
      text: chartData.title,
    },
    data: [
      {
        type: 'pie',
        indexLabel: `{label}: {y} ${chartData.sign ? chartData.sign : ''}`,
        startAngle: -90,
        dataPoints: chartData.dataPoints.map((dp) => ({
          label: dp.label,
          y: dp.value,
          color:dp.color || undefined
        })),
      },
    ],
  };
};

export default MultiplePieChart;
