import { RoleT, ResourceT, ActionT, BackendGetConfigsResT, StatusT, ResourceWithGroupT, LanguageT, CharacterT, StatusTalkT, EndReasonTalkT, TalkTypeT } from '../../utils/models'




//type of action types 
export enum ConfigsReduxActionTypesT {
    saveConfigs = 'SAVE_CONFIGS',
    removeConfigs = 'REMOVE_CONFIGS',
    saveWholeStateFromSessionStorage = 'SAVE_WHOLE_CONFIGS_FROM_SESSION_STORAGE'
}


type Action_Save_configs_T = {
    type: ConfigsReduxActionTypesT.saveConfigs
    payload: BackendGetConfigsResT
}

type Action_Remove_configs_T = {
    type: ConfigsReduxActionTypesT.removeConfigs
}

type Action_Save_Whole_State_From_Session_Storage = {
    type: ConfigsReduxActionTypesT.saveWholeStateFromSessionStorage,
    payload: ConfigsReduxT
}

//type of all actions
export type ConfigsReduxActionsT = Action_Save_configs_T | Action_Remove_configs_T | Action_Save_Whole_State_From_Session_Storage



//type of redux state
export type ConfigsReduxT = {
    allRoles: RoleT[]
    allResources: ResourceWithGroupT[]
    allActions: ActionT[]
    allStatuses: StatusT[]
    allLanguages: LanguageT[]
    allCharacters: CharacterT[]
    talkStatuses: StatusTalkT[]
  talkEndReasons: EndReasonTalkT[]
    talkType:TalkTypeT[]
}
