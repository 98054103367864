import styles from './filterH.module.scss'

import DatePicker from '../datePicker/reactDatePicker'
import ButtonA from '../buttons/buttonA'
import RightImg from '../../assets/images/right.png'
import CalenderImg from '../../assets/images/calender.png'

import FormControl,{SelectContent, OtherInputsContent} from '../formControl/formControl'

const {filter, filterItem, filterLabel, filterValue, arrIcon, calIcon, search, role, status, createdRange, confirmBtn, dateBox,fromHour, toHour,mediaServerA,mediaServerB,platformA,platformB,talkType} = styles


type FilterProps = {
    searchValue?: string 
    setSearchValue?: (a: string) => void
  searchContent?: OtherInputsContent    
      perTimeValue?: string 
    setPerValue?: (a: string) => void
    perContent?: OtherInputsContent 
    roleValue?: number
    setRoleValue?: (e: any, value: number, label: string, object: object) => void
    roleContent?: SelectContent
    statusValue?: number
    setStatusValue?: (e: any, value: number, label: string, object: object) => void
    statusContent?: SelectContent
    createdAt?: {from: Date, to: Date}
    setCreatedAt?: (a: any) => void
    confirmHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
    loading: boolean
    fromHourValue?: string 
    setFromHourValue?: (a: string) => void
    fromHourContent?: OtherInputsContent 
    toHourValue?: string 
    setToHourValue?: (a: string) => void
  toHourContent?: OtherInputsContent 
       mediaServerAValue?: string 
    setMediaServerA?: (a: string) => void
  mediaServerAContent?: OtherInputsContent 
        mediaServerBValue?: string 
    setMediaServerB?: (a: string) => void
   mediaServerBContent?: OtherInputsContent 
  
    platformFC?: SelectContent
    platformAValue?: number
    setPlatformAValue?: (e: any, value: number, label: string, object: object) => void
    
    platformBValue?: number
    setPlatformBValue?: (e: any, value: number, label: string, object: object) => void
    
    talkTypeFC?: SelectContent
    talkTypeValue?: number
    setTalkTypeValue?: (e: any, value: number, label: string, object: object) => void
    
}

const Filter:React.FC<FilterProps> = (
    {searchValue,setSearchValue,searchContent,perTimeValue,setPerValue,perContent,fromHourValue,fromHourContent,setFromHourValue,toHourValue,setToHourValue,toHourContent,mediaServerAContent,mediaServerAValue,setMediaServerA,mediaServerBContent,mediaServerBValue,setMediaServerB,roleValue,setRoleValue,roleContent,statusValue,setStatusValue,statusContent,createdAt,setCreatedAt,platformFC,platformAValue,setPlatformAValue,platformBValue,setPlatformBValue,talkTypeFC,talkTypeValue,setTalkTypeValue,confirmHandler,loading}: FilterProps) => {
    
    return (
    <div className={filter}>
        {
        searchContent ?
        <div className={[filterItem, search].join(' ')}>
            <div className={filterLabel}>search:</div>
            <div className={filterValue}>
                <FormControl content={searchContent} value={searchValue} setParentValue={setSearchValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
         {
        perContent ?
        <div className={[filterItem, search].join(' ')}>
            <div className={filterLabel}>Time per Minutes:</div>
            <div className={filterValue}>
                <FormControl content={perContent} value={perTimeValue} setParentValue={setPerValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        roleContent ?
        <div className={[filterItem, role].join(' ')}>
            <div className={filterLabel}>role:</div>
            <div className={filterValue}>
                <FormControl content={roleContent} value={roleValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setRoleValue}/>
            </div>
        </div>
        :""
        }
        {
        statusContent ?
        <div className={[filterItem, status].join(' ')}>
            <div className={filterLabel}>status:</div>
            <div className={filterValue}>
                <FormControl content={statusContent} value={statusValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setStatusValue}/>
            </div>
        </div>
        :""
        }
        {
        createdAt && setCreatedAt ?
        <div className={[filterItem, createdRange].join(' ')}>
            <div className={filterLabel}>created at:</div>
            <div className={filterValue}>
                <div className={dateBox}><DatePicker date={createdAt.from} setDate={(date) => {setCreatedAt(prev => ({...prev,from: date}))}} placeholder="Start date"/></div>
                <img src={RightImg} className={arrIcon}/>
                <div className={dateBox}><DatePicker date={createdAt.to} setDate={(date) => {setCreatedAt(prev => ({...prev, to: date}))}} placeholder="End date"/></div>
                <img src={CalenderImg} className={calIcon}/>
            </div>
        </div>
        :""
        }
          {
        fromHourContent ?
        <div className={[filterItem, fromHour].join(' ')}>
            <div className={filterLabel}>from hour:</div>
            <div className={filterValue}>
                <FormControl content={fromHourContent} value={fromHourValue} setParentValue={setFromHourValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
            {
        toHourContent ?
        <div className={[filterItem, toHour].join(' ')}>
            <div className={filterLabel}>To hour:</div>
            <div className={filterValue}>
                <FormControl content={toHourContent} value={toHourValue} setParentValue={setToHourValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
            {
        mediaServerA ?
        <div className={[filterItem, mediaServerA].join(' ')}>
            <div className={filterLabel}>Media Server Per A:</div>
            <div className={filterValue}>
                <FormControl content={mediaServerAContent} value={mediaServerAValue} setParentValue={setMediaServerA} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
            {
        mediaServerB ?
        <div className={[filterItem, mediaServerB].join(' ')}>
            <div className={filterLabel}>Media Server Per B:</div>
            <div className={filterValue}>
                <FormControl content={mediaServerBContent} value={mediaServerBValue} setParentValue={setMediaServerB} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
          {    
        platformFC ? 
        <div className={[filterItem, platformA].join(' ')}>
            <div className={filterLabel}>platformA:</div>
            <div className={filterValue}>
                <FormControl content={platformFC} value={platformAValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setPlatformAValue}/>
            </div>
        </div>
        : ""
        }
           {    
        platformFC ? 
        <div className={[filterItem, platformB].join(' ')}>
            <div className={filterLabel}>platformB:</div>
            <div className={filterValue}>
                <FormControl content={platformFC} value={platformBValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setPlatformBValue}/>
            </div>
        </div>
        : ""
        }
         {
        talkTypeFC ?
        <div className={[filterItem, talkType].join(' ')}>
            <div className={filterLabel}>Type:</div>
            <div className={filterValue}>
                <FormControl content={talkTypeFC} value={talkTypeValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setTalkTypeValue}/>
            </div>
        </div>
        :""
        }
        <div className={confirmBtn}>
            <ButtonA children={'filter result'} type='primary' loading={loading} onClick={confirmHandler}/>
        </div>
    </div>
    )
}


export default Filter