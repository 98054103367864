import { useNavigate } from 'react-router-dom'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import Template from '../../components/template/template'
import { useDispatch, useTypedSelector } from '../../redux'
import styles from './failtalk.module.scss'
import { changeHourOfISODate, historyPropertiesExistanceCheck } from '../../utils/utility'
import { useEffect, useState } from 'react'
import { FailTalkFilterT, FailTalkItemT, FailTalkResT, GetFailTalk } from '../../utils/requests'
import { justNumberInputChanger } from '../../utils/validator'
import Tabs from '../../components/tab/tabs'
import {toast} from 'react-toastify'
import { reorder_date_fields, transformDataToDatasets } from '../../utils/others'
import FailTalkTable from '../../components/tables/failTalkReportTable/FailTalkTable'
import { platformFC_creator, sortFC_creator, stringFC, talkTypeFC_creator} from '../../utils/formControl'
import { SelectContent } from '../../components/formControl/formControl'
import { PLATFORMS_ARR } from '../../utils/contants'
import Filter from '../../components/filters/FilterM'
import MultiplePieChart from '../../components/charts/multiplePieChart/MultiplePieChart'
const { container, filterSpot, body } = styles
   const sort_arr = [{name: "notStartedCount", value: 1}, {name: "inProgressCount", value: 2}, {name: "finishedCount", value: 3},{name: "exitedCount", value: 4},{name: "p1_media", value: 5},{name: "p2_media", value: 6},{name: "notStartedPercent", value: 7},{name: "inProgressPercent", value: 8},{name: "finishedPercent", value: 9},{name: "exitedPercent", value: 10},{name: "total", value: 11}]   //string
const FailTalk: React.FC = () => {
     const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
    const dispatch = useDispatch()
  const navigate = useNavigate()
   const today = new Date()
  const yesterday = new Date(today.getTime() - 1 *24*60*60*1000)
  const filteredDates = historyPropertiesExistanceCheck(location, 'filteredDates', null) as { from: Date | null, to: Date | null } | null
  const [loading, setLoading] = useState(false)
    const [fromHourV, setFromHourV] = useState('0')
  const [toHourV, setToHourV] = useState('23')
  const [platformFC, setPlatformFC] = useState<SelectContent | null>(null)
  const [sortFC, setSortFC] = useState<SelectContent | null>(null)
  const [sortV,setSortV] = useState(0)
  const [platformVA, setPlatformVA] = useState(0)
  const [platformVB, setPlatformVB] = useState(0)
  const [talkTypeFC, setTalkTypeFC] = useState<SelectContent | null>(null)
  const [talkTypeV, setTypeTalkV] = useState(-1)
  const [mediaServerAV, setMediaServerAV] = useState('')
  const [mediaServerBV, setMediaServerBV] = useState('')
  const [mediaV, setMediaV] = useState('')
    const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({
      from: filteredDates && filteredDates.from ? filteredDates.from : today,
    to: filteredDates && filteredDates.to ? filteredDates.to : today
    })
      const [selectedTabId, setSelectedTabId] = useState(0)
  const [confirmedFilterPhrase, setConfirmedFilterPhrase] = useState<FailTalkFilterT| null>({
     from: changeHourOfISODate(createdAt.from, Number(fromHourV), 0, 0),
      to: changeHourOfISODate(createdAt.to, Number(toHourV), 0, 0)
  })
  const [failTalkReport, setFailTalkReport] = useState<FailTalkItemT[]>([])
    const fromHourChanger = justNumberInputChanger(setFromHourV)
  const toHourChanger = justNumberInputChanger(setToHourV)
    const platformChangerA = (e: any, value: number, label: string, object: object) => {
    setPlatformVA(value)
  }
  const platformChangerB = (e: any, value: number, label: string, object: object) => {
    setPlatformVB(value)
  }
   const sortChanger = (e: any, value: number, label: string, object: object) => {
        setSortV(value)
  }
   const talkTypeChanger = (e: any, value: number, label: string, object: object) => {
        setTypeTalkV(value)
  }
    //configs based on roles and status comes from redux for dropdown
  useEffect(() => {    
 
    setSortFC(sortFC_creator(sort_arr)) 
    setPlatformFC(platformFC_creator(PLATFORMS_ARR)) 
     setTalkTypeFC(talkTypeFC_creator(configRedux.talkType) )
  }, [configRedux])
  const fetchData = async (access_token: string, filterPhrase: FailTalkFilterT) => {
  try {
      setLoading(true)
      const res = await GetFailTalk({token: access_token, filters: filterPhrase},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
      setLoading(false)
      if(res.status === 200){
        const resData = res.data as FailTalkResT
        // console.log('fail talk', res.data);
        
          if(resData){
            setFailTalkReport(resData.items)
          }
          else {
            setFailTalkReport([])
          }
      }
  }
  catch(err){
      setLoading(false)
  }
}
  const confirmHandler = async() => {
  if(loading){
    return
  }
 
  try {
   //first we have to populate input values if exists any
    const filteredPhrase: FailTalkFilterT = { 
        from: confirmedFilterPhrase.from,
      to: confirmedFilterPhrase.to,
    }
    let from: Date | null = null
    let to: Date | null = null
    //if createdAt is selected
    if(createdAt && createdAt.from){
      from = createdAt.from as Date
      filteredPhrase.from = changeHourOfISODate(from,Number(fromHourV),0,0 )
    }
    if(createdAt && createdAt.to){
      to = createdAt.to as Date
      filteredPhrase.to = changeHourOfISODate(to,Number(toHourV), 59, 59)
    }
       if(platformVA !== 0){
            filteredPhrase.p1_platform= platformVA
    }
    if(platformVB !== 0){
            filteredPhrase.p2_platform= platformVB
    }
    //if sortby is choosed
        if(sortV !== 0){
            filteredPhrase.sort = sort_arr.find(so => so.value === sortV)?.name
    }
    if(talkTypeV !== -1){
            filteredPhrase.call_type = talkTypeV
    }
     if(mediaServerAV.trim().length !== 0){
            filteredPhrase.p1_media = mediaServerAV.trim()
    }
    if(mediaServerBV.trim().length !== 0){
            filteredPhrase.p2_media = mediaServerBV.trim()
    }
    if(mediaV.trim().length !== 0){
            filteredPhrase.media = mediaV.trim()
    }
    reorder_date_fields(createdAt, filteredPhrase)
    setConfirmedFilterPhrase(filteredPhrase)
    await fetchData(userRedux.access_token, filteredPhrase)
  }
  catch(err){
  }
  
  }
   let reconstructed_data_for_Pie_Chart = []
  reconstructed_data_for_Pie_Chart = transformDataToDatasets(failTalkReport)
  
  return (
    <Template>
      <>
        <MainPanel title='Fail Talk By Media' headButtons={[]}>
          <div className={container} style={{ width: '100%' } as React.CSSProperties}>
                <div className={filterSpot}>
            {/* <Filter  confirmHandler={confirmHandler} loading={loading} 
                createdAt={createdAt} setCreatedAt={setCreatedAt} fromHourValue={fromHourV} setFromHourValue={fromHourChanger} fromHourContent={accountIdFC} toHourValue={toHourV} setToHourValue={toHourChanger} toHourContent={accountIdFC} platformFC={platformFC}/> */}
              <Filter confirmHandler={confirmHandler} loading={loading}
                createdAt={createdAt} setCreatedAt={setCreatedAt} fromHourValue={fromHourV} setFromHourValue={fromHourChanger} fromHourContent={stringFC} toHourValue={toHourV} setToHourValue={toHourChanger} toHourContent={stringFC} platformAValue={platformVA} setPlatformAValue={platformChangerA} platformBValue={platformVB} setPlatformBValue={platformChangerB} platformFC={platformFC} sortFC={sortFC} sortValue={sortV} setSortValue={sortChanger} talkTypeFC={talkTypeFC} talkTypeValue={talkTypeV} setTalkTypeValue={talkTypeChanger} mediaServerAContent={stringFC} mediaServerAValue={mediaServerAV} setMediaServerA={setMediaServerAV} mediaServerBContent={stringFC} mediaServerBValue={mediaServerBV} setMediaServerB={setMediaServerBV} mediaContent={stringFC} mediaValue={ mediaV} setMedia={setMediaV} />
            </div>
                <div className={body}>
              <Tabs
                components={[
                  <FailTalkTable failTalkData={failTalkReport} />,
                  <MultiplePieChart charts={reconstructed_data_for_Pie_Chart} />
                ]}
                data={[
                  {id: 0, title: " table"},
                  { id: 1, title: "Chart" },
                  // {id: 2, title: "table"}
                 
                ]}
                selectedTabId={selectedTabId}
                tabChanger={(tabId) => setSelectedTabId(tabId)}
              />
            </div>
          </div>
        </MainPanel>
      </>
   </Template>
  )
}
export default FailTalk