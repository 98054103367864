import styles from './dashboard.module.scss'
import React from 'react';
import Billboard from '../../billboard/Billboard';

interface info {
    onlineUsers: number,
  inSearchUsers: number,
  currentActiveCalls:number,
  inHistoryActiveCalls: number,
  avgDurationPerCall:number,
  failedPercentage:number | string,
  exitedPercentage:number | string
  women:number,
  womenRate: number
  progressCall:number
}

interface Props {
 data:info
}

const DashboardInfo: React.FC<Props> = ({data}) => {
  return (
    <div className={styles.container}>    
      <Billboard title='online users' data={data.onlineUsers} />
      <Billboard title='in-search users' data={data.inSearchUsers}  path='/talk-another'/>
      <Billboard title='Active calls' data={data.currentActiveCalls} path='/talk-list' />
      <Billboard title='Call' data={data.progressCall} path='/talk-list' />
      <Billboard title='total calls (last 24hrs)' data={data.inHistoryActiveCalls} path='/daily-talk-list' />
      <Billboard title='Total Women' data={data.women} path='/country-talk-list' />
      <Billboard title='failed calls(% Last 24hrs)' data={data.failedPercentage} />
      <Billboard title='exited calls(% Last 24hrs)' data={data.exitedPercentage} />
      <Billboard title='avg duration / call (last 24hrs)' data={data.avgDurationPerCall} />
      <Billboard title='Women rate ( % Last 24hrs)' data={data.womenRate} />
      </div>
     
  );
};

export default DashboardInfo;
