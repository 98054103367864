import React from 'react'
import styles from './nav.module.scss'
import LogoImg from '../../assets/images/logoType&Mark-backDark.svg'
import Profile from './profile'
import {  NavLink, useNavigate } from 'react-router-dom'
import { PATHES } from '../../utils/contants'
const {nav,logoBox,menuBox,navigateBtn} = styles


const Header:React.FC = props => {
   const navigate = useNavigate()
    return(
        <nav className={nav}>
         <NavLink to={`${PATHES.home}`}><img className={logoBox} src={LogoImg} /></NavLink>
        
        <div className={menuBox}>
            <div className={navigateBtn}  onClick={() => {navigate(PATHES.home)}}>
               Home
            </div>
                <Profile />
        </div>
        
        </nav>
    )
}


export default Header