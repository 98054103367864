

export const PATHES = {
  login: '/',
  forgotPassword: '/forgot-password',
  refreshPassword: '/refresh-password',
  home: '/',
  profile: '/profile',
  profileEdit: '/profile-edit',
  profileChangePassword: '/profile-change-password',
  userCreate: '/user-create',
  userEdit: '/user-edit',
  policyList: '/policy-list',
  policyEdit: '/policy-edit',
  talkReportsLanguageCount: '/talk-reports-language-count',
  talkReportsLanguageDuration: '/talk-reports-language-duration',
  talkReportsCharacterCount: '/talk-reports-character-count',
  talkReportsCharacterDuration: '/talk-reports-character-duration',
  talkList: '/talk-list',
  talkAnother: '/talk-another',
  languageTotalList: '/language-total-list',
  countryTalkList: "/country-talk-list",
  countryTalkDetail: "/country-talk-detail",
  dailyTalkList: "/daily-talk-list",
  dailyHourList: '/daily-hour-list',
  user_suspension: '/user-suspension',
  send_topic: '/send-topic',
  createSuspension: '/create-user-suspension',
  talkListStatus: '/talk-list-status',
  talkSteps: '/talk-steps',
  failTalk: '/fail-talk',
  newTalkList: '/new-talk-list',
  /*dynamic pathes*/
  access_policy: '/policy-list',
  // account: '/account',
  // context: '/context',
  // pal: '/pal',
  talk: '/talk',
  // feedback: '/feedback',
  user: '/user-list',
  // config: '/config',
  // language: '/language',
  // character: '/charachter'

}



export const SUBLINKS = {
  '10': {
    sublinks: [
      {
        name: 'Language Count',
        value: '/talk-reports-language-count'
      },
      {
        name: 'Language Duration',
        value: '/talk-reports-language-duration'
      },
      {
        name: 'Character Count',
        value: '/talk-reports-character-count'
      },
      {
        name: 'Character Duration',
        value: '/talk-reports-character-duration'
      },
      {
        name: 'Talk List',
        value: '/talk-list',
      },
      {
        name: 'Talk Another',
        value: '/talk-another'
      },
      {
        name: 'Language Total List',
        value: '/language-total-list'
      },
      {
        name: 'Country Talk List',
        value: '/country-talk-list'
      },
      {
        name: 'Daily Talk List',
        value: "/daily-talk-list"
      },
      {
        name: 'Talk Status',
        value: "/talk-list-status"
      },
      {
        name: 'Talk Steps',
        value: "/talk-steps"
      },
      {
        name: 'Fail Talk ',
        value: "/fail-talk"
      },
      {
        name: 'New Talk List ',
        value: "/new-talk-list"
      }

    ]
  }
}



export const GROUPS = {
  1: {
    name: 'AAA',
    color: 'cyan'
  },
  2: {
    name: 'BBB',
    color: 'lightblue'
  },
  3: {
    name: 'CCC',
    color: 'red'
  },
  4: {
    name: 'DDD',
    color: 'orange'
  }
}


export const PASSWORD_MIN_LENGTH = 8
export const USERNAME_MIN_LENGTH = 5
export const NAME_MIN_LENGTH = 3
export const USER_PER_PAGE = 10


export const SESSION_STORAGE_KEYS = {
  user: 'user',
  configs: 'configs'
}


export const PLATFORMS_ARR = [{ name: "ios", value: 1 }, { name: "android", value: 2 }, { name: "web", value: 3 }]

export const static_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTI4LCJwbGF0Zm9ybSI6MiwiYXBwX3ZlcnNpb24iOiIxLjEuMSIsImRldmljZV9pZCI6ImRhc2QiLCJleHAiOjE2NjA4MjgxNDN9.BokpQhgGgEyvu9ok2c703AC64INYDEUwchvY6051PA4"